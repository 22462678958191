<template>
  <app-icon class="volumeIcon" :width="bounds.width" :height="bounds.height">
    <svg xmlns="http://www.w3.org/2000/svg" :viewBox="getSVGViewboxByIconSize(props.size)">
      <title v-if="$isNativeTitleTooltipShown">{{ $t('icons.volume') }}</title>
      <template v-if="size === 'extra-small'">
        <template v-if="mode === 'off'">
          <g clip-path="url(#clip0_140_1973)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.9419 1.94194C11.186 1.69786 11.186 1.30214 10.9419 1.05806C10.6979 0.813981 10.3021 0.813981 10.0581 1.05806L7.5 3.61612V2.67553C7.5 2.07164 6.82273 1.71538 6.3251 2.0575L3.75594 3.8238C3.58924 3.9384 3.3917 3.99976 3.18941 3.99976H1.75C1.33579 3.99976 1 4.33554 1 4.74976V7.24976C1 7.66397 1.33579 7.99976 1.75 7.99976H3.11636L1.05806 10.0581C0.813981 10.3021 0.813981 10.6979 1.05806 10.9419C1.30214 11.186 1.69786 11.186 1.94194 10.9419L10.9419 1.94194ZM5.43645 9.33107L7.5 7.26752V9.32398C7.5 9.92787 6.82273 10.2841 6.3251 9.94201L5.43645 9.33107Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_140_1973">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </template>
        <template v-if="mode === 'half'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.75594 3.82387L6.3251 2.05757C6.82273 1.71546 7.5 2.07172 7.5 2.67561V9.32406C7.5 9.92795 6.82273 10.2842 6.3251 9.94209L3.75594 8.17579C3.58924 8.06119 3.3917 7.99983 3.18941 7.99983H1.75C1.33579 7.99983 1 7.66405 1 7.24983V4.74983C1 4.33562 1.33579 3.99983 1.75 3.99983H3.18941C3.3917 3.99983 3.58924 3.93848 3.75594 3.82387ZM10.0609 3.43444C9.81663 3.19059 9.4209 3.19095 9.17704 3.43525C8.93319 3.67955 8.93355 4.07528 9.17785 4.31913C9.60927 4.74976 9.875 5.34308 9.875 5.99983C9.875 6.65668 9.60919 7.25042 9.17785 7.68098C8.93355 7.92483 8.93318 8.32056 9.17704 8.56486C9.42089 8.80916 9.81662 8.80952 10.0609 8.56567C10.7177 7.91013 11.125 7.00182 11.125 5.99983C11.125 4.99775 10.7176 4.0899 10.0609 3.43444Z"
            fill="white"
          />
        </template>

        <template v-if="mode === 'full'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.75594 3.82387L6.3251 2.05757C6.82273 1.71546 7.5 2.07172 7.5 2.67561V9.32406C7.5 9.92795 6.82273 10.2842 6.3251 9.94209L3.75594 8.17579C3.58924 8.06119 3.3917 7.99983 3.18941 7.99983H1.75C1.33579 7.99983 1 7.66405 1 7.24983V4.74983C1 4.33562 1.33579 3.99983 1.75 3.99983H3.18941C3.3917 3.99983 3.58924 3.93848 3.75594 3.82387ZM10.0609 3.43444C9.81663 3.19059 9.4209 3.19095 9.17704 3.43525C8.93319 3.67955 8.93355 4.07528 9.17785 4.31913C9.60927 4.74976 9.875 5.34308 9.875 5.99983C9.875 6.65668 9.60919 7.25042 9.17785 7.68098C8.93355 7.92483 8.93318 8.32056 9.17704 8.56486C9.42089 8.80916 9.81662 8.80952 10.0609 8.56567C10.7177 7.91013 11.125 7.00182 11.125 5.99983C11.125 4.99775 10.7176 4.0899 10.0609 3.43444Z"
            fill="white"
          />
        </template>
      </template>
      <template v-if="size === 'small'">
        <template v-if="mode === 'off'">
          <g clip-path="url(#clip0_140_1981)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.3687 1.86872C15.7104 1.52701 15.7104 0.97299 15.3687 0.631282C15.027 0.289573 14.473 0.289573 14.1313 0.631282L10.25 4.51256V3.01378C10.25 2.10795 9.2341 1.57355 8.48766 2.08673L4.63391 4.73618C4.38386 4.90809 4.08756 5.00012 3.78412 5.00012H1.625C1.00368 5.00012 0.5 5.5038 0.5 6.12512V9.87512C0.5 10.4964 1.00368 11.0001 1.625 11.0001H3.76245L0.631282 14.1313C0.289573 14.473 0.289573 15.027 0.631282 15.3687C0.97299 15.7104 1.52701 15.7104 1.86872 15.3687L15.3687 1.86872ZM7.04993 12.9251L10.25 9.725V12.9865C10.25 13.8923 9.23409 14.4267 8.48765 13.9135L7.04993 12.9251Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_140_1981">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </template>
        <template v-if="mode === 'half'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.63391 4.73606L8.48766 2.08661C9.2341 1.57343 10.25 2.10783 10.25 3.01365V12.9863C10.25 13.8922 9.23409 14.4266 8.48765 13.9134L4.63391 11.2639C4.38386 11.092 4.08756 11 3.78412 11H1.625C1.00368 11 0.5 10.4963 0.5 9.87499V6.12499C0.5 5.50367 1.00368 4.99999 1.625 4.99999H3.78412C4.08756 4.99999 4.38386 4.90796 4.63391 4.73606ZM14.0472 4.19614C13.7052 3.85474 13.1512 3.85525 12.8098 4.19727C12.4684 4.53929 12.4689 5.09331 12.8109 5.43471C13.4693 6.09189 13.875 6.9976 13.875 7.99999C13.875 9.00253 13.4692 9.90886 12.8109 10.5659C12.4689 10.9073 12.4684 11.4614 12.8098 11.8034C13.1512 12.1454 13.7052 12.1459 14.0472 11.8045C15.0211 10.8325 15.625 9.48572 15.625 7.99999C15.625 6.51413 15.021 5.16809 14.0472 4.19614Z"
            fill="white"
          />
        </template>

        <template v-if="mode === 'full'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.63391 4.73606L8.48766 2.08661C9.2341 1.57343 10.25 2.10783 10.25 3.01365V12.9863C10.25 13.8922 9.23409 14.4266 8.48765 13.9134L4.63391 11.2639C4.38386 11.092 4.08756 11 3.78412 11H1.625C1.00368 11 0.5 10.4963 0.5 9.87499V6.12499C0.5 5.50367 1.00368 4.99999 1.625 4.99999H3.78412C4.08756 4.99999 4.38386 4.90796 4.63391 4.73606ZM14.0472 4.19614C13.7052 3.85474 13.1512 3.85525 12.8098 4.19727C12.4684 4.53929 12.4689 5.09331 12.8109 5.43471C13.4693 6.09189 13.875 6.9976 13.875 7.99999C13.875 9.00253 13.4692 9.90886 12.8109 10.5659C12.4689 10.9073 12.4684 11.4614 12.8098 11.8034C13.1512 12.1454 13.7052 12.1459 14.0472 11.8045C15.0211 10.8325 15.625 9.48572 15.625 7.99999C15.625 6.51413 15.021 5.16809 14.0472 4.19614Z"
            fill="white"
          />
        </template>
      </template>
      <template v-if="size === 'medium'">
        <template v-if="mode === 'off'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.7071 3.70711C22.0976 3.31658 22.0976 2.68342 21.7071 2.29289C21.3166 1.90237 20.6834 1.90237 20.2929 2.29289L15 7.58579V5.3517C15 4.14393 13.6455 3.4314 12.6502 4.11564L7.51188 7.64824C7.17848 7.87745 6.78341 8.00015 6.37882 8.00015H3.5C2.67157 8.00015 2 8.67173 2 9.50015V14.5002C2 15.3286 2.67157 16.0002 3.5 16.0002H6.37882C6.44484 16.0002 6.51061 16.0034 6.5759 16.0099L2.29289 20.2929C1.90237 20.6834 1.90237 21.3166 2.29289 21.7071C2.68342 22.0976 3.31658 22.0976 3.70711 21.7071L21.7071 3.70711ZM10.4538 18.3746L15 13.8284V18.6486C15 19.8564 13.6455 20.5689 12.6502 19.8847L10.4538 18.3746Z"
            fill="white"
          />
        </template>
        <template v-if="mode === 'half'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.51188 7.64824L12.6502 4.11564C13.6455 3.4314 15 4.14393 15 5.3517V18.6486C15 19.8564 13.6455 20.5689 12.6502 19.8847L7.51188 16.3521C7.17848 16.1229 6.78341 16.0002 6.37882 16.0002H3.5C2.67157 16.0002 2 15.3286 2 14.5002V9.50015C2 8.67173 2.67157 8.00015 3.5 8.00015H6.37882C6.78341 8.00015 7.17848 7.87745 7.51188 7.64824ZM19.9452 7.04631C19.5544 6.65614 18.9212 6.65672 18.531 7.0476C18.1409 7.43848 18.1414 8.07164 18.5323 8.46181C19.4402 9.36804 20 10.6176 20 12.0002C20 13.3829 19.4401 14.6333 18.5323 15.5394C18.1414 15.9296 18.1409 16.5627 18.531 16.9536C18.9212 17.3445 19.5543 17.3451 19.9452 16.9549C21.2136 15.6888 22 13.9351 22 12.0002C22 10.0651 21.2135 8.31227 19.9452 7.04631Z"
            fill="white"
          />
        </template>

        <template v-if="mode === 'full'">
          <g clip-path="url(#clip0_140_1970)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.51188 7.64824L12.6502 4.11564C13.6455 3.4314 15 4.14393 15 5.3517V18.6486C15 19.8564 13.6455 20.5689 12.6502 19.8847L7.51188 16.3521C7.17848 16.1229 6.78341 16.0002 6.37882 16.0002H3.5C2.67157 16.0002 2 15.3286 2 14.5002V9.50015C2 8.67173 2.67157 8.00015 3.5 8.00015H6.37882C6.78341 8.00015 7.17848 7.87745 7.51188 7.64824Z"
              fill="white"
            />
            <path
              d="M20.6797 18.4547C22.2121 16.7918 23.004 14.8199 23.004 12.2271C23.004 9.63433 22.2121 7.66288 20.6797 6"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill-opacity="0"
            />
            <path
              d="M19.682 11.9994C19.682 10.7213 19.2802 9.52236 18.5744 8.48331C18.0988 7.7832 17.1425 8.2008 17.1425 9.04716L17.1425 14.9528C17.1425 15.7992 18.0987 16.2167 18.5742 15.5166C19.2801 14.4772 19.682 13.2777 19.682 11.9994Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_140_1970">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </template>
      </template>
      <template v-if="size === 'large'">
        <template v-if="mode === 'off'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.6338 4.13388C30.122 3.64573 30.122 2.85427 29.6338 2.36612C29.1457 1.87796 28.3542 1.87796 27.8661 2.36612L20.2499 9.98228V6.58115C20.2499 4.87015 18.331 3.86073 16.921 4.83006L9.64175 9.83458C9.16943 10.1593 8.60975 10.3331 8.03658 10.3331H3.95825C2.78465 10.3331 1.83325 11.2845 1.83325 12.4581V19.5415C1.83325 20.7151 2.78465 21.6665 3.95825 21.6665H8.03658C8.20059 21.6665 8.36349 21.6807 8.52357 21.7086L2.36608 27.8661C1.87792 28.3543 1.87792 29.1457 2.36608 29.6339C2.85423 30.122 3.64569 30.122 4.13384 29.6339L29.6338 4.13388ZM13.5302 24.8383L20.2499 18.1187V25.4184C20.2499 27.1294 18.331 28.1389 16.921 27.1695L13.5302 24.8383Z"
            fill="white"
          />
        </template>
        <template v-if="mode === 'half'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.64175 9.83472L16.921 4.8302C18.331 3.86087 20.2499 4.87028 20.2499 6.58129V25.4186C20.2499 27.1296 18.331 28.139 16.921 27.1697L9.64175 22.1651C9.16943 21.8404 8.60975 21.6666 8.03658 21.6666H3.95825C2.78465 21.6666 1.83325 20.7152 1.83325 19.5416V12.4583C1.83325 11.2847 2.78465 10.3333 3.95825 10.3333H8.03658C8.60975 10.3333 9.16943 10.1594 9.64175 9.83472ZM27.1379 9.09994C26.6493 8.61223 25.8579 8.61296 25.3702 9.10156C24.8825 9.59016 24.8832 10.3816 25.3718 10.8693C26.688 12.1831 27.4999 13.9952 27.4999 15.9999C27.4999 18.0048 26.6878 19.8181 25.3718 21.1318C24.8832 21.6195 24.8824 22.411 25.3701 22.8996C25.8579 23.3882 26.6493 23.3889 27.1379 22.9012C28.9048 21.1376 29.9999 18.6951 29.9999 15.9999C29.9999 13.3046 28.9046 10.8634 27.1379 9.09994Z"
            fill="white"
          />
        </template>

        <template v-if="mode === 'full'">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.64175 9.83472L16.921 4.8302C18.331 3.86087 20.2499 4.87028 20.2499 6.58129V25.4186C20.2499 27.1296 18.331 28.139 16.921 27.1697L9.64175 22.1651C9.16943 21.8404 8.60975 21.6666 8.03658 21.6666H3.95825C2.78465 21.6666 1.83325 20.7152 1.83325 19.5416V12.4583C1.83325 11.2847 2.78465 10.3333 3.95825 10.3333H8.03658C8.60975 10.3333 9.16943 10.1594 9.64175 9.83472Z"
            fill="white"
          />
          <path
            d="M27.5111 24.0525C29.4927 21.9022 30.5167 19.3523 30.5167 15.9996C30.5167 12.6468 29.4927 10.0975 27.5111 7.94727"
            stroke="white"
            stroke-width="2.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.0433 15.9992C26.0433 14.3097 25.4611 12.7349 24.4521 11.403C23.941 10.7284 22.9806 11.1544 22.9806 12.0008L22.9806 19.9992C22.9806 20.8456 23.941 21.2716 24.4519 20.5969C25.461 19.2646 26.0433 17.689 26.0433 15.9992Z"
            fill="white"
          />
        </template>
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { getBoundsByIconSize, getSVGViewboxByIconSize, IconSize } from '@PLAYER/player/components/icons/icon-types';
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';

const props = defineProps<{
  mode: 'off' | 'half' | 'full';
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss">
.volumeIcon {
  position: relative;
}
</style>
