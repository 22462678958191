<template>
  <root-video-z-index-layer
    :style="{
      left: $toPixel(x),
      top: $toPixel(y),
      width: $toPixel(width as number),
      height: $toPixel(height + MENU_PADDING),
    }"
    :class="$style.contextPopup"
  >
    <div ref="menuEl" :class="$style.contextMenu" role="menu">
      <app-slot-button
        v-for="item in items"
        :key="item.id"
        :on-click="() => item.action()"
        :class="$style.menuItem"
        role="menuitem"
        aria-haspopup="false"
        tabindex="0"
      >
        <component :is="item.icon" :width="24" :height="24" />
        <span>{{ item.title }}</span>
      </app-slot-button>
    </div>
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import { copyToClipboard } from '@package/sdk/src/core';
import IconCopyLink from '@PLAYER/player/components/icons/IconCopyLink.vue';
import IconCriticalError from '@PLAYER/player/components/icons/IconCriticalError.vue';
import AppSlotButton from '@PLAYER/player/components/ui/AppSlotButton.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import useDebugActions from '@PLAYER/player/modules/debug/use-debug-actions';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { translate } from '@PLAYER/player/modules/localization/translate';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { useElementSize } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { storeToRefs } from 'pinia';
import { computed, markRaw, ref } from 'vue';

const layoutStore = useLayoutStore();
const debugActions = useDebugActions();

const { contextMenuOptions } = storeToRefs(layoutStore);
const { isWeb } = usePlatform();
const { isVOD } = useProjector();

const { normalizedDisplayedCurrentTime } = useVideoPlayerVariables();

const menuEl = ref<HTMLElement>();
const { width, height } = useElementSize(menuEl);
const { appWidth, appHeight } = useRootVideoElBounds();
const MENU_PADDING = 20;

const x = computed(() => {
  const _x = contextMenuOptions.value?.x;

  if (!_x) {
    return 0;
  }

  const maximumAllowedX = appWidth.value;

  if (_x + width.value >= maximumAllowedX) {
    return maximumAllowedX - width.value;
  }

  return _x;
});

const y = computed(() => {
  const _y = contextMenuOptions.value?.y;

  if (!_y) {
    return 0;
  }

  const maximumAllowedY = appHeight.value;

  if (_y + height.value >= maximumAllowedY) {
    return maximumAllowedY - height.value;
  }

  return _y;
});

const items = markRaw([
  {
    title: translate('debug.metadata'),
    id: nanoid(2),
    icon: IconCriticalError,
    action: () => {
      debugActions.requestDebugMenu();
    },
  },
]);

if (isWeb && isVOD.value) {
  items.push({
    title: translate('debug.copyContentURL'),
    id: nanoid(2),
    icon: IconCopyLink,
    action: () => {
      const url = new URL(window.location.href);

      try {
        copyToClipboard(url.toString());
      } catch {
        // it is ok
      }
    },
  });

  items.push({
    title: translate('debug.copyVideoURL'),
    id: nanoid(2),
    icon: IconCopyLink,
    action: () => {
      const url = new URL(window.location.href);

      url.searchParams.set('t', normalizedDisplayedCurrentTime.value.toFixed());

      try {
        copyToClipboard(url.toString());
      } catch {
        // it is ok
      }
    },
  });
}
</script>

<style lang="scss" module>
.contextPopup {
  z-index: var(--z-index-context-menu);
  border-radius: 2px;
  background-color: var(--color-notheme-bg-banner-button-pressed);
  overflow: hidden;
  text-shadow: 0 0 2px rgb(0 0 0 / 50%);
  transition: opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
}

.contextMenu {
  padding: 10px;
  min-width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}
</style>
