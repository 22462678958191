<template>
  <root-video-z-index-layer
    :ref="(comp) => setRootElement(comp?.$el)"
    :class="$style.popup"
    :fullscreen="isFullscreenPopup"
    class="popup-layer"
  >
    <component :is="currentPopupComponentName" :ref="(comp) => (popupEl = comp?.$el)" />
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { CSSSupportMap } from '@PLAYER/player/base/dom';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import { onClickOutside } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';

const layoutStore = useLayoutStore();
const { isWeb } = usePlatform();

const { ignorePopupElements, currentPopupComponentName, isFocused } = storeToRefs(layoutStore);

const popupEl = ref<HTMLElement>();
const rootEl = ref<HTMLElement>();

const { isMobileView } = useRootVideoElBounds();

const popupBackgroundColor = isWeb ? 'var(--color-notheme-bg-secondary-30)' : 'var(--color-notheme-bg-secondary-80)';

const isFullscreenPopup = computed(() => {
  if (!isMobileView.value && currentPopupComponentName.value === 'PopupSettingsMenu') {
    return false;
  }

  if (currentPopupComponentName.value !== 'PopupSettingsMenu') {
    return true;
  }

  return true;
});

// В firefox :has() селектора нет, приходитcя сами тут городить
if (!CSSSupportMap.HasSelector) {
  const disposeWatcher = watch(
    isMobileView,
    async (isSmall) => {
      if (!currentPopupComponentName.value) {
        throw new UnexpectedComponentStateError('currentPopupComponentName');
      }

      await nextTick();

      if (isSmall) {
        rootEl.value?.classList.add(`${currentPopupComponentName.value}_fullscreen`);
      } else {
        rootEl.value?.classList.remove(`${currentPopupComponentName.value}_fullscreen`);
      }
    },
    { immediate: true },
  );

  onBeforeUnmount(() => {
    disposeWatcher();
  });
}

const setRootElement = (el: HTMLElement) => {
  if (!el) {
    return;
  }

  rootEl.value = el;

  if (!CSSSupportMap.HasSelector) {
    if (!currentPopupComponentName.value) {
      throw new UnexpectedComponentStateError('rootEl || currentPopupComponentName');
    }

    el.classList.add(currentPopupComponentName.value);
  }
};

// При клике вне элемента, закрываем попап. (Для мартов не актуально)
if (isWeb) {
  onClickOutside(
    popupEl,
    () => {
      if (!isFocused.value) {
        return;
      }

      layoutStore.setPopup(null);
    },
    {
      ignore: ignorePopupElements.value,
    },
  );
}
</script>

<style lang="scss">
.PopupEpisodesMenu {
  width: 100%;
  height: 100%;
}

.PopupSettingsMenu {
  bottom: 85px;
  right: 15px;
  max-height: calc(100% - 90px);
  border-radius: var(--g-border-round-24);
}

.PopupSettingsMenu_fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 0;
}
</style>

<style lang="scss" module>
.popup {
  z-index: var(--z-index-popup-view);
  background-color: v-bind(popupBackgroundColor);
  backdrop-filter: blur(var(--player-default-backdrop-filter-modal-blur));
  overflow: hidden;
}
</style>
