export const PlayerFocusKeys = {
  PLAYER_ROOT: 'PLAYER_ROOT',
  PLAYER_TIMELINE: 'PLAYER_TIMELINE',
  SERIES_BUTTON: 'SERIES_BUTTON',

  PLAYER_PLAY_BUTTON: 'PLAYER_PLAY_BUTTON',
  PLAYER_NEXT_EPISODE: 'PLAYER_NEXT_EPISODE',
  PLAYER_NEXT_EPISODE_BUTTON: 'PLAYER_NEXT_EPISODE_BUTTON',
  PLAYER_NEXT_EPISODE_PLAY: 'PLAYER_NEXT_EPISODE_PLAY',
};
