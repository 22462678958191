<template>
  <app-icon :width="bounds.width" :height="bounds.height">
    <svg
      :class="$style.svg"
      :style="{ transform: `rotate(${state === 'normal' ? 0 : 60}deg)` }"
      :viewBox="getSVGViewboxByIconSize(size)"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title v-if="$isNativeTitleTooltipShown">{{ $t('icons.settings') }}</title>
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.54198 1.56661C6.24662 1.25774 5.75325 1.25774 5.45789 1.56661L4.35132 2.72378C4.25246 2.82716 4.1261 2.90011 3.98715 2.93404L2.43173 3.31377C2.01655 3.41513 1.76987 3.8424 1.88968 4.25262L2.33853 5.78953C2.37863 5.92683 2.37863 6.07273 2.33853 6.21003L1.88968 7.74693C1.76987 8.15716 2.01655 8.58443 2.43173 8.68579L3.98715 9.06552C4.1261 9.09945 4.25246 9.1724 4.35132 9.27578L5.45789 10.4329C5.75325 10.7418 6.24662 10.7418 6.54198 10.4329L7.64855 9.27578C7.74741 9.1724 7.87377 9.09945 8.01272 9.06552L9.56814 8.68579C9.98332 8.58443 10.23 8.15716 10.1102 7.74693L9.66134 6.21003C9.62124 6.07273 9.62124 5.92683 9.66134 5.78953L10.1102 4.25263C10.23 3.8424 9.98332 3.41513 9.56814 3.31377L8.01272 2.93404C7.87377 2.90011 7.74741 2.82716 7.64855 2.72378L6.54198 1.56661ZM5.99993 7.49978C6.82836 7.49978 7.49993 6.82821 7.49993 5.99978C7.49993 5.17135 6.82836 4.49978 5.99993 4.49978C5.17151 4.49978 4.49993 5.17135 4.49993 5.99978C4.49993 6.82821 5.17151 7.49978 5.99993 7.49978Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.8131 1.35041C8.37005 0.887103 7.63 0.887103 7.18695 1.35041L5.5271 3.08616C5.37881 3.24123 5.18928 3.35066 4.98084 3.40154L2.64771 3.97114C2.02495 4.12318 1.65493 4.76408 1.83464 5.37943L2.50792 7.68478C2.56807 7.89073 2.56807 8.10959 2.50792 8.31554L1.83464 10.6209C1.65493 11.2362 2.02495 11.8771 2.64771 12.0292L4.98084 12.5988C5.18928 12.6497 5.37881 12.7591 5.5271 12.9142L7.18695 14.6499C7.63 15.1132 8.37005 15.1132 8.8131 14.6499L10.4729 12.9142C10.6212 12.7591 10.8108 12.6497 11.0192 12.5988L13.3523 12.0292C13.9751 11.8771 14.3451 11.2362 14.1654 10.6209L13.4921 8.31554C13.432 8.10959 13.432 7.89073 13.4921 7.68478L14.1654 5.37943C14.3451 4.76408 13.9751 4.12318 13.3523 3.97114L11.0192 3.40154C10.8108 3.35066 10.6212 3.24123 10.4729 3.08616L8.8131 1.35041ZM8.00002 10.2502C9.24266 10.2502 10.25 9.2428 10.25 8.00016C10.25 6.75752 9.24266 5.75016 8.00002 5.75016C6.75738 5.75016 5.75002 6.75752 5.75002 8.00016C5.75002 9.2428 6.75738 10.2502 8.00002 10.2502Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0841 3.13372C12.4934 2.51598 11.5066 2.51597 10.9159 3.13372L8.70276 5.44805C8.50504 5.65481 8.25233 5.80071 7.97441 5.86856L4.86357 6.62803C4.03323 6.83074 3.53986 7.68528 3.77948 8.50574L4.67718 11.5795C4.75738 11.8541 4.75738 12.146 4.67718 12.4206L3.77948 15.4944C3.53986 16.3148 4.03323 17.1694 4.86357 17.3721L7.97441 18.1315C8.25233 18.1994 8.50504 18.3453 8.70276 18.552L10.9159 20.8664C11.5066 21.4841 12.4934 21.4841 13.0841 20.8664L15.2972 18.552C15.4949 18.3453 15.7477 18.1994 16.0256 18.1315L19.1364 17.3721C19.9668 17.1694 20.4601 16.3148 20.2205 15.4944L19.3228 12.4206C19.2426 12.146 19.2426 11.8541 19.3228 11.5795L20.2205 8.50574C20.4601 7.68528 19.9668 6.83074 19.1364 6.62803L16.0256 5.86856C15.7477 5.80071 15.4949 5.65481 15.2972 5.44805L13.0841 3.13372ZM12 15C13.6568 15 15 13.6569 15 12C15 10.3432 13.6568 9.00005 12 9.00005C10.3431 9.00005 8.99999 10.3432 8.99999 12C8.99999 13.6569 10.3431 15 12 15Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.5358 3.43956C16.699 2.56442 15.3011 2.56442 14.4642 3.43955L11.3289 6.7182C11.0488 7.0111 10.6908 7.2178 10.2971 7.31392L5.89009 8.38983C4.71377 8.67701 4.01483 9.8876 4.35429 11.0499L5.62604 15.4045C5.73965 15.7935 5.73965 16.2069 5.62604 16.5959L4.35429 20.9505C4.01483 22.1128 4.71377 23.3234 5.8901 23.6106L10.2971 24.6865C10.6908 24.7826 11.0488 24.9893 11.3289 25.2822L14.4642 28.5608C15.3011 29.436 16.699 29.436 17.5358 28.5608L20.6711 25.2822C20.9512 24.9893 21.3092 24.7826 21.7029 24.6865L26.1099 23.6106C27.2863 23.3234 27.9852 22.1128 27.6458 20.9505L26.374 16.5959C26.2604 16.2069 26.2604 15.7935 26.374 15.4045L27.6457 11.0499C27.9852 9.8876 27.2863 8.67701 26.1099 8.38983L21.7029 7.31392C21.3092 7.2178 20.9512 7.0111 20.6711 6.7182L17.5358 3.43956ZM16 20.2502C18.3472 20.2502 20.25 18.3474 20.25 16.0002C20.25 13.653 18.3472 11.7502 16 11.7502C13.6528 11.7502 11.75 13.653 11.75 16.0002C11.75 18.3474 13.6528 20.2502 16 20.2502Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { getBoundsByIconSize, getSVGViewboxByIconSize, IconSize } from '@PLAYER/player/components/icons/icon-types';
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';

const props = defineProps<{
  state: 'normal' | 'rotate';
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.svg {
  transform-origin: center;
  transition: transform 0.7s ease;
}
</style>
