<template>
  <teleport :to="modalTarget">
    <div ref="el" :class="$style.modal">
      <div ref="scrollRef" :class="$style.modalContainer">
        <slot :scroll-element="scrollRef" />
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { TvKeyCode } from '@package/sdk/src/core';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { customEventsService, FocusKeys, keyboardEventHandler } from '@SMART/index';
import { onMounted, onUnmounted, provide, ref } from 'vue';

withDefaults(
  defineProps<{
    modalTarget?: string;
  }>(),
  {
    modalTarget: '#app',
  },
);

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.FULL_SCREEN_MODAL,
  isFocusBoundary: true,
  forceFocus: true,
});
provide('parentFocusKey', focusKey.value);

const previousFocusKey = ref('');

const scrollRef = ref();

const onScroll = (px: number) => {
  if (scrollRef.value) {
    window.requestAnimationFrame(() => {
      if (!scrollRef.value) {
        return;
      }

      scrollRef.value.scrollTop = scrollRef.value.scrollTop + px;
    });
  }
};

onMounted(async () => {
  customEventsService.setOnPressBackCallback(() => {
    emit('close');
  });

  keyboardEventHandler.on(TvKeyCode.UP, () => onScroll(-50));
  keyboardEventHandler.on(TvKeyCode.DOWN, () => onScroll(50));

  previousFocusKey.value = SpatialNavigation.getCurrentFocusKey();
  focusSelf();
});

onUnmounted(() => {
  customEventsService.setOnPressBackCallback(undefined, true);

  if (previousFocusKey.value) {
    SpatialNavigation.setFocus(previousFocusKey.value);
  }
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  padding: adjust.adjustPx(80px) adjust.adjustPx(138px);

  width: 100%;
  height: 100%;

  background-image: url('@SMART/assets/images/bg-gradient.webp');
  background-color: var(--color-bg-primary);
  background-size: cover;

  &Container {
    width: 100%;
    height: 100%;
    max-height: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-bg-accent);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-bg-tertiary);
    }
  }
}
</style>
