const programmableDimension = 1280;
const designDimension = 1920;

export const adjustPx = (px: number): number => {
  const aspectRatio = programmableDimension / designDimension;

  return aspectRatio * px;
};

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('should adjustPx(999) equals 666px', () => {
    expect(adjustPx(999)).toBe(666);
  });

  it('should return instanceof Number', () => {
    expect(adjustPx(666)).toBeTypeOf('number');
  });
}
