import { Disposable } from '@package/sdk/src/core';
import { IndexedDBStorage } from '@package/sdk/src/core/database/indexed-db';

import type { DsmlRequestBodyV2Params } from '../code/dsml-v2-request-transformer';

export class StoredEventsDatabase extends Disposable {
  private readonly indexedDB = new IndexedDBStorage({
    tableName: 'stored-events',
    databaseName: 'dsml-js',
    timeoutForReading: 2500,
  });

  constructor() {
    super();
  }

  public add(key: string, value: DsmlRequestBodyV2Params): Promise<void> {
    return this.indexedDB.write<DsmlRequestBodyV2Params>(key, value);
  }

  public remove(key: string): Promise<void> {
    return this.indexedDB.delete(key);
  }

  public read(key: string) {
    return this.indexedDB.read<DsmlRequestBodyV2Params>(key);
  }

  public clear(): Promise<void> {
    return this.indexedDB.clear();
  }

  public readAll() {
    return this.indexedDB.readAll<DsmlRequestBodyV2Params>();
  }
}
