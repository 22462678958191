import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { MaybeElementRef } from '@vueuse/core';
import { onBeforeUnmount, onMounted, ref, unref } from 'vue';

interface CroppedTimelineOptions {
  startOffset: number;
  videoEl: MaybeElementRef<HTMLVideoElement>;
}

export default function useCroppedTimelineVideo(options: CroppedTimelineOptions) {
  const { startOffset } = options;
  const currentTime = ref(0);

  const videoEl = ref<HTMLVideoElement>();
  const useSafePlayerAction = (callback: (videoEl: HTMLVideoElement) => void) => {
    if (!videoEl.value) {
      throw new UnexpectedComponentStateError('videoEl');
    }

    Reflect.apply(callback, undefined, [videoEl.value]);
  };

  const changeCurrentTime = (value: number) =>
    useSafePlayerAction((videoEl) => {
      const newTime = value - startOffset;
      videoEl.currentTime = newTime < 0 ? 0.1 : newTime;
    });

  const onCurrentTimeUpdate = () => {
    useSafePlayerAction((videoEl) => {
      const newTime = videoEl.currentTime - startOffset;
      currentTime.value = newTime < 0 ? 0.1 : newTime;
    });
  };

  onMounted(() => {
    videoEl.value = unref(options.videoEl);

    videoEl.value.currentTime = startOffset;
    videoEl.value.addEventListener('timeupdate', onCurrentTimeUpdate);
  });

  onBeforeUnmount(() => {
    if (!videoEl.value) {
      throw new UnexpectedComponentStateError('videoEl');
    }

    videoEl.value.removeEventListener('timeupdate', onCurrentTimeUpdate);
  });

  return {
    currentTime,
    changeCurrentTime,
    useSafePlayerAction,
    onCurrentTimeUpdate,
  };
}
