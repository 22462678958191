import useLogger from '@package/logger/src/use-logger';
import { AlertMessageTypes, alertService, RouterPage, translate } from '@SMART/index';
import { defineComponent } from 'vue';
import type * as router from 'vue-router';

import AuthPage from '@/pages/auth/AuthPage.vue';
import CatalogPage from '@/pages/catalog/CatalogPage.vue';
import ChannelsPage from '@/pages/channels/ChannelsPage.vue';
import MainPage from '@/pages/main/MainPage.vue';
import MediaCardPage from '@/pages/media-card/MediaCardPage.vue';
import MyChannelPage from '@/pages/my-channel/MyChannelPage.vue';
import SearchPage from '@/pages/search/SearchPage.vue';
import SettingsPage from '@/pages/settings/SettingsPage.vue';

const logger = useLogger('routes');

const handleDynamicError = (e: Error) => {
  console.error(e);
  logger.debug(e.message);
  logger.debug(e.stack || '');

  alertService.addAlert({
    hideIcon: false,
    type: AlertMessageTypes.Success,
    timeoutMs: 15000,
    message: translate('error.moduleLoadError'),
  });
};

const routes: router.RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: RouterPage.MainPage },
  },
  {
    path: '/',
    component: defineComponent({
      template: '<router-view/>',
    }),
  },
  {
    path: '/main',
    name: RouterPage.MainPage,
    component: MainPage,
  },
  {
    path: '/parental',
    name: RouterPage.ParentalPage,
    component: () => import('@/pages/parental/ParentalPage.vue').catch(handleDynamicError),
  },
  {
    path: '/parental/code/:variant?',
    name: RouterPage.ParentalCodePage,
    component: () => import('@/pages/parental/ParentalCodePage.vue').catch(handleDynamicError),
  },
  {
    path: '/parental/recover',
    name: RouterPage.ParentalCodeRecoverPage,
    component: () => import('@/pages/parental/ParentalCodeRecoverPage.vue').catch(handleDynamicError),
  },
  {
    path: '/search',
    name: RouterPage.SearchPage,
    component: SearchPage,
  },
  {
    path: '/catalog/',
    name: RouterPage.CatalogPage,
    component: CatalogPage,
  },
  {
    path: '/collection/:page?/:size?/:id?/:type?/',
    name: RouterPage.CollectionPage,
    props: true,
    component: () => import('@/pages/collection/CollectionPage.vue').catch(handleDynamicError),
  },
  {
    path: '/channels/',
    name: RouterPage.ChannelsPage,
    component: ChannelsPage,
  },
  {
    path: '/channels/player/:id/',
    name: RouterPage.ChannelsPlayerPage,
    component: () => import('@/pages/channels/player/ChannelsPlayerPage.vue').catch(handleDynamicError),
  },
  {
    path: '/my-channel/:page?/:size?/:id?/:type?/',
    name: RouterPage.MyChannelPage,
    props: true,
    component: MyChannelPage,
  },
  {
    path: '/moments/:page?/:size?/:id?/:type?/:momentIndex?',
    name: RouterPage.MomentsPage,
    props: true,
    component: () => import('@/pages/my-channel/MomentsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/offers/',
    name: RouterPage.Offers,
    component: () => import('@/pages/offers/OffersPage.vue').catch(handleDynamicError),
    beforeEnter: [],
  },
  {
    path: '/offer-info/:offerId/',
    name: RouterPage.OfferInfo,
    props: true,
    component: () => import('@/pages/offers/OfferInfoPage.vue').catch(handleDynamicError),
    beforeEnter: [],
  },
  {
    path: '/debug/log/',
    name: RouterPage.LogInfo,
    props: true,
    component: () => import('@/pages/debug/LogInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/settings/',
    name: RouterPage.SettingsPage,
    component: SettingsPage,
    beforeEnter: [],
    redirect: {
      name: RouterPage.PersonalData,
    },
    children: [
      {
        path: 'subscriptions/',
        name: RouterPage.Subscriptions,
        beforeEnter: [],
        redirect: {
          name: RouterPage.UserSubscriptions,
        },
        children: [
          {
            path: 'all/',
            name: RouterPage.UserSubscriptions,
            component: () => import('@/pages/settings/components/UserSubscriptions.vue').catch(handleDynamicError),
          },
          {
            path: 'device-info/?offerId=:offerId/',
            name: RouterPage.SubscriptionInfo,
            props: true,
            component: () => import('@/components/subscription-info/SubscriptionInfo.vue').catch(handleDynamicError),
          },
        ],
      },
      {
        path: 'personal/',
        name: RouterPage.PersonalData,
        component: () => import('@/pages/settings/components/PersonalData.vue').catch(handleDynamicError),
      },
      {
        path: 'help/',
        name: RouterPage.HelpContacts,
        component: () => import('@/pages/settings/components/HelpContacts.vue').catch(handleDynamicError),
      },
    ],
  },
  {
    path: '/profiles/',
    name: RouterPage.ProfilesPage,
    component: () => import('@/pages/profile/ProfilesPage.vue').catch(handleDynamicError),
    beforeEnter: [],
  },
  {
    path: '/auth/',
    name: RouterPage.AuthPage,
    component: AuthPage,
  },

  {
    path: '/auth/login',
    name: RouterPage.LoginPage,
    component: () => import('@/pages/auth/LoginPage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/sign-in/:variant?',
    name: RouterPage.SignInPage,
    component: () => import('@/pages/auth/SignInPage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/sign-up',
    name: RouterPage.SignUpPage,
    component: () => import('@/pages/auth/SignUpPage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/recover-code',
    name: RouterPage.RecoverCodePage,
    component: () => import('@/pages/auth/RecoverCodePage.vue').catch(handleDynamicError),
  },
  {
    path: '/auth/recover-password',
    name: RouterPage.RecoverPasswordPage,
    component: () => import('@/pages/auth/RecoverPasswordPage.vue').catch(handleDynamicError),
  },

  {
    path: '/media-card/player/:id/:type/',
    name: RouterPage.MediaCardPlayerPage,
    component: () => import('@/pages/media-card/MediaCardPlayer.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/collections/',
    name: RouterPage.MediaCardCollectionsPage,
    component: () => import('@/pages/media-card/MediaCardCollectionsPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/collections/:collectionId/',
    name: RouterPage.MediaCardCollectionsInfoPage,
    component: () => import('@/pages/media-card/MediaCardCollectionsInfoPage.vue').catch(handleDynamicError),
  },
  {
    path: '/media-card/:type/:id/:activeEpisodeIndex?/:activeSeasonIndex?',
    name: RouterPage.MediaCardPage,
    component: MediaCardPage,
  },
];

export default routes;
