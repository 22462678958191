import useLogger from '@package/logger/src/use-logger';
import { AnalyticEventNameNew } from '@package/sdk/src/analytics/analytic-event-name';
import { AnalyticPageName } from '@package/sdk/src/analytics/analytic-page-name';
import { AnalyticParams } from '@package/sdk/src/analytics/composables/kmza';
import { Disposable } from '@package/sdk/src/core';
import { debounce } from '@package/sdk/src/core/std/async';
import type { AnyFunction } from '@package/sdk/src/core/structures/common';

import type { DeviceService } from '../device/device-service';
import type { EnvironmentService } from '../environment/environment-service';
import { FeatureService } from '../features/feature-service';
import { RouterPage } from '../router/router';
import type { RouterService } from '../router/router-service';
import DsmlService from './dsml-service';
import YandexMetricaService from './yandex-metrica-service';

const logger = useLogger('analytic-service', 'smarttv');

const AppToAnalyticPageMapper: Record<string, AnalyticPageName> = {
  [RouterPage.CollectionPage]: AnalyticPageName.Collections,
  [RouterPage.MomentsPage]: AnalyticPageName.Kinom,
  [RouterPage.MyChannelPage]: AnalyticPageName.MyChannel,
  [RouterPage.MainPage]: AnalyticPageName.Main,

  [RouterPage.MediaCardPlayerPage]: AnalyticPageName.Item,
  [RouterPage.MediaCardControls]: AnalyticPageName.Item,
  [RouterPage.MediaCardPage]: AnalyticPageName.Item,
  [RouterPage.MediaCardDescription]: AnalyticPageName.Item,
  [RouterPage.MediaCardPersons]: AnalyticPageName.Item,
  [RouterPage.MediaCardSimilarPage]: AnalyticPageName.Item,
  [RouterPage.MediaCardSeriesPage]: AnalyticPageName.Item,

  [RouterPage.SettingsPage]: AnalyticPageName.Account,
  [RouterPage.PersonalData]: AnalyticPageName.Account,
  [RouterPage.UserSubscriptions]: AnalyticPageName.Account,
  [RouterPage.ProfilesPage]: AnalyticPageName.Account,
  [RouterPage.ProfilePage]: AnalyticPageName.Account,
  [RouterPage.ParentalCodeRecover]: AnalyticPageName.Account,
  [RouterPage.ParentalControl]: AnalyticPageName.Account,
  [RouterPage.ParentalCode]: AnalyticPageName.Account,
  [RouterPage.ParentalRating]: AnalyticPageName.Account,
  [RouterPage.PartnerNoSubscriptionPage]: AnalyticPageName.Account,
  [RouterPage.HelpContacts]: AnalyticPageName.Account,
  [RouterPage.Subscriptions]: AnalyticPageName.Account,

  [RouterPage.ChannelsPage]: AnalyticPageName.Tv,
  [RouterPage.ChannelsPlayerPage]: AnalyticPageName.TvChannel,

  [RouterPage.SearchPage]: AnalyticPageName.Search,

  [RouterPage.CatalogPage]: AnalyticPageName.Catalog,
};

interface InternalAnalyticService {
  sendAnalyticEvent(params: AnalyticParams): unknown;

  getCurrentAnalyticPageValue(...args: any[]): unknown;
}

class WindowAnalyticService extends Disposable implements InternalAnalyticService {
  constructor(private readonly analyticService: AnalyticService) {
    super();
  }

  public getCurrentAnalyticPageValue(from?: RouterPage): AnalyticPageName {
    try {
      return this.analyticService.getAnalyticPageName(from);
    } catch (e) {
      logger.error('getCurrentAnalyticPageValue', e);

      return AnalyticPageName.Unknown;
    }
  }

  public sendAnalyticEvent(params: AnalyticParams): void {
    try {
      this.analyticService.sendEvent(params);
    } catch (e) {
      logger.error('sendAnalyticEvent', e);
    }
  }
}

const IS_YM_ENABLED = false;
const IS_DSML_ENABLED = true;

export class AnalyticService {
  private readonly sendEventDebounced: AnyFunction;
  private readonly dsml?: DsmlService;
  private readonly ym?: YandexMetricaService;
  private readonly _analyticSender: WindowAnalyticService;

  public get sender() {
    return this._analyticSender;
  }

  constructor(
    private readonly device: DeviceService,
    private readonly environment: EnvironmentService,
    private readonly routerService: RouterService,
    private readonly featureService: FeatureService,
  ) {
    if (IS_YM_ENABLED) {
      this.ym = new YandexMetricaService();
    }

    if (IS_DSML_ENABLED) {
      this.dsml = new DsmlService(this.device, this.environment);
    }

    this.sendEventDebounced = debounce((params) => {
      if (this.dsml) {
        this.dsml.sendEvent(params);
      }
    }, 5);

    this._analyticSender = new WindowAnalyticService(this);
  }

  public sendEvent(params: AnalyticParams): void {
    const data = {
      ...params,
      page: params.page || this.getAnalyticPageName(),
      name: params.name || params.event,
    };

    if (this.ym) {
      this.ym.sendEvent(params.name as AnalyticEventNameNew);
    }

    if (this.dsml) {
      this.dsml.sendEvent(data);
    }
  }

  public async init() {
    await this.featureService.fetchFeatureFlags();

    if (this.dsml) {
      await this.dsml.init();
    }

    if (this.ym) {
      this.ym.init();
    }
  }

  public setFeatureFlags(dict: Record<string, unknown>) {
    if (this.dsml) {
      this.dsml.setFeatureFlags(dict);
    }
  }

  public setUser(params: any): void {
    if (this.dsml) {
      this.dsml.setUser(params);
    }
  }

  public getAnalyticPageName(from?: RouterPage) {
    const { router } = this.routerService;

    return AppToAnalyticPageMapper[from || (router.currentRoute.value?.name as string)] || AnalyticPageName.Unknown;
  }
}
