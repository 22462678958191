<template></template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { CookieKey } from '@PLAYER/player/base/cookie';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useSafeVideoElement from '@PLAYER/player/modules/video/use-safe-video-element';
import useVolumeActions from '@PLAYER/player/modules/volume/use-volume-actions';
import { useCookies } from '@vueuse/integrations/useCookies';
import { computed, onMounted } from 'vue';

const videoEl = useSafeVideoElement();
const cookies = useCookies([CookieKey.VideoVolume]);
const volumeActions = useVolumeActions();
const logger = useLogger('InitialVolumeSetter.vue', 'media-player');

const { isSmartTV } = usePlatform();
const { isVOD, isLive, isMyChannelPlayer } = useProjector();

const isNeedAutoplay = computed(() => isVOD.value || isLive.value || isMyChannelPlayer.value);

const setInitialVolume = () => {
  const isMuted = videoEl.muted;

  if (isSmartTV) {
    return volumeActions.safeSetVolume(isMuted ? 0 : 1);
  }

  const cookieVolume = cookies.get(CookieKey.VideoVolume);

  const normalizedVolume = (() => {
    if (isNeedAutoplay.value && window.$vijuPlayer?.userHasMadeInteraction) {
      return cookies.get(cookieVolume) || 1;
    }

    return isMuted ? 0 : cookieVolume;
  })();

  logger.info('setInitialVolume', `Initial Volume - ${normalizedVolume}`);

  volumeActions.safeSetVolume(normalizedVolume);
};

onMounted(() => {
  setInitialVolume();
});
</script>
