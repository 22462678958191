<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 12C10 11.4477 10.4477 11 11 11H21C21.5523 11 22 11.4477 22 12V14C22 14.5523 21.5523 15 21 15H11C10.4477 15 10 14.5523 10 14V12Z"
      fill="#0A1E1F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 5C5.79086 5 4 6.79086 4 9V55C4 57.2091 5.79086 59 8 59H56C58.2091 59 60 57.2091 60 55V9C60 6.79086 58.2091 5 56 5H8ZM11 19C10.4477 19 10 19.4477 10 20V52C10 52.5523 10.4477 53 11 53H53C53.5523 53 54 52.5523 54 52V20C54 19.4477 53.5523 19 53 19H11Z"
      fill="#0A1E1F"
    />
    <circle cx="52" cy="13" r="2" fill="#00C7B2" />
    <circle cx="45" cy="13" r="2" fill="#00C7B2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.5482 27.2553C32.9471 26.5768 33.68 26.1211 34.5177 26.1211C35.7841 26.1211 36.8112 27.1642 36.8112 28.4504C36.8112 29.7365 35.7841 30.7796 34.5177 30.7796C33.68 30.7796 32.9471 30.3239 32.5482 29.6454C32.1493 30.3239 31.4164 30.7796 30.5787 30.7796C29.3123 30.7796 28.2852 29.7365 28.2852 28.4504C28.2852 27.1642 29.3123 26.1211 30.5787 26.1211C31.4164 26.1211 32.1493 26.5768 32.5482 27.2553ZM17.167 31.5879H20.1087L23.1003 38.0187L26.1418 31.5879H28.9339L24.3468 41.7658H21.804L17.167 31.5879ZM32.0244 31.5879H29.3818V41.7658H32.0244V31.5879ZM35.9142 31.5879H33.2218L33.1969 42.5C33.0224 43.7913 31.9753 43.7406 31.9753 43.7406H29.582V46.1205H32.3243C32.6484 46.1053 33.1321 46.0395 33.6705 45.8167C33.6705 45.8167 34.3337 45.5433 34.8173 45.1078C36.0239 44.0191 35.9292 41.9937 35.9142 41.7658V31.5879ZM36.9102 31.5879H39.6026V37.7655C39.6225 37.9225 39.7173 38.5909 40.3006 39.0821C40.8192 39.5226 41.3976 39.5378 41.597 39.5378C41.7865 39.5378 42.3399 39.5378 42.8584 39.1428C43.5465 38.6162 43.6313 37.801 43.6413 37.6643V31.5879H46.3337V38.3732C46.3337 38.3732 46.0844 41.2088 43.2922 41.8671C42.8934 41.9481 42.4346 42.0089 41.9261 42.0291C41.0037 42.0696 40.2109 41.9532 39.6026 41.8164C39.2785 41.6949 38.755 41.4569 38.2514 40.9809C37.6232 40.3885 37.334 39.7353 37.2093 39.3859C37.2093 39.3859 37.0597 39.1327 36.9102 37.8668V31.5879Z"
      fill="#00C7B2"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconVijuTv',
};
</script>
