import type ResponseHandler from '../handlers/ResponseHandler';
import RequestSender from '../RequestSender';

export default abstract class GetRequest<TData> {
  protected abstract url: string;

  protected requestInitPart: Omit<RequestInit, 'method'> = {};

  protected abstract responseHandler: ResponseHandler<TData>;

  private get requestInit(): RequestInit {
    const { requestInitPart } = this;

    return {
      ...requestInitPart,
      method: 'GET',
    };
  }

  public async send() {
    const { url, requestInit, responseHandler } = this;

    const response = await RequestSender.sendRequest(url, requestInit);

    const data = await responseHandler.handleResponse(response);

    return data;
  }
}
