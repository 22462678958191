import { CookieKey, getCookie, setCookie } from '../platform/cookie';
import storage from '../platform/memory-storage';
import { TokenResponseData } from '../request/create-token-request';
import { getLocalStorageItem, setLocalStorageItem } from './localstorage';

export function getLocalItem() {
  const isCookieStorage = storage.get('storageType') === 'cookie';

  return isCookieStorage ? getCookie('dsml.token') : getLocalStorageItem('dsml.token', null);
}

export function setLocalItem(name: CookieKey, value: TokenResponseData) {
  const isCookieStorage = storage.get('storageType') === 'cookie';

  isCookieStorage ? setCookie(name, value) : setLocalStorageItem(name, value);
}
