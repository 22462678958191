import {
  AppKeyboardEvent,
  DisposableStore,
  isFunction,
  KeyCode,
  UnexpectedComponentStateError,
} from '@package/sdk/src/core';
import { getActiveDocumentElement, isDevtoolMode } from '@PLAYER/player/base/dom';
import { AnyFunction } from '@PLAYER/player/base/function';
import useKeyboardHandler from '@PLAYER/player/modules/hooks/use-keyboard-handler';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { SmartTvCode } from '@PLAYER/player/modules/keyboard/smart-tv-key-codes';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount } from 'vue';

interface AddShortcutOptions {
  useDefaultPrevent: boolean;
}

interface AddLocalShortcutOptions {
  expression?: () => boolean;
}

export default function useKeyboardShortcuts() {
  const disposableStore = new DisposableStore();
  const layoutStore = useLayoutStore();
  const keyboard = useKeyboardHandler();
  const { isWeb, isSmartTV } = usePlatform();

  const { isDebugVisible, isFocused } = storeToRefs(layoutStore);

  const onSmartTvKeydown = (callback: AnyFunction) => {
    return (event: KeyboardEvent) => {
      if (event.keyCode !== SmartTvCode.Enter) {
        return;
      }

      event.preventDefault();

      return Reflect.apply(callback, undefined, []);
    };
  };

  const executeWebShortcutAction = (data: AppKeyboardEvent, cb: (event: AppKeyboardEvent) => void) => {
    if (!isFocused.value || isDebugVisible.value) {
      return;
    }

    // Если мы сфокусированы на <input> элементе, не даем совершать юзеру действия через горячие клавиши
    const activeElement = getActiveDocumentElement();
    if (activeElement && activeElement instanceof HTMLInputElement) {
      return;
    }

    const { event, type } = data;

    if (event.defaultPrevented && !isDevtoolMode) {
      return;
    }

    event.preventDefault();

    if (type === 'keydown') {
      Reflect.apply(cb, undefined, [data]);
    }
  };

  const addLocalShortcutWithCustomExpression = (
    code: KeyCode,
    listener: (event: AppKeyboardEvent) => void,
    options?: AddLocalShortcutOptions,
  ) => {
    disposableStore.add(
      keyboard.on(code, (data) => {
        if (isFunction(options?.expression) && options?.expression()) {
          return;
        }

        const { type, event } = data;

        // Событие уже где-то было преврано
        if (event.defaultPrevented) {
          return;
        }

        event.preventDefault();

        if (type === 'keydown') {
          listener(data);
        }
      }),
    );
  };

  const executeSmartTvShortcutAction = (
    data: AppKeyboardEvent,
    cb: (event: AppKeyboardEvent) => void,
    options?: AddShortcutOptions,
  ) => {
    const { event, type } = data;

    if (options?.useDefaultPrevent && event.defaultPrevented) {
      return;
    }

    if (type === 'keydown') {
      Reflect.apply(cb, undefined, [data]);
    }
  };

  const addGlobalShortcut = (
    code: string | number,
    listener: (event: AppKeyboardEvent) => void,
    options?: AddShortcutOptions,
  ) => {
    disposableStore.add(
      keyboard.on(code, (data) => {
        if (isWeb) {
          return executeWebShortcutAction(data, listener);
        }

        if (isSmartTV) {
          return executeSmartTvShortcutAction(data, listener, options);
        }

        throw new UnexpectedComponentStateError('expect platform to be defined');
      }),
    );
  };

  onBeforeUnmount(() => {
    disposableStore.dispose();
  });

  return {
    onSmartTvKeydown,
    addLocalShortcutWithCustomExpression,
    addGlobalShortcut,
  };
}
