<template>
  <timeline-playback
    v-show="isWeb"
    :ref="(comp) => (timelineEl = comp?.$el)"
    :current-time="currentTime"
    :duration="duration"
    :buffer-length="bufferLength"
    :buffer-start="bufferStart"
    :is-timeline-hover-handle-shown="false"
    :is-timeline-playback-buttons-shown="false"
    :current-handle-position="currentHandlePosition"
  />
</template>

<script lang="ts" setup>
import { DisposableStore } from '@package/sdk/src/core';
import TimelinePlayback from '@PLAYER/player/components/timeline/TimelinePlayback.vue';
import useKinomMetadata from '@PLAYER/player/modules/content/use-kinom-metadata';
import useCroppedTimelineVideo from '@PLAYER/player/modules/hooks/use-cropped-timeline-video';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoTimeline from '@PLAYER/player/modules/timeline/use-video-timeline';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import useSafeVideoElement from '@PLAYER/player/modules/video/use-safe-video-element';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import { watchPausable } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, ref, watch } from 'vue';

const props = defineProps<{
  loop: boolean;
  bufferLength: number;
  bufferStart: number;
}>();

const videoEl = useSafeVideoElement();
const timelineEl = ref<HTMLElement>();
const { duration, startOffset } = useKinomMetadata();

const disposableStore = new DisposableStore();

const videoInteractions = useVideoInteractions();
const videoEventHandler = useSafeVideoEventHandler();
const { isMyChannelPlayer } = useProjector();
const { isWeb } = usePlatform();
const { isPlayerIntersect, isDocumentVisible } = storeToRefs(useLayoutStore());

const playbackActions = usePlaybackActions();

let kinomEndedTimeoutId: number;

const { currentTime } = useCroppedTimelineVideo({
  videoEl: videoEl.domElement as HTMLVideoElement,
  startOffset,
});

const isPlaying = ref(false);

const { currentHandlePosition } = useVideoTimeline(timelineEl, {
  currentTime,
  duration,
  play: videoInteractions.play,
  pause: videoInteractions.pause,
  changeCurrentTime(val: number) {
    videoInteractions.changeCurrentTime({
      seconds: val + startOffset,
      manually: true,
    });
  },
  isPlaying,
});

const isManuallyPaused = ref(false);

const onPause = () => {
  isPlaying.value = false;

  if (isDocumentVisible.value) {
    isManuallyPaused.value = true;
  }
};

const pausableCurrentTimeWatcher = watchPausable(currentTime, (time) => {
  if (time >= duration.value) {
    videoEventHandler.trigger('ended');
    pausableCurrentTimeWatcher.pause();
  }
});

const playVideoFromStart = () => {
  videoInteractions.changeCurrentTime({
    seconds: startOffset,
    manually: false,
  });
  playbackActions.doPlay({ manual: false });
  pausableCurrentTimeWatcher.resume();
};

const CHANGE_CURRENT_TIME_TIMEOUT = 500;

const onEnded = () => {
  if (props.loop) {
    return playVideoFromStart();
  }

  playbackActions.doPause({ manual: false });

  kinomEndedTimeoutId = window.setTimeout(() => {
    videoInteractions.changeCurrentTime({
      seconds: startOffset,
      manually: false,
    });
  }, CHANGE_CURRENT_TIME_TIMEOUT);
};

const onPlay = () => {
  isPlaying.value = true;

  if (isDocumentVisible.value) {
    isManuallyPaused.value = false;
  }

  pausableCurrentTimeWatcher.resume();

  if (isPlayerIntersect.value || isMyChannelPlayer.value) {
    return;
  }

  if (isWeb) {
    // Если плеер вдруг был добавлен на страницу в тот момент, когда он находился "вне" видимой области экрана
    playbackActions.doPause({ manual: false });
  }
};

disposableStore.add(videoEventHandler.addEventListener('play', onPlay));
disposableStore.add(videoEventHandler.addEventListener('pause', onPause));
disposableStore.add(videoEventHandler.addEventListener('ended', onEnded));

watch(isDocumentVisible, (visible) => {
  if (!isPlayerIntersect.value) {
    return;
  }

  if (visible && !isManuallyPaused.value) {
    return playbackActions.doPlay({ manual: false });
  }

  playbackActions.doPause({ manual: false });
});

onBeforeUnmount(() => {
  if (kinomEndedTimeoutId) {
    window.clearTimeout(kinomEndedTimeoutId);
  }

  disposableStore.dispose();
});
</script>
