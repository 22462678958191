export default function useRealNativeHlsPlaybackSupport() {
  const videoEl = document.createElement('video');

  // HLS manifests can go by many mime-types
  const canPlay = [
    // Apple santioned
    'application/vnd.apple.mpegurl',
    // Apple sanctioned for backwards compatibility
    'audio/mpegurl',
    // Very common
    'audio/x-mpegurl',
    // Very common
    'application/x-mpegurl',
    // Included for completeness
    'video/x-mpegurl',
    'video/mpegurl',
    'application/mpegurl',
  ];

  return canPlay.some((canItPlay) => /maybe|probably/i.test(videoEl.canPlayType(canItPlay)));
}
