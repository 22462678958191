const DECIMAL_RESOLUTION_REGEX = /^(\d+)x(\d+)$/;
const ATTR_LIST_REGEX = /(.+?)=(".*?"|.*?)(?:,|$)/g;

// adapted from https://github.com/kanongil/node-m3u8parse/blob/master/attrlist.js
export class AttrList {
  [key: string]: any;

  constructor(attrs: string | Record<string, any>) {
    if (typeof attrs === 'string') {
      attrs = AttrList.parseAttrList(attrs);
    }

    for (const attr in attrs) {
      if (Object.prototype.hasOwnProperty.call(attrs, attr)) {
        if (attr.substring(0, 2) === 'X-') {
          this.clientAttrs = this.clientAttrs || [];
          this.clientAttrs.push(attr);
        }
        this[attr] = attrs[attr];
      }
    }
  }

  public decimalInteger(attrName: string): number {
    const intValue = parseInt(this[attrName], 10);
    if (intValue > Number.MAX_SAFE_INTEGER) {
      return Infinity;
    }

    return intValue;
  }

  public hexadecimalInteger(attrName: string) {
    if (this[attrName]) {
      let stringValue = (this[attrName] || '0x').slice(2);
      stringValue = (stringValue.length & 1 ? '0' : '') + stringValue;

      const value = new Uint8Array(stringValue.length / 2);
      for (let i = 0; i < stringValue.length / 2; i++) {
        value[i] = parseInt(stringValue.slice(i * 2, i * 2 + 2), 16);
      }

      return value;
    } else {
      return null;
    }
  }

  public hexadecimalIntegerAsNumber(attrName: string): number {
    const intValue = parseInt(this[attrName], 16);
    if (intValue > Number.MAX_SAFE_INTEGER) {
      return Infinity;
    }

    return intValue;
  }

  public decimalFloatingPoint(attrName: string): number {
    return parseFloat(this[attrName]);
  }

  public optionalFloat(attrName: string, defaultValue: number): number {
    const value = this[attrName];
    return value ? parseFloat(value) : defaultValue;
  }

  public enumeratedString(attrName: string): string | undefined {
    return this[attrName];
  }

  public bool(attrName: string): boolean {
    return this[attrName] === 'YES';
  }

  public decimalResolution(attrName: string):
    | {
        width: number;
        height: number;
      }
    | undefined {
    const res = DECIMAL_RESOLUTION_REGEX.exec(this[attrName]);
    if (res === null) {
      return undefined;
    }

    return {
      width: parseInt(res[1], 10),
      height: parseInt(res[2], 10),
    };
  }

  public static parseAttrList(input: string): Record<string, string> {
    let match;
    const attrs: Record<string, string> = {};
    const quote = '"';
    ATTR_LIST_REGEX.lastIndex = 0;

    while ((match = ATTR_LIST_REGEX.exec(input)) !== null) {
      let value = match[2];

      if (value.indexOf(quote) === 0 && value.lastIndexOf(quote) === value.length - 1) {
        value = value.slice(1, -1);
      }
      const name = match[1].trim();
      attrs[name] = value;
    }

    return attrs;
  }
}
