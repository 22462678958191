import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';
import type { AnalyticEventSender } from '../index';

export function useSubscribeAnalytics(sender: AnalyticEventSender) {
  const onShowSubscribeOfferPage = (offerType: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowSubscribeOfferPage,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.OfferType,
          value: offerType,
        },
      ],
    });
  };

  const onClickSubscribeOfferChoose = (offerId: string) => {
    sender.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeOfferChoose,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Offer,
          value: offerId,
        },
      ],
    });
  };

  return {
    onShowSubscribeOfferPage,
    onClickSubscribeOfferChoose,
  };
}
