<template>
  <ScrollViewport ref="el" tag="div" :class="$style.seasons" orientation="horizontal" role="list">
    <NavigatableItem
      v-for="(season, index) in seasons"
      :key="season.id"
      :class="{ [$style.season]: true, [$style.activeSeason]: activeSeason === index }"
      :tag="AppButton"
      :data-testid="`seasons-${index + 1}`"
      :data-index="index"
      :text="$t('pages.mediaCard.season', { index: season.number })"
      @active="(element) => emit('active', { element, index })"
      @click="emit('click', index)"
    />
  </ScrollViewport>
</template>

<script setup lang="ts">
import { Season } from '@package/sdk/src/api';
import { FocusKeys } from '@package/smarttv-base/src';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { provide } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

defineProps<{
  activeSeason: number;
  seasons: Season[];
}>();

const emit = defineEmits<{
  (event: 'active', options: { element?: HTMLElement; index: number }): void;
  (event: 'click', index: number): void;
}>();

const { focusKey, el } = useNavigatable({
  saveLastFocusedChild: true,
  focusKey: FocusKeys.MEDIA_CARD_PAGE_SEASONS_SLIDER,
  focusBoundaryDirections: ['right'],
  hasGlobalAccess: true,
  isFocusBoundary: true,
});
provide('parentFocusKey', focusKey.value);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.seasons {
  display: flex;
  margin-bottom: adjust.adjustPx(32px);
}
.season {
  width: adjust.adjustPx(163px);
  min-width: adjust.adjustPx(163px);
  height: adjust.adjustPx(80px);
  min-height: adjust.adjustPx(80px);
  margin-right: adjust.adjustPx(24px);
  background-color: var(--color-bg-tertiary);
}

.activeSeason {
  background-color: var(--color-bg-button);
}
</style>
