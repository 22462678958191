import useLayoutStore from '@PLAYER/player/modules/store/layout-store';

export type LoaderType = 'normal' | 'light' | null;

export function useLoaderActions() {
  const layout = useLayoutStore();

  const releaseLoader = () => {
    layout.setLoader(null);
  };

  const requestLoader = (type: LoaderType = 'normal') => {
    layout.setLoader(type);
  };

  return {
    releaseLoader,
    requestLoader,
  };
}
