import type { DsmlAdditionalOptions, DsmlValue } from '../dsml';
import storage from '../platform/memory-storage';
import { getUTCTimestamp } from '../platform/std';
import type { BaseDsmlEventPayload, StorageFields } from '../request/abstract-send-event-request';
import type { DsmlRequestBodyParams } from '../request/send-single-event-request';
import { getUserTimezone } from './date';
import sessionOrderCounter from './session-order-counter';

export type AdditionalOptions = {
  timecode?: number;
  item_id?: string;
  episode_id?: string;
  kinom_id?: string;
};

export default function transformBodyToV1Version(
  name: string,
  _: string,
  values: DsmlValue[],
  options?: DsmlAdditionalOptions,
): DsmlRequestBodyParams {
  const utcTimestamp = getUTCTimestamp();

  const normalizedOptions: AdditionalOptions = {};

  if (options?.timecode) {
    normalizedOptions.timecode = options.timecode;
  }

  if (options?.itemId) {
    normalizedOptions.item_id = options.itemId;
  }

  if (options?.episodeId) {
    normalizedOptions.episode_id = options.episodeId;
  }

  if (options?.kinomId) {
    normalizedOptions.kinom_id = options.kinomId;
  }

  const timestamps: BaseDsmlEventPayload = {
    utc_timestamp: utcTimestamp,
    user_timezone: getUserTimezone(),
  };

  return {
    ...normalizedOptions,
    ...timestamps,
    ...(storage.getAll() as unknown as StorageFields),
    event_type_id: name,
    session_order: sessionOrderCounter.getNext(),
    value: values,
  };
}
