import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export default function usePlaybackVariables() {
  const { isKinom, isVOD, isContentBackgroundPlayer } = useProjector();
  const { isLiveWithDVR } = useVideoPlayerVariables();
  const { isInitialized } = storeToRefs(useVideoUIStore());

  const isKinomMetadataControllerShown = computed(() => {
    if (isContentBackgroundPlayer.value) {
      return false;
    }

    return isKinom.value && isInitialized.value;
  });

  const isTimelinePlaybackShown = computed(() => {
    if (isKinom.value || !isInitialized.value) {
      return false;
    }

    if (isVOD.value) {
      return true;
    }

    return isLiveWithDVR.value;
  });

  return {
    isKinomMetadataControllerShown,
    isTimelinePlaybackShown,
  };
}
