import { ProcessingMapper } from '../base/processing-mapper';
import { ProfileType } from './types/profile-type';

export interface Profile {
  id: string;
  name: string;
  kind: ProfileType;
  ageLimit: number;
}

export interface ApiProfile {
  id: string;
  name: string;
  kind: ProfileType;
  age_limit: number;
}

export class ProfilesMapper {
  static mapMany(profiles: ApiProfile[]): Profile[] {
    return ProcessingMapper.process(profiles.map(ProfilesMapper.map));
  }

  static map({ age_limit, ...rest }: ApiProfile): Profile {
    return ProcessingMapper.process({
      ageLimit: age_limit,
      ...rest,
    });
  }
}
