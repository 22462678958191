import { globalSettings } from '../services/global-settings';

interface VNodeMountedOptions {
  withTimeout?: boolean;
  timeout?: number;
}

export default function useVNodeMounted(options: VNodeMountedOptions = {}) {
  const isVNodeMounted = globalSettings.ref(false);
  const { withTimeout = true, timeout = 1000 } = options;

  let vNodeTimeoutId: number;

  const onVNodeMounted = () => {
    if (withTimeout) {
      vNodeTimeoutId = window.setTimeout(() => (isVNodeMounted.value = true), timeout);
      return;
    }

    isVNodeMounted.value = true;
  };

  globalSettings.onBeforeUnmount(() => {
    if (vNodeTimeoutId) {
      window.clearTimeout(vNodeTimeoutId);
    }
  });

  return {
    isVNodeMounted,
    onVNodeMounted,
  };
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('should isVNodeMounted without timeout to be true', () => {
    const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: false });

    onVNodeMounted();

    expect(isVNodeMounted.value).toBeTruthy();
  });

  it('should isVNodeMounted with timeout to be false', () => {
    const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: true });

    onVNodeMounted();

    expect(isVNodeMounted.value).toBeFalsy();
  });
}
