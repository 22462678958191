<template>
  <picture>
    <source v-if="src && withSrcSet" :srcset="srcSet" />
    <img
      v-if="src"
      ref="imageEl"
      :alt="alt"
      :class="{
        [$style.img]: true,
        [imageClass]: imageClass || undefined,
        stub: useStub && loadingError,
      }"
      :fetchpriority="imageFetchPriority"
      :loading="loading"
      :src="src"
      @error="onError"
      @load="onLoad"
    />
  </picture>
</template>

<script>
export default {
  props: {
    src: { type: String, required: true },
    alt: { type: String, default: '' },
    useStub: { type: Boolean, default: false },
    withSrcSet: { type: Boolean, default: true },
    imageFetchPriority: { type: String, default: 'auto' },
    /** @type {'eager'|'lazy'} */
    loading: { type: String, default: 'eager' },
    imageClass: { type: String },
    srcSet: { type: String },
    stub: { type: String },
    loadingError: { type: Boolean },
  },
  emits: ['error', 'load'],
  methods: {
    onError() {
      this.$emit('error');
    },
    onLoad() {
      this.$emit('load');
    },
  },
};
</script>

<style lang="scss" module>
.img {
  object-fit: cover;
}
</style>
