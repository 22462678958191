import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import type { PlayerInstanceConstructorOptions } from '@package/media-player/src/player/modules/instance/interfaces';
import { FeatureToggle } from '@package/sdk/src/api';
import { analyticService, environmentService, featuresService, usePlayerSentryError } from '@SMART/index';
import { onBeforeUnmount } from 'vue';

export default function useSmartTVPlayer(options: PlayerInstanceConstructorOptions) {
  const { startRecordingPlayerExceptions } = usePlayerSentryError();

  const isRelease = environmentService.getVariable<boolean>('isRelease');

  const defaultOptions: Partial<PlayerInstanceConstructorOptions> = {
    muted: false,
    autoplay: true,
  };

  const _options = {
    ...defaultOptions,
    ...options,
  };

  const player = new SmartTvVijuPlayer(_options);
  startRecordingPlayerExceptions(player);

  const isShakaApiEnabled = (() => {
    // для остальных - welcome

    if (options.projector === 'live' || options.projector === 'vod') {
      return featuresService.getFeatureFlag(FeatureToggle.ShakaPlayerApiEnabled)?.status || false;
    }

    return true;
  })();

  player.setConfigProperties({
    'experimental.feature.shakaPlayerAPI': isShakaApiEnabled,
    'system.isDebugEnabled': !isRelease,
    'analytic.metadata': { page: analyticService.getAnalyticPageName() },
  });

  onBeforeUnmount(() => {
    player.endMediaSession();
  });

  return player;
}
