<template>
  <section :class="$style.qr">
    <div :class="$style.qrVertical">
      <div :class="$style.qrContainer">
        <h1 :class="$style.header">{{ $t('pages.auth.qrLogin.qrTitle') }}</h1>

        <div :class="$style.hintBlock">{{ $t('pages.auth.qrLogin.qrHint') }}</div>

        <canvas ref="canvasRef" :class="$style.qrCanvas" @click="onQRCodeClick()" />
      </div>

      <div :class="$style.divider" />
    </div>
  </section>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { AnalyticAuthType, useAuthPageAnalytics } from '@package/sdk/src/analytics';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import {
  analyticService,
  authService,
  deviceService,
  environmentService,
  onboardingService,
  OperationSystem,
  qrService,
  useAuthActions,
  WebAppRoute,
  WebRouteQuery,
} from '@package/smarttv-base/src';
import { onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue';

defineProps<{
  code: string;
}>();

const emit = defineEmits<{
  (e: 'update:code', code: string): void;
}>();

const { redirectWithHistoryClear } = useAuthActions();
const authPageAnalytics = useAuthPageAnalytics(analyticService.sender);

const canvasRef = useTemplateRef<HTMLCanvasElement>('canvasRef');
const webAppURL = environmentService.getVariable('webAppURL');

const webURL = ref('');

let checkTvTokenInterval: number;

const generateQr = async () => {
  const { tvCode, tvToken } = await authService.createTvToken();

  emit('update:code', tvCode);

  if (!canvasRef.value) {
    throw new UnexpectedComponentStateError('canvasRef');
  }

  const url = webAppURL + WebAppRoute.SmartTvCode + '?' + WebRouteQuery.SmartTvCode + '=' + tvCode;
  webURL.value = url;

  await qrService.createQRCode({
    canvasEl: canvasRef.value,
    text: url,
  });

  const onTokenUpdated = async () => {
    try {
      await authService.checkTvToken(tvToken);

      authPageAnalytics.onSuccessAuthorization({ authMethod: 'login', auth: AnalyticAuthType.Authorization });
      onboardingService.resetParentalCode();

      await redirectWithHistoryClear();
    } catch (error) {
      // it's ok
    }
  };

  checkTvTokenInterval = window.setInterval(
    onTokenUpdated,
    ConstantsConfigInstanceSmartTV.getProperty('authCheckTvTokenTimeoutMs'),
  );
};

const onQRCodeClick = () => {
  if (deviceService.os !== OperationSystem.Desktop) {
    return;
  }

  const browserWindow = window.open(webURL.value, '_blank');

  window.setTimeout(() => browserWindow?.close(), 10000);
};

onMounted(generateQr);

onBeforeUnmount(() => {
  if (checkTvTokenInterval) {
    window.clearInterval(checkTvTokenInterval);
  }
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.qr {
  position: relative;
  display: flex;
  width: 50%;
  height: fit-content;
  flex-direction: column;
  margin-right: adjust.adjustPx(80px);
}

.qrVertical {
  position: relative;
  display: flex;
  margin-bottom: adjust.adjustPx(120px);
}

.qrContainer {
  display: flex;
  flex-direction: column;
}

.qrCanvas {
  width: adjust.adjustPx(432px) !important;
  height: adjust.adjustPx(432px) !important;

  border-radius: adjust.adjustPx(24px);
}

.divider {
  position: absolute;
  right: adjust.adjustPx(-80px);
  width: 2px;
  height: 100%;
  background-color: var(--color-stroke-div-primary);
}

.header {
  @include smartTvFonts.SmartTvTitle-3();

  margin-bottom: adjust.adjustPx(16px);
}

.hintBlock {
  line-height: adjust.adjustPx(52px);
  color: var(--color-text-tertiary);
  margin-bottom: adjust.adjustPx(48px);
}
</style>
