class SessionOrderCounter {
  private _next = 0;

  public get value(): number {
    return this._next;
  }

  public getNext(): number {
    return this._next++;
  }

  public reset(): void {
    this._next = 0;
  }
}

export default new SessionOrderCounter();
