import { ApiYear } from './content-types/year';
import { Year } from './types/year';

export class YearMapper {
  static mapMany(genres: ApiYear[]): Year[] {
    return genres.map(YearMapper.map);
  }

  static map({ from, to, printed }: ApiYear): Year {
    return {
      id: printed,
      filterType: 'year',
      slug: printed,
      title: printed,
      from,
      to,
      printed,
    };
  }
}
