import { globalSettings } from '../services';

export function useMouseDetect() {
  const ref = globalSettings.ref;
  const onMounted = globalSettings.onMounted;
  const isMouseDetected = ref(false);

  const tick = () => {
    isMouseDetected.value = true;

    window.removeEventListener('mousedown', tick);
    window.removeEventListener('mousemove', tick);
    window.removeEventListener('mouseup', tick);
  };

  onMounted(() => {
    window.addEventListener('mousedown', tick);
    window.addEventListener('mousemove', tick);
    window.addEventListener('mouseup', tick);
  });

  return {
    isMouseDetected,
  };
}
