import type { ControlsStateEvent } from '@PLAYER/player/modules/event/internal-event';
import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';

export default function useVideoControlsActions() {
  const eventBus = useSafeEventBus();

  const requestVideoControls = () => {
    eventBus.emit(
      'onControlsStateUpdated',
      new VideoPlayerInternalEvent<ControlsStateEvent>({
        visible: true,
      }),
    );
  };

  const releaseVideoControls = () => {
    eventBus.emit(
      'onControlsStateUpdated',
      new VideoPlayerInternalEvent<ControlsStateEvent>({
        visible: false,
      }),
    );
  };

  return {
    releaseVideoControls,
    requestVideoControls,
  };
}
