import {
  ClientType,
  PartnerId,
  SessionId,
  StorageType,
  UserId,
  UserIpV4,
  UserProfileId,
  VisitorId,
} from '../code/user';
import { DsmlAdditionalOptions, DsmlValue } from '../dsml';
import { DsmlEventError } from '../platform/errors';
import PostRequest from './api/getRequest/PostRequest';
import JSONResponseHandler from './api/handlers/JSONResponseHandler';
import RequestSender from './api/RequestSender';
import { DSML_ENDPOINTS, getBaseUrl } from './endpoint';

export interface BaseDsmlEventPayload {
  utc_timestamp: number;
  user_timezone: number;
}

export interface StorageFields {
  partner_id: PartnerId;
  user_id: UserId;
  profile_id: UserProfileId;
  visitor_id: VisitorId;
  user_ip_v4: UserIpV4;
  session_id: SessionId;
  client_type: ClientType;
  storage: StorageType;
}

export interface SendEventPayload extends BaseDsmlEventPayload, StorageFields {
  value: DsmlValue[];
  options?: DsmlAdditionalOptions;
  event_type_id: string;
  timecode?: number;
}

export interface SendEventRequestResponse {
  warning: boolean;
  details: string[];
}

export abstract class AbstractSendEventRequest extends PostRequest<SendEventRequestResponse> {
  protected responseHandler = new JSONResponseHandler<SendEventRequestResponse>();

  private token: string | undefined;
  public setToken(str: string): void {
    this.token = str;
  }

  public constructor(protected readonly payload: unknown) {
    super();
  }

  public async send() {
    const { url, requestInit, timeout, responseHandler } = this;

    const response = await RequestSender.sendRequest(url, requestInit, timeout);
    const data = await responseHandler.handleResponse(response);

    if (data.warning) {
      throw new DsmlEventError(requestInit, data.details);
    }

    return data;
  }

  protected get additionalHeaders() {
    return {
      Authorization: `Bearer ${this.token}`,
    };
  }

  protected get body() {
    return this.payload;
  }

  protected get url() {
    return `${getBaseUrl()}/${DSML_ENDPOINTS.EVENTS_SEND}`;
  }
}
