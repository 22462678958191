<template></template>

<script lang="ts" setup>
import { isDefined } from '@package/sdk/src/core';
import useMediaTimestamps from '@PLAYER/player/modules/content/use-media-timestamps';
import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import onInitialPlaybackStarted from '@PLAYER/player/modules/hooks/on-initial-playback-started';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { watchPausable } from '@vueuse/core';
import { storeToRefs } from 'pinia';

const { timestamps } = useMediaTimestamps();
const { exactDisplayedCurrentTime } = storeToRefs(useVideoUIStore());
const eventBus = useSafeEventBus();

const { normalizedDuration } = useVideoPlayerVariables();

const tick = (time: number) => {
  if (!normalizedDuration.value) {
    return;
  }

  for (const timestamp of timestamps.value) {
    const { start, end, type } = timestamp;

    const isStartPresent = isDefined(start);
    const isEndPresent = isDefined(end);

    // Если есть конечные границы у timestamp
    if (isStartPresent && isEndPresent) {
      const isReached = time > start && end >= time;
      const eventName = isReached ? 'onTimestampReached' : 'onTimestampPassed';

      eventBus.emit(eventName, new VideoPlayerInternalEvent({ type, timestamp }));
      continue;
    }

    // Если timestamp нужно проиграть с конца
    if (isEndPresent && end < 0 && !isStartPresent) {
      const diff = exactDisplayedCurrentTime.value - normalizedDuration.value;
      const isReached = diff > end;

      const eventName = isReached ? 'onTimestampReached' : 'onTimestampPassed';

      eventBus.emit(eventName, new VideoPlayerInternalEvent({ type, diff, timestamp }));
    }
  }
};

const pausableTickWatcher = watchPausable(exactDisplayedCurrentTime, tick);
pausableTickWatcher.pause();

onInitialPlaybackStarted(() => {
  pausableTickWatcher.resume();
});
</script>
