const boundaryPadding = 5;

const calculateLabelPosition =
  (isFullscreenEnabled: boolean) => (rootEl: HTMLElement) => (labelEl: HTMLElement, rootVideoEl: HTMLElement) => {
    const elRect = rootEl.getBoundingClientRect();
    const labelRect = labelEl.getBoundingClientRect();
    const rootVideoElRect = rootVideoEl.getBoundingClientRect();

    const topDiff = isFullscreenEnabled ? 9 : 2;
    const leftDiff = isFullscreenEnabled ? 20 : 0;

    const desiredTopCenterPointX = elRect.left + elRect.width / 2;
    const desiredTopCenterPointY = rootVideoElRect.height - labelRect.height * 4 - topDiff;

    const desiredTopLeftPointX = desiredTopCenterPointX - labelRect.width / 1.5 + leftDiff;
    const desiredTopLeftPointY = desiredTopCenterPointY;

    let realTopLeftPointX;
    const realTopLeftPointY = desiredTopLeftPointY;
    const desiredTopRightPointX = desiredTopLeftPointX + labelRect.width;
    const maximalAllowedPointX = rootVideoElRect.width - boundaryPadding;

    if (desiredTopLeftPointX < boundaryPadding) {
      realTopLeftPointX = boundaryPadding;
    } else if (desiredTopRightPointX > maximalAllowedPointX) {
      realTopLeftPointX = maximalAllowedPointX - labelRect.width;
    } else {
      realTopLeftPointX = desiredTopLeftPointX;
    }

    return {
      left: realTopLeftPointX,
      top: realTopLeftPointY,
    };
  };

export default calculateLabelPosition;
