/**
 * @vitest-environment node
 */

export function roundNumber(value: number) {
  return Math.round(value);
}

export type TimeSeconds = number;

export type TimeMilliseconds = number;

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('should 55.555 round to 56', () => {
    expect(roundNumber(55.555)).toBe(56);
  });
}
