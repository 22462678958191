import { CloseAllControlElements, VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import { onBeforeUnmount, ref } from 'vue';

export default function useTooltipState(closeAllTooltipsOnOpen = true) {
  const externalEventBus = useSafeExternalEventBus();
  const isTooltipShown = ref(false);

  const closeTooltip = () => {
    isTooltipShown.value = false;
  };

  const emitCloseAllControlElements = () => {
    externalEventBus.emit(
      'close-all-control-elements',
      new VideoPlayerExternalEvent<CloseAllControlElements>({ shouldBeIgnored: false }),
    );
  };

  const openTooltip = () => {
    if (closeAllTooltipsOnOpen) {
      emitCloseAllControlElements();
    }

    isTooltipShown.value = true;
  };

  if (closeAllTooltipsOnOpen) {
    externalEventBus.on('close-all-control-elements', closeTooltip);
  }

  onBeforeUnmount(() => {
    if (closeAllTooltipsOnOpen) {
      externalEventBus.removeEventListener('close-all-control-elements', closeTooltip);
    }
  });

  return {
    isTooltipShown,
    openTooltip,
    closeTooltip,
    emitCloseAllControlElements,
  };
}
