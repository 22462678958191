<template>
  <video-control-button :on-click="onClosePlayer" :is-disabled="false" :is-label-shown="false" :text="'close'">
    <icon-close />
  </video-control-button>
</template>

<script lang="ts" setup>
import IconClose from '@PLAYER/player/components/icons/IconClose.vue';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import { storeToRefs } from 'pinia';

import VideoControlButton from '../controls/VideoControlButton.vue';

const externalEventBus = useSafeExternalEventBus();
const { isFullScreenEnabled, isPictureInPictureEnabled } = storeToRefs(useVideoUIStore());
const videoInteractions = useVideoInteractions();

const onClosePlayer = () => {
  if (isFullScreenEnabled.value) {
    videoInteractions.exitFullscreen();
  }

  if (isPictureInPictureEnabled.value) {
    videoInteractions.closePictureInPicture();
  }

  externalEventBus.emit('close', new VideoPlayerExternalEvent(undefined));
};
</script>
