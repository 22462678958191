import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import type { Playlist } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import { customEventsService, FocusKeys } from '@SMART/index';
import { nextTick, ref } from 'vue';

export const useDropdown = (
  onPlay: (state: boolean) => void,
  updateContent: (step: number) => Promise<void>,
  resetMoments: () => void,
) => {
  const playlistOffset = ref(0);
  const momentOffset = ref(0);
  const activePlaylist = ref<Partial<Playlist>>({});
  const isPlaylistsOpen = ref(false);
  const isMomentsOpen = ref(false);
  const activeMomentIndex = ref(0);
  const activePlaylistIndex = ref(0);

  const onBackPress = () => {
    let focusKey = '';
    if (isPlaylistsOpen.value) {
      focusKey = FocusKeys.PLAYLIST_DROPDOWN;
    } else if (isMomentsOpen.value) {
      focusKey = FocusKeys.MOMENTS_DROPDOWN;
    }

    isPlaylistsOpen.value = false;
    isMomentsOpen.value = false;

    focusKey && SpatialNavigation.setFocus(focusKey);

    onPlay(true);
  };

  const onSetMomentIndex = (index: number) => {
    activeMomentIndex.value = index;
  };

  const onSetPlaylistIndex = (index: number) => {
    activePlaylistIndex.value = index;
  };

  const togglePlaylists = async () => {
    isPlaylistsOpen.value = !isPlaylistsOpen.value;
    onPlay(!isPlaylistsOpen.value);
    if (isPlaylistsOpen.value) {
      customEventsService.setOnPressBackCallback(onBackPress, true);
    }

    isMomentsOpen.value = false;
    momentOffset.value = 0;
  };

  const toggleMoments = async (activeIndex: number) => {
    isMomentsOpen.value = !isMomentsOpen.value;
    onPlay(!isMomentsOpen.value);
    if (isMomentsOpen.value) {
      customEventsService.setOnPressBackCallback(onBackPress, true);
    }

    isPlaylistsOpen.value = false;

    await nextTick();
    if (isMomentsOpen.value) {
      const index = activeIndex;
      SpatialNavigation.setFocus(`${FocusKeys.MOMENTS_DROPDOWN}-${index}`);
    }
  };

  const selectPlaylist = async (playlist?: Playlist) => {
    activePlaylist.value = playlist || ({} as Playlist);
    momentOffset.value = 0;
    resetMoments();
    onSetMomentIndex(0);
    await updateContent(0);
  };

  const selectMoment = async (index: number, currentMomentIndex: number, currentPage: number) => {
    const pagedIndex =
      currentMomentIndex + ConstantsConfigInstanceSmartTV.getProperty('dropdownPageSize') * (currentPage - 1);

    await updateContent(index - pagedIndex);
  };

  const setPlaylistOffset = (offset: number) => {
    playlistOffset.value = offset;
  };

  const setMomentOffset = (offset: number) => {
    momentOffset.value = offset;
  };

  return {
    playlistOffset,
    momentOffset,
    activePlaylist,
    isPlaylistsOpen,
    isMomentsOpen,
    activeMomentIndex,
    activePlaylistIndex,
    setPlaylistOffset,
    setMomentOffset,
    selectPlaylist,
    selectMoment,
    toggleMoments,
    togglePlaylists,
    onSetMomentIndex,
    onSetPlaylistIndex,
  };
};
