<template>
  <div ref="playerEl" :class="$style.player">
    <app-image :use-fallback-icon="false" :src="content.background" :width="1280" :class="$style.preview" />
  </div>
</template>

<script setup lang="ts">
import type { Media, Moment } from '@package/sdk/src/api';
import { timeout, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { nextTick, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';

import AppImage from '@/components/app-image/AppImage.vue';
import useSmartTVPlayer from '@/sdk/player/use-smarttv-player';

const props = defineProps<{
  moments: Moment[];
  content: Media;
}>();

const playerEl = ref<HTMLElement>();
const currentMomentIndex = ref(0);

const player = useSmartTVPlayer({
  projector: 'content-background',
  muted: true,
  autoplay: true,
});

const loadMoment = (index: number) => {
  if (!props.moments.length) {
    return;
  }

  const moment = props.moments[index];

  if (!moment.hls) {
    throw new UnexpectedComponentStateError('moment.hls');
  }

  player.setConfigProperty('content.media', moment);
  player.load({ src: moment.hls, id: moment.id, autoplay: true });
};

const onPlayerEnded = () => {
  if (currentMomentIndex.value < props.moments.length - 1) {
    return loadMoment((currentMomentIndex.value += 1));
  }

  loadMoment((currentMomentIndex.value = 0));
};

player.on('mounted', () => loadMoment(0));
player.on('ended', onPlayerEnded);

const MOUNT_PLAYER_TIMEOUT = 5000;

onMounted(async () => {
  await timeout(MOUNT_PLAYER_TIMEOUT);

  if (!playerEl.value || !props.moments.length) {
    return;
  }

  player.mount(playerEl.value);
});

onBeforeUnmount(() => player.dispose());
</script>

<style module lang="scss">
.player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preview {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1, 1);
}
</style>
