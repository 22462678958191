export enum DisplayType {
  PromoBlock = 'promo_block',
  ContentMomentList = 'content_moments_list',
  MomentList = 'moments_list',
  DsmlRecommendations = 'dsml_recommendations',
  ContinueWatch = 'continue_watch',
  PlaylistsList = 'playlists_list',
  Default = 'default',
  GenresBelt = 'belt',
  OffersList = 'offers_list',
  TvChannelsList = 'dsml_tv_channel_recommendations',
}
