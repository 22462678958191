import { MediaContentType } from '@package/sdk/src/api';
import VideoPlayerEvent from '@PLAYER/player/base/event';
import { AmbientGradient } from '@PLAYER/player/modules/ambient/create-ambient-palette';

interface SerialMediaObject {
  season: number;
  episode: number;
}

interface KinomMediaObject {
  hls: string;
}

type MediaPlayRequestEventType = MediaContentType | 'kinom';

export interface MediaPlayRequestedEvent {
  id: string;
  title?: string;
  type: MediaPlayRequestEventType;
  serial?: SerialMediaObject;
  kinom?: KinomMediaObject;
  slug?: string;

  /**
   * @deprecated
   */
  startOffset?: number;
  autoplay?: boolean;
}

export interface MediaKinomCreatedEvent {
  startOffset: number;
  endOffset: number;
}

export interface MediaWatchingProgressEvent {
  offset: number;
  /**
   * Поле больше НЕ должно использоваться в рабочем коде, используйте новый сервис watching_items.
   *
   * @deprecated
   */
  isBeforeEndOfVideo: boolean;
}

export interface IntersectionEvent {
  intersect: boolean;
}

export type RedirectRequiredPage =
  | 'registration'
  | 'offers'
  | 'my-collection'
  | 'next-channel'
  | 'prev-channel'
  | 'end-of-content'
  | 'episodes-menu';

export interface RedirectRequiredEvent {
  page: RedirectRequiredPage;
}

export type IAmbientModeGradientUpdatedEvent = AmbientGradient;

export interface PictureInPictureEvent {
  active: boolean;
}

export interface FullscreenEvent {
  fullscreen: boolean;
  isPictureInPictureEnabled: boolean;
}

export interface CloseAllControlElements {
  shouldBeIgnored: boolean;
}

export interface RemoteControlTriggeredEvent {
  offset: number;
  paused: boolean;
}

export class VideoPlayerExternalEvent<T> extends VideoPlayerEvent<T> {
  constructor(data: T) {
    super(data);
  }
}
