import ConstantsConfig from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import { TimeMilliseconds, TimeSeconds } from '@PLAYER/player/base/number';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import { PlayerTimeUpdated, VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useAnalyticsStore from '@PLAYER/player/modules/store/analytics-store';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { millisecondsToSeconds } from 'date-fns';
import { storeToRefs } from 'pinia';

interface PlaybackManualRewindPayload {
  // diff между двумя timestamp"ами выше
  viewingTime: TimeMilliseconds;
  // Время начало просмотра в секундах (только для контента)
  startContentTime?: TimeSeconds;
  // Время паузы просмотра в секундах (только для контента)
  finishContentTime?: TimeSeconds;
  // время после перемотки
  timeAfterRewinding?: TimeSeconds;

  timeshiftOffset?: TimeSeconds;
}

export default function useStreamingAnalytics() {
  const eventBus = useSafeEventBus();
  const playbackAnalytics = usePlaybackAnalytics();
  const logger = useLogger('use-streaming-analytics', 'media-player');

  const { startViewingDate, finishViewingDate, startTimeContentStreaming, finishTimeContentStreaming } =
    storeToRefs(useAnalyticsStore());
  const { isLiveWithDVR, normalizedDisplayedCurrentTime, normalizedDuration } = useVideoPlayerVariables();

  const sendPlayerTimeUpdatedEvent = (payload: PlaybackManualRewindPayload) => {
    const { startContentTime, finishContentTime, viewingTime, timeAfterRewinding, timeshiftOffset } = payload;

    const event = new VideoPlayerInternalEvent<PlayerTimeUpdated>({
      startContentTime,
      finishContentTime,
      viewingTime,
      timeshiftOffset,
    });

    eventBus.emit('onPlayerTimeUpdated', event);

    if (timeAfterRewinding) {
      playbackAnalytics.setStartTimeStreaming(timeAfterRewinding);
    }
  };

  const sendAutoStreamingEvent = () => {
    playbackAnalytics.setFinishTimeStreaming(normalizedDisplayedCurrentTime.value);

    if (!startViewingDate.value) {
      return;
    }

    const startTimeSeconds = millisecondsToSeconds(startViewingDate.value);
    const finishTimeSeconds = millisecondsToSeconds(finishViewingDate.value);

    const viewingTime = finishTimeSeconds - startTimeSeconds;
    const timeshiftOffset = normalizedDuration.value - normalizedDisplayedCurrentTime.value;

    if (viewingTime < ConstantsConfig.getProperty('autoPlayerStreamingMinRangeSeconds')) {
      return logger.info(`Expect viewingTime >= 1, get ${viewingTime}`);
    }

    const payload: PlaybackManualRewindPayload = {
      startContentTime: startTimeContentStreaming.value,
      finishContentTime: finishTimeContentStreaming.value,
      viewingTime,
    };

    if (isLiveWithDVR.value) {
      payload.timeshiftOffset = timeshiftOffset;
    }

    sendPlayerTimeUpdatedEvent(payload);
  };

  return {
    sendAutoStreamingEvent,
    sendPlayerTimeUpdatedEvent,
  };
}
