export enum WebRouteQuery {
  SmartTvCode = 'smart_code',
  AccessToken = 'access_token',
  PaymentPayload = 'payment_payload',
}

export enum WebAppRoute {
  Payments = '/offers/payments/:signature/',
  LKDevices = '/account/devices/',
  SmartTvCode = 'code/',
}
