import { Episode, Season } from '@package/sdk/src/api';
import { isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { findRight } from '@PLAYER/player/base/dom';
import { NextAvailableEpisode } from '@PLAYER/player/modules/content/get-next-available-episode';
import useContentAvailability from '@PLAYER/player/modules/content/use-availability';

interface GetLastAvailableEpisodeOptions {
  seasons: Season[];
}

export function getLastAvailableEpisode(options: GetLastAvailableEpisodeOptions): NextAvailableEpisode {
  const { isAvailable } = useContentAvailability();
  const { seasons } = options;

  let lastAvailableEpisode: Episode | undefined;

  const lastAvailableSeason = findRight(seasons, (season) => {
    lastAvailableEpisode = findRight(season.episodes, isAvailable);

    return isDefined(lastAvailableEpisode);
  });

  if (!lastAvailableSeason || !lastAvailableEpisode) {
    throw new UnexpectedComponentStateError('lastAvailableSeason | lastAvailableEpisode');
  }

  return {
    season: lastAvailableSeason,
    episode: lastAvailableEpisode,
  };
}
