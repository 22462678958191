import useLogger from '@package/logger/src/use-logger';

import type { DSMLAnalyticApi } from './dsml';
import DSMLApi from './dsml';

const PROP_NAME = '$dsml';

declare global {
  interface Window {
    $dsml: DSMLAnalyticApi;
  }
}

const logger = useLogger('setupDsml', 'dsml-js');

export function setupDsml(): void {
  const dsml = new DSMLApi();

  Reflect.defineProperty(window, PROP_NAME, {
    configurable: false,
    writable: false,
    value: dsml,
  });
}

try {
  setupDsml();
} catch (error) {
  if (error instanceof Error) {
    logger.warn(error.message);
  } else {
    logger.error(error);
  }
}
