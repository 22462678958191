import useLayoutStore from '@PLAYER/player/modules/store/layout-store';

export default function useDebugActions() {
  const layoutStore = useLayoutStore();

  const requestDebugMenu = () => {
    layoutStore.setIsDebugVisible(true);
  };

  const releaseDebugMenu = () => {
    layoutStore.setIsDebugVisible(false);
  };

  return {
    requestDebugMenu,
    releaseDebugMenu,
  };
}
