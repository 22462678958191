import { getSmartTVInformation, isServiceWorkerModeActivated, isTizenOS, isWebOS } from '../smarttv/get-tv-information';

const isClient = typeof window !== 'undefined';

function getModule() {
  return {
    options: [],
    header: [
      isClient ? window.navigator.platform : '',
      isClient ? window.navigator.userAgent : '',
      isClient ? window.navigator.appVersion : '',
      isClient ? window.navigator.vendor : '',
    ],
    dataos: [
      { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
      { name: 'Windows', value: 'Win', version: 'NT' },
      { name: 'iPhone iOS', value: 'iPhone', version: 'OS' },
      { name: 'iPad iOS', value: 'iPad', version: 'OS' },
      { name: 'Kindle', value: 'Silk', version: 'Silk' },
      { name: 'Android', value: 'Android', version: 'Android' },
      { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
      { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
      { name: 'MacOS', value: 'Mac', version: 'OS X' },
      { name: 'Linux', value: 'Linux', version: 'rv' },
      { name: 'Palm', value: 'Palm', version: 'PalmOS' },
    ],
    databrowser: [
      { name: 'Google Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Mozilla Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Apple Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla Firefox', value: 'Mozilla', version: 'Mozilla' },
    ],
    init: function () {
      const agent = this.header.join(' ');
      const os = this.matchItem(agent, this.dataos);
      const browser = this.matchItem(agent, this.databrowser);

      return { os, browser };
    },
    matchItem: function (string: any, data: any) {
      let i = 0;
      let j = 0;
      let regex;
      let regexv;
      let match;
      let matches;
      let version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: 'unknown', version: 0 };
    },
  };
}

export function getDeviceName() {
  const data = getModule().init();

  return `${data.os.name} v.${data.os.version} / ${data.browser.name} v.${data.browser.version}`;
}

export function getWebDetailedDeviceName() {
  const data = getModule().init();

  return {
    osVersion: data.os.version,
    osName: data.os.name,
    browserVersion: data.browser.version,
    browserName: data.browser.name,
  };
}

interface DetailDeviceInfo {
  osName: string;
  osVersion: string;
  browserName: string;
  browserVersion: string;
  vendorName: string;
  modelName: string;
  modelCode: string;
}

export async function getDetailedDeviceInfo(): Promise<DetailDeviceInfo> {
  const { os, browser } = getModule().init();

  const isServiceWorker = isServiceWorkerModeActivated();

  const isSmartTV = (isTizenOS || isWebOS) && !isServiceWorker;

  const { name: browserName, version: browserVersion } = browser;

  const { version: browserOsVersion, name: browserOsName } = os;

  const {
    osName: smartTvOsName,
    modelName: smartTvModelName,
    vendorName: smartTvVendorName,
    modelCode: smartTvModelCode,
  } = await getSmartTVInformation();

  const osName = (() => {
    if (isSmartTV) {
      return smartTvOsName;
    }

    return browserOsName;
  })();

  const osVersion = (() => {
    return String(browserOsVersion);
  })();

  const normalizedBrowserName = (() => {
    return browserName;
  })();

  const normalizedBrowserVersion = (() => {
    return String(browserVersion);
  })();

  return {
    vendorName: smartTvVendorName,
    modelCode: smartTvModelCode,
    modelName: smartTvModelName,
    osName,
    osVersion,
    browserName: normalizedBrowserName,
    browserVersion: normalizedBrowserVersion,
  };
}
