import { RemoteControlTriggeredEvent, VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';

export default function useRemoteControlEvent() {
  const externalEventBus = useSafeExternalEventBus();

  const triggerRemoteControlEvent = (event: RemoteControlTriggeredEvent) => {
    externalEventBus.emit(
      'remote-control-event-triggered',
      new VideoPlayerExternalEvent<RemoteControlTriggeredEvent>(event),
    );
  };

  return {
    triggerRemoteControlEvent,
  };
}
