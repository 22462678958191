<template>
  <div
    ref="timelineEl"
    :aria-label="$t('controls.timeline.ariaLabel')"
    :class="{
      [$style.timeline]: true,
      [$style.timelineWeb]: isWeb,
      [$style.timelineWebKinom]: isKinom,
    }"
  >
    <timeline-progress
      :current-time="currentTime"
      :class="$style.progress"
      :chapters="timelineChapters"
      :pixel-factor="pixelFactor"
      :current-handle-position="currentHandlePosition"
    />
    <timeline-buffer-progress
      :class="$style.progress"
      :duration="duration"
      :current-time="currentTime"
      :buffer-length-seconds="bufferLength"
      :buffer-start-seconds="bufferStart"
    />
    <timeline-linked-kinom-playback-mark
      v-if="isTimelinePlaybackButtonsShown && isLinkedKinomShown"
      :duration="duration"
      :kinom-duration="linkedKinomDuration as number"
      :start-offset="linkedKinomStartOffset as number"
    />
    <timeline-bar
      ref="timelineBarComp"
      :pixel-factor="pixelFactor"
      :chapters="timelineChapters"
      :is-timeline-hover-handle-shown="isTimelineHoverHandleShown"
      :is-mouse-over="!isOutside"
      :current-handle-hover-position="currentHandleHoverPosition"
      :current-handle-position="currentHandlePosition"
      :current-time="currentTime"
      :duration="duration"
    />
    <template v-if="isShownTimelinePreview">
      <div v-show="isPreviewVisible">
        <timeline-frame-preview
          v-show="isHoverTimeDisplayLabelShown"
          ref="timelineFramePreviewEl"
          :timeline-mouse-x="timelineMouseX as number"
          :timeline-left="timelineLeft as number"
          :timeline-width="timelineWidth as number"
          :pixel-factor="pixelFactor"
          :is-live="isLive"
          :duration="duration"
        />
        <timeline-display-label
          v-show="lastTimelineWidth && isCurrentTimeDisplayLabelShown"
          :display-time="currentDisplayedTime"
          :is-focused="timelineBarComp.isHandleFocused"
          :left-position="currentTimeDisplayLabelPosition"
        />
      </div>
      <timeline-progress-bar-move-detector ref="timelineDetector" :timeline-el="timelineEl as HTMLElement" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { formatTime } from '@PLAYER/player/base/date';
import { isDesktop } from '@PLAYER/player/base/dom';
import { TimeSeconds } from '@PLAYER/player/base/number';
import TimelineBar from '@PLAYER/player/components/timeline/TimelineBar.vue';
import TimelineBufferProgress from '@PLAYER/player/components/timeline/TimelineBufferProgress.vue';
import TimelineDisplayLabel from '@PLAYER/player/components/timeline/TimelineDisplayLabel.vue';
import TimelineFramePreview from '@PLAYER/player/components/timeline/TimelineFramePreview.vue';
import TimelineLinkedKinomPlaybackMark from '@PLAYER/player/components/timeline/TimelineLinkedKinomPlaybackMark.vue';
import TimelineProgress from '@PLAYER/player/components/timeline/TimelineProgress.vue';
import TimelineProgressBarMoveDetector from '@PLAYER/player/components/timeline/TimelineProgressbarMoveDetector.vue';
import useContentLinkedKinom from '@PLAYER/player/modules/content/use-content-linked-kinom';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useTimelineChapters from '@PLAYER/player/modules/timeline/use-timeline-chapters';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { useElementSize, useMouseInElement, watchDebounced } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { ComponentPublicInstance, computed, Ref, ref, watch, watchEffect } from 'vue';

const props = defineProps<{
  duration: TimeSeconds;
  currentTime: TimeSeconds;
  currentHandlePosition: number;
  bufferLength: TimeSeconds;
  bufferStart: TimeSeconds;
  isTimelinePlaybackButtonsShown: boolean;
  isTimelineHoverHandleShown: boolean;
}>();

type ExposedTimelineDetectorComponent = ComponentPublicInstance<{ isPreviewVisible: Ref<boolean> }>;
const timelineDetector = ref<ExposedTimelineDetectorComponent>();

type ExposedTimelineBarComponent = ComponentPublicInstance<{ isHandleFocused: Ref<boolean> }>;
const timelineBarComp = ref<ExposedTimelineBarComponent>();

const { isKinom, isLive, isVOD } = useProjector();
const { isWeb, isSmartTV } = usePlatform();
const { timelineChapters } = useTimelineChapters();
const { linkedKinomDuration, linkedKinomStartOffset } = useContentLinkedKinom();
const { isRewindShown } = useVideoControlsVariables();
const { isLiveWithDVR } = useVideoPlayerVariables();

const SAFE_OFFSET_LABEL = 100;

const layoutStore = useLayoutStore();
const videoControlsStore = useVideoControlsStore();
const { isLinkedKinomShown, isNextContentEpisodeShown } = storeToRefs(layoutStore);
const { isFullScreenEnabled } = storeToRefs(useVideoUIStore());
const { isRewindTipNotificationShown } = storeToRefs(videoControlsStore);

const isHoverTimeDisplayLabelShown = computed(() => {
  return isWeb;
});

const isPreviewVisible = computed(() => {
  if (!timelineDetector.value) {
    return false;
  }

  if (isSmartTV) {
    return true;
  }

  return timelineDetector.value?.isPreviewVisible || isRewindShown.value || isRewindTipNotificationShown.value;
});

const timelineEl = ref<HTMLElement>();

const {
  x: timelineMouseX,
  isOutside,
  elementPositionX: timelineLeft,
} = useMouseInElement(timelineEl, { handleOutside: false });

const { width: timelineWidth } = useElementSize(timelineEl);

const timelineFramePreviewEl = ref<ComponentPublicInstance<{ safeMouseXFraction: number }>>();
const lastTimelineWidth = ref(0);

const isShownCurrentTimeDisplayLabel = ref(true);
const isCurrentTimeDisplayLabelShown = computed(() => {
  if (isSmartTV) {
    if (isNextContentEpisodeShown.value) {
      return false;
    }

    return !isLinkedKinomShown.value;
  }

  const isPlayerWithRewind = isVOD.value || isLiveWithDVR.value;
  const isRewindActive = isRewindShown.value || isRewindTipNotificationShown.value;

  return (isShownCurrentTimeDisplayLabel.value && isPlayerWithRewind) || isRewindActive;
});
const isShownTimelinePreview = computed(() => {
  if (!timelineEl.value) {
    return false;
  }

  if (isWeb) {
    return (isDesktop && isLiveWithDVR.value) || isVOD.value;
  }

  return isSmartTV;
});

const currentDisplayedTime = computed(() => {
  if (isLive.value) {
    return formatTime(props.currentTime - props.duration);
  }

  return formatTime(props.currentTime);
});

const SMARTTV_PLAYER_OFFSET_PX = 30;
const TIMELINE_LABEL_WIDTH = 55;

const currentTimeDisplayLabelPosition = computed(() => {
  const targetPosition = props.currentHandlePosition * lastTimelineWidth.value;
  const normalizedTimelineWidth = lastTimelineWidth.value - 65;

  let normalizedTargetPosition = targetPosition - TIMELINE_LABEL_WIDTH;

  if (normalizedTargetPosition < 0) {
    return 0;
  }

  if (isSmartTV) {
    if (targetPosition >= normalizedTimelineWidth) {
      normalizedTargetPosition = normalizedTimelineWidth - SMARTTV_PLAYER_OFFSET_PX;
    }

    return normalizedTargetPosition;
  }

  return normalizedTargetPosition + TIMELINE_LABEL_WIDTH / 2; // делим на два, чтобы найти pivot
});

const mouseXFraction = computed(() => timelineFramePreviewEl.value?.safeMouseXFraction || 0);

// отнимаем отступы, чтобы курсор был по центру ползунка таймлайна
// на странице плеера отнимаем 21px, т.к на ней нет паддинга 16px, на всех остальных 35px
const hoverPositionShift = computed(() =>
  isVOD.value && isWeb
    ? ConstantsConfigPlayer.getProperty('hoverPositionShiftForPlayerPagePx')
    : ConstantsConfigPlayer.getProperty('hoverPositionShiftPx'),
);

const currentHandleHoverPosition = computed(() => {
  const timelineMouse = timelineMouseX.value - hoverPositionShift.value;

  if (isFullScreenEnabled.value) {
    return (
      (timelineMouse + ConstantsConfigPlayer.getProperty('hoverPositionShiftInFullscreenPx')) / lastTimelineWidth.value
    );
  }

  return timelineMouse / lastTimelineWidth.value;
});

const pixelFactor = computed(() => {
  const roundPixelFactor = (props.duration / lastTimelineWidth.value).toFixed(2);

  return Number(roundPixelFactor);
});

watchEffect(
  () => {
    if (!timelineFramePreviewEl.value) {
      return;
    }

    const diff = mouseXFraction.value - currentTimeDisplayLabelPosition.value;

    if (Number.isNaN(diff)) {
      return;
    }

    if (diff < 0) {
      isShownCurrentTimeDisplayLabel.value = Math.abs(diff) > SAFE_OFFSET_LABEL / 2;
    } else {
      isShownCurrentTimeDisplayLabel.value = Math.abs(diff) > SAFE_OFFSET_LABEL;
    }
  },
  { flush: 'post' },
);

watch(
  timelineWidth,
  (width) => {
    if (!width) {
      return;
    }

    videoControlsStore.setTimelineWidth(width);
    lastTimelineWidth.value = width;
  },
  { immediate: true },
);

watch(pixelFactor, (val) => videoControlsStore.setTimelinePixelFactor(val), { immediate: true });

watchDebounced(
  isOutside,
  () => {
    if (!isOutside.value) {
      layoutStore.setIsNextEpisodeElementHovered(false);
    }
  },
  { debounce: 500 },
);
</script>

<style module lang="scss">
.timeline {
  position: relative;
  width: calc(100% - var(--vod-playback-time-view-width));
  cursor: pointer;
  touch-action: none;
}

.timelineWeb {
  padding-top: var(--g-spacing-20);
  padding-bottom: var(--g-spacing-10);
}

.timelineWebKinom {
  position: absolute;
  margin: 0;
  padding-bottom: 0;
  width: 100%;
}

.progress {
  height: 4px;
  user-select: none;
}
</style>
