import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import useLocalStorage from '@package/sdk/src/core/dom/use-local-storage';
import { isMobile } from '@PLAYER/player/base/dom';
import { LocalStorageKey } from '@PLAYER/player/base/local-storage';
import { TimeSeconds } from '@PLAYER/player/base/number';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import useStreamingAnalytics from '@PLAYER/player/modules/analytics/use-streaming-analytics';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useAnalyticsStore from '@PLAYER/player/modules/store/analytics-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoControlsActions from '@PLAYER/player/modules/video/use-video-controls-actions';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { millisecondsToSeconds } from 'date-fns';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount } from 'vue';

export default function usePlaybackRewindActions() {
  const videoControlsStore = useVideoControlsStore();
  const videoInteractions = useVideoInteractions();
  const logger = useLogger('use-playback-rewind-actions', 'media-player');
  const playbackAnalytics = usePlaybackAnalytics();
  const { isLiveWithDVR, normalizedDuration, normalizedDisplayedCurrentTime } = useVideoPlayerVariables();
  const { isWeb } = usePlatform();
  const streamingAnalytics = useStreamingAnalytics();
  const localStorage = useLocalStorage();
  const eventBus = useSafeEventBus();
  const videoControlsActions = useVideoControlsActions();

  const { isRewindTipNotificationShown, activeMobileBackgroundControl, autoRepeatMultiplier } =
    storeToRefs(videoControlsStore);
  const { displayedCurrentTime, isPlaying } = storeToRefs(useVideoUIStore());
  const { startTimeContentStreaming, startViewingDate, finishViewingDate } = storeToRefs(useAnalyticsStore());

  let playbackRewindTimeoutId: number;
  let rewindNotificationTimeoutId: number;
  let mobileBackgroundControlsTimeoutId: number;

  const getViewingTime = () => {
    const startTimeSeconds = millisecondsToSeconds(startViewingDate.value);
    const finishTimeSeconds = millisecondsToSeconds(finishViewingDate.value);

    return finishTimeSeconds - startTimeSeconds;
  };

  const getTimeShiftOffset = () => {
    if (isLiveWithDVR.value) {
      return normalizedDuration.value - normalizedDisplayedCurrentTime.value;
    }

    return undefined;
  };

  const sendAnalyticRewind = (timeAfterRewinding?: TimeSeconds) => {
    if (!isPlaying.value) {
      return;
    }

    const viewingTime = getViewingTime();

    if (viewingTime < ConstantsConfigPlayer.getProperty('autoPlayerStreamingMinRangeSeconds')) {
      logger.info(`Expect viewingTime >= 1, get ${viewingTime}`);
    } else {
      streamingAnalytics.sendPlayerTimeUpdatedEvent({
        startContentTime: startTimeContentStreaming.value,
        finishContentTime: normalizedDisplayedCurrentTime.value,
        viewingTime,
        timeAfterRewinding,
        timeshiftOffset: getTimeShiftOffset(),
      });
    }
  };

  const doRewind = (timeAfterRewinding: TimeSeconds, shouldPauseVideo = true) => {
    sendAnalyticRewind(timeAfterRewinding);

    if (shouldPauseVideo) {
      videoInteractions.pause();
    }

    if (playbackRewindTimeoutId) {
      window.clearTimeout(playbackRewindTimeoutId);
    }

    playbackRewindTimeoutId = window.setTimeout(() => {
      videoControlsStore.setCurrentAction(undefined);
      videoControlsStore.setIsRewindTriggeredByUserControls(false);
    }, ConstantsConfigPlayer.getProperty('rewindPlayTimeoutMs'));

    videoInteractions.changeCurrentTime({
      seconds: timeAfterRewinding,
      manually: true,
    });
  };

  const goToLive = () => {
    playbackAnalytics.setFinishTimeStreaming(normalizedDisplayedCurrentTime.value);

    sendAnalyticRewind(undefined);

    videoInteractions.goToLive();
  };

  const rewindTimeForwardExact = (time: TimeSeconds, showAction = true) => {
    playbackAnalytics.onPlayForwardAnalytics();
    playbackAnalytics.setFinishTimeStreaming(time);

    doRewind(time, false);

    if (showAction) {
      videoControlsStore.setCurrentAction({ type: 'skip-forward' });
    }
  };

  const rewindTimeBackwardExact = (time: TimeSeconds, showAction = true) => {
    playbackAnalytics.onPlayBackwardAnalytics();
    playbackAnalytics.setFinishTimeStreaming(time);

    doRewind(time, false);

    if (showAction) {
      videoControlsStore.setCurrentAction({ type: 'skip-forward' });
    }
  };

  const getRewindTime = () => {
    // TODO:
    // const isLongDurationContent = normalizedDuration.value >= 1200;

    const isLongDurationContent = true;

    if (isWeb) {
      return isLongDurationContent
        ? ConstantsConfigPlayer.getProperty('rewindTimeSecondsWebMax')
        : ConstantsConfigPlayer.getProperty('rewindTimeSecondsWebMin');
    }

    return isLongDurationContent
      ? ConstantsConfigPlayer.getProperty('rewindTimeSecondsSmartTvMax')
      : ConstantsConfigPlayer.getProperty('rewindTimeSecondsSmartTvMin');
  };

  const rewindTimeForward = (autoRepeatMultiplier = 1, showAction = true) => {
    playbackAnalytics.onPlayForwardAnalytics();
    playbackAnalytics.setFinishTimeStreaming(normalizedDisplayedCurrentTime.value);

    const rewindTime = getRewindTime();
    const timeAfterRewinding = displayedCurrentTime.value + rewindTime * autoRepeatMultiplier;

    const shouldPauseVideo = !isPlaying.value;
    doRewind(timeAfterRewinding, shouldPauseVideo);

    if (showAction) {
      videoControlsStore.setCurrentAction({ type: 'skip-forward' });
    }
  };

  const rewindTimeBackward = (autoRepeatMultiplier = 1, showAction = true) => {
    playbackAnalytics.onPlayBackwardAnalytics();
    playbackAnalytics.setFinishTimeStreaming(normalizedDisplayedCurrentTime.value);

    const rewindTime = getRewindTime();
    const timeAfterRewinding = displayedCurrentTime.value - rewindTime * autoRepeatMultiplier;

    const shouldPauseVideo = !isPlaying.value;
    doRewind(timeAfterRewinding, shouldPauseVideo);

    if (showAction) {
      videoControlsStore.setCurrentAction({ type: 'skip-backward' });
    }
  };

  const showRewindOnboardingNotification = () => {
    if (!isMobile || isRewindTipNotificationShown.value) {
      return;
    }

    const timesShown = localStorage.getValue(LocalStorageKey.RewindOnboardingTimesShown, 0)?.value as number;

    if (!timesShown) {
      localStorage.setValue(LocalStorageKey.RewindOnboardingTimesShown, { value: 0 });
    }

    if (timesShown >= ConstantsConfigPlayer.getProperty('rewindOnboardingTimesShown')) {
      return;
    }

    localStorage.setValue(LocalStorageKey.RewindOnboardingTimesShown, { value: timesShown + 1 });

    videoControlsStore.setIsRewindTipNotificationShown(true);

    if (rewindNotificationTimeoutId) {
      window.clearTimeout(rewindNotificationTimeoutId);
    }

    rewindNotificationTimeoutId = window.setTimeout(() => {
      videoControlsStore.setIsRewindTipNotificationShown(false);
    }, ConstantsConfigPlayer.getProperty('rewindOnboardingTimeoutMs'));
  };

  const hideBackgroundControlsTimeout = () => {
    if (activeMobileBackgroundControl.value === 'left') {
      rewindTimeBackward();
    }

    if (activeMobileBackgroundControl.value === 'right') {
      rewindTimeForward();
    }

    if (mobileBackgroundControlsTimeoutId) {
      window.clearTimeout(mobileBackgroundControlsTimeoutId);
    }

    mobileBackgroundControlsTimeoutId = window.setTimeout(() => {
      videoControlsStore.setActiveMobileBackgroundControl(undefined);
      videoControlsStore.setIsMobileBackgroundControlsVisible(false);
      videoControlsActions.requestVideoControls();
      videoControlsStore.setAutoRepeatMultiplier(0);
      videoControlsStore.setIsIgnoreDoubleTapOnButtonTouch(false);
      videoControlsStore.setCurrentSkipTime(10);
    }, ConstantsConfigPlayer.getProperty('doubleTapPlaybackTimeoutMs'));
  };

  const doActiveMobileBackgroundAction = (direction: 'left' | 'right') => {
    if (activeMobileBackgroundControl?.value !== direction) {
      videoControlsStore.setAutoRepeatMultiplier(1);
    } else {
      videoControlsStore.setAutoRepeatMultiplier((autoRepeatMultiplier.value += 1));
    }

    if (autoRepeatMultiplier.value === 10) {
      showRewindOnboardingNotification();
    }

    videoControlsStore.setCurrentSkipTime(autoRepeatMultiplier.value * 10);
    videoControlsStore.setIsRewindTriggeredByUserControls(true);
    videoControlsActions.requestVideoControls();
    videoControlsStore.setActiveMobileBackgroundControl(direction);
    videoControlsStore.setIsMobileBackgroundControlsVisible(true);
    hideBackgroundControlsTimeout();
  };

  onBeforeUnmount(() => {
    if (playbackRewindTimeoutId) {
      window.clearTimeout(playbackRewindTimeoutId);
    }
  });

  return {
    goToLive,
    rewindTimeBackward,
    rewindTimeForward,
    rewindTimeBackwardExact,
    rewindTimeForwardExact,
    showRewindOnboardingNotification,
    doActiveMobileBackgroundAction,
  };
}
