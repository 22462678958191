<template>
  <input
    :class="{
      [$style.input]: true,
      [$style.inputBorderless]: Boolean(borderless),
    }"
    :placeholder="placeholder"
    :disabled="disabled"
    :type="type"
    :value="text"
  />
</template>

<script lang="ts" setup>
// eslint-disable-next-line vue/no-dupe-keys
import { inputMask } from '@SMART/index';
import { computed } from 'vue';

interface Props {
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  input?: string;
  borderless?: boolean;
  inputMask?: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
});

const emit = defineEmits(['update:input']);

const text = computed({
  get: () => (props.inputMask ? inputMask(props.inputMask, props.input) : props.input),
  set: (value) => emit('update:input', value),
});
</script>

<style module lang="scss">
@import '../../styles/mixins';
@import '@/styles/colors';

.input {
  box-sizing: border-box;
  padding: 0 adjustPx(24px);
  min-height: adjustPx(83px);
  border-radius: adjustPx(10px);
  border: none;
  background-color: var(--color-states-bg-secondary-disabled);
  color: var(--color-text-primary);
  outline: none;

  @include f-body;

  &Borderless {
    padding: 0;
    border-radius: 0;
    border: 1px solid var(--color-states-bg-secondary-disabled);
    background-color: transparent;
    color: var(--color-notheme-white-50);
    border-width: 0 0 1px 0;
  }
}
input::placeholder {
  color: var(--color-notheme-white-50);
}
</style>
