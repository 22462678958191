import type { CookieKey } from '../platform/cookie';

export function getLocalStorageItem(name: CookieKey, defaultValue: any) {
  try {
    const result = JSON.parse(localStorage.getItem(name) || defaultValue);

    return result;
  } catch (err) {
    console.error(err);
    return defaultValue;
  }
}

export function setLocalStorageItem(name: CookieKey, value: unknown) {
  try {
    localStorage.setItem(name, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
}
