<template>
  <article ref="el" :class="$style.movieDetails">
    <section :class="$style.movieInfo">
      <div :class="$style.movieText">
        <h1 :class="$style.movieTitle">{{ $t(`pages.mediaCard.about-${content.contentType}`) }}</h1>
        <p :class="$style.movieDescription">
          {{ content.description }}
        </p>
      </div>
      <div v-for="(items, index) in infoItems" :key="index" :class="$style.movieDetailsGrid">
        <div :class="$style.infoItem">
          <MediaCardInfoItem v-for="item in items" :key="item.title" :title="item.title" :value="item.value" />
        </div>
      </div>
    </section>
    <aside :class="$style.movieMetadata">
      <MediaCardMetadataSection
        v-if="content.genres.length"
        filter-type="genre"
        :title="$t('pages.mediaCard.genres')"
        :tags="content.genres"
        :startIndex="0"
        @activated="handleActivated"
        @selected="(options) => emit('selected', options)"
      />
      <MediaCardMetadataSection
        v-if="content.countries.length"
        :class="$style.countries"
        filter-type="country"
        :title="$t('pages.mediaCard.countries')"
        :tags="content.countries"
        :startIndex="content.genres.length"
        @activated="handleActivated"
        @selected="(options) => emit('selected', options)"
      />
    </aside>
  </article>
</template>

<script lang="ts" setup>
import { Media, MediaContentType, Serial } from '@package/sdk/src/api';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { FocusKeys, formatDuration, pluralize, translate } from '@SMART/index';
import { computed, provide } from 'vue';

import MediaCardInfoItem from './MediaCardInfoItem.vue';
import MediaCardMetadataSection from './MediaCardMetadataSection.vue';

interface Props {
  content: Media;
}
const { content } = defineProps<Props>();
const emit = defineEmits<{
  (event: 'activated', offset: number, index: number): void;
  (event: 'selected', options: { genre?: string; country?: string }): void;
}>();

const { el, focusKey } = useNavigatable({
  focusKey: FocusKeys.MEDIA_CARD_DETAILS,
  saveLastFocusedChild: true,
  isFocusBoundary: true,
  hasGlobalAccess: true,
  focusBoundaryDirections: ['right'],
});
provide('parentFocusKey', focusKey.value);

const duration = computed(() => formatDuration(content?.duration, 'hhmm'));
const director = computed(() => content?.directors?.[0]?.name);
const actors = computed(() =>
  content?.actors
    ?.slice(0, 3)
    ?.map((x) => x.name)
    .join(', '),
);

const handleActivated = (offsetTop: number, index: number) => {
  emit('activated', el.value?.offsetTop || offsetTop, index);
};

const infoItems = computed(() => {
  const items: { title: string; value: string }[] = [];

  const title = content.titleEn || content.title;
  if (title) {
    items.push({ title: translate('pages.mediaCard.originalTitle'), value: title });
  }
  if (duration.value) {
    const isSerial = content.contentType === MediaContentType.SERIAL;
    const seasonNumber = (content as Serial).seasons?.length ?? 1;
    const value = isSerial
      ? `${seasonNumber} ${pluralize(seasonNumber, 'pluralizedUnit.season').toLowerCase()}`
      : duration.value;

    items.push({ title: translate('pages.mediaCard.duration'), value });
  }
  if (content.year) {
    items.push({ title: translate('pages.mediaCard.productionYear'), value: String(content.year) });
  }
  if (director.value) {
    items.push({ title: translate('pages.mediaCard.director'), value: director.value });
  }
  if (actors.value) {
    items.push({ title: translate('pages.mediaCard.actors'), value: actors.value });
  }
  const sliced = [items.slice(0, 2), items.slice(2, 4), items.slice(4)];
  return sliced;
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@package/ui/src/styles/smarttv-fonts';
@import '@/styles/mixins';

.movieDetails {
  display: flex;
  align-items: flex-start;
  padding-right: adjust.adjustPx(60px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.movieInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  margin-right: adjust.adjustPx(56px);
  width: adjust.adjustPx(1011px);
  min-width: adjust.adjustPx(240px);
}

.movieText {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--color-text-primary);
  margin-bottom: adjust.adjustPx(40px);
}

.movieTitle {
  font-weight: 500;
  font-size: adjust.adjustPx(48px);
  line-height: 1;
  letter-spacing: adjust.adjustPx(-0.48px);
}

.movieDescription {
  margin-top: adjust.adjustPx(24px);

  @include smartTvFonts.SmartTvBody-2();
}

.movieDetailsGrid {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-weight: 400;
  font-size: adjust.adjustPx(32px);
  flex-wrap: wrap;
}

.infoItem {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 0;
  margin-right: adjust.adjustPx(24px);
  min-width: adjust.adjustPx(240px);
}

.movieMetadata {
  display: flex;
  width: adjust.adjustPx(403px);
  font-weight: 500;
  color: var(--color-text-primary);
  flex-direction: column;
  flex-grow: 1;
  min-width: adjust.adjustPx(240px);
}

.genres {
  margin-bottom: adjust.adjustPx(60px);
}
</style>
