<template>
  <div ref="el" data-smart-tv-control>
    <smart-tv-video-control-button
      :on-click="requestNextEpisode"
      :class="$style.nextEpisode"
      :is-label-shown="true"
      aria-haspopup="true"
      :text="$t('controls.content.next')"
      :focus-key="PlayerFocusKeys.PLAYER_NEXT_EPISODE_BUTTON"
      @focus="openTooltip"
      @blur="onBlur"
    >
      <icon-next-episode size="medium" />
      <span>{{ $t('controls.content.next') }}</span>
    </smart-tv-video-control-button>
    <smart-tv-next-episode-controls-preview
      v-if="isNextEpisodePreviewShown"
      :episode="nextAvailableEpisode.episode"
      :season="currentSelectedSeason"
      :on-request-episode="() => {}"
      @active="onActiveTooltip"
      @blur="onBlur"
    />
  </div>
</template>

<script lang="ts" setup>
import IconNextEpisode from '@PLAYER/player/components/icons/IconNextEpisode.vue';
import useNextSerialEpisode from '@PLAYER/player/modules/content/use-next-serial-episode';
import useEpisodesNavigationActions from '@PLAYER/player/modules/hooks/use-episodes-navigation-actions';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useTooltipState from '@PLAYER/player/modules/timeline/use-tooltip-state';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import SmartTvNextEpisodeControlsPreview from '@PLAYER/player/versions/smart/components/ui/SmartTvNextEpisodeControlsPreview.vue';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, provide, ref } from 'vue';

import useSmartNavigatable from '../../modules/smart-navigation/use-smarttv-navigatable';

const { isTooltipShown, openTooltip, closeTooltip } = useTooltipState();
const { requestNextAvailableEpisode, nextAvailableEpisode } = useNextSerialEpisode();
const { isLinkedKinomShown } = storeToRefs(useLayoutStore());
const { timelineBarHandleEl } = storeToRefs(useVideoControlsStore());
const { currentSelectedSeason } = useEpisodesNavigationActions();
const { isSmartTV } = usePlatform();

const { useNavigatable, PlayerFocusKeys, SpatialNavigation } = useSmartNavigatable();
const { el, focusKey } = useNavigatable({
  focusKey: PlayerFocusKeys.PLAYER_NEXT_EPISODE,
  isFocusBoundary: true,
  focusBoundaryDirections: ['up'],
});
provide('parentFocusKey', focusKey?.value);

const isHideForced = ref(false);

let isHideForcedTimeoutId: number;

const isNextEpisodePreviewShown = computed(
  () => isTooltipShown.value && nextAvailableEpisode.value && !isLinkedKinomShown.value && !isHideForced.value,
);

const requestNextEpisode = () => {
  isHideForced.value = true;

  isHideForcedTimeoutId = window.setTimeout(() => {
    isHideForced.value = false;
  }, 2000);

  requestNextAvailableEpisode();

  if (timelineBarHandleEl.value) {
    window.$setActiveNavigationItem(timelineBarHandleEl.value);
  }
};

const onBlur = () => {
  if (!isSmartTV) {
    return closeTooltip();
  }

  window.setTimeout(() => {
    if (
      [PlayerFocusKeys.PLAYER_NEXT_EPISODE_BUTTON, PlayerFocusKeys.PLAYER_NEXT_EPISODE_PLAY].includes(
        SpatialNavigation?.getCurrentFocusKey() || '',
      )
    ) {
      return;
    }

    closeTooltip();
  }, 200);
};

onBeforeUnmount(() => {
  if (isHideForcedTimeoutId) {
    window.clearTimeout(isHideForcedTimeoutId);
  }
});
</script>

<style lang="scss" module>
.nextEpisode {
  display: flex;
  align-items: center;
}
</style>
