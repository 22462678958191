import { Onboardings, onboardingService } from '@SMART/index';
import { ref } from 'vue';

const ONBOARDING_TIMEOUT = 10_000;

export const useNotifications = () => {
  const isOnboardingShown = ref(true);
  const isOnboardingLikeShown = ref(true);
  const isOnboardingDislikeShown = ref(true);
  const isOnboardingSaveShown = ref(true);
  const isAuthError = ref(false);

  const closeAllNotifications = () => {
    isOnboardingShown.value = true;
    isOnboardingLikeShown.value = true;
    isOnboardingDislikeShown.value = true;
    isOnboardingSaveShown.value = true;
    isAuthError.value = false;
  };

  const setOnboardingPage = () => {
    isOnboardingShown.value = onboardingService.isOnboardingFinished(Onboardings.MomentsFeed);
    if (!isOnboardingShown.value) {
      onboardingService.finishOnboarding(Onboardings.MomentsFeed);
      window.setTimeout(() => {
        isOnboardingShown.value = true;
      }, ONBOARDING_TIMEOUT);
    }
  };

  const setOnboardingLike = () => {
    closeAllNotifications();
    isOnboardingLikeShown.value = onboardingService.isOnboardingFinished(Onboardings.MomentsLike);
    onboardingService.finishOnboarding(Onboardings.MomentsLike);
    window.setTimeout(() => {
      isOnboardingLikeShown.value = true;
    }, ONBOARDING_TIMEOUT);
  };

  const setOnboardingDislike = () => {
    closeAllNotifications();
    isOnboardingDislikeShown.value = onboardingService.isOnboardingFinished(Onboardings.MomentsDislike);
    onboardingService.finishOnboarding(Onboardings.MomentsDislike);
    window.setTimeout(() => {
      isOnboardingDislikeShown.value = true;
    }, ONBOARDING_TIMEOUT);
  };

  const setOnboardingSave = () => {
    closeAllNotifications();
    isOnboardingSaveShown.value = onboardingService.isOnboardingFinished(Onboardings.MomentsSave);
    onboardingService.finishOnboarding(Onboardings.MomentsSave);
    window.setTimeout(() => {
      isOnboardingSaveShown.value = true;
    }, ONBOARDING_TIMEOUT);
  };

  return {
    setOnboardingPage,
    setOnboardingLike,
    setOnboardingDislike,
    setOnboardingSave,
    isOnboardingShown,
    isOnboardingLikeShown,
    isOnboardingDislikeShown,
    isOnboardingSaveShown,
    isAuthError,
    closeAllNotifications,
  };
};
