import { isDefined } from '@package/sdk/src/core';
import { isIOS, isTizenOS, isWebOS, isWebOSSimulator } from '@PLAYER/player/base/dom';
import { M3U8Parser } from '@PLAYER/player/modules/hls/m3u8-parser';
import useRealNativeHlsPlaybackSupport from '@PLAYER/player/modules/hooks/use-real-native-playback-support';

export type UseDetectMediaTechSupportResult = 'html5' | 'hls.js' | 'dash.js' | 'avplayer' | 'tataudio' | 'shaka';

interface UseDetectMediaTechSupport {
  forceAVPlayer?: boolean;
  forceTataudio?: boolean;
  forceHlsJs?: boolean;
  forceShakaPlayer?: boolean;
}

const DEFAULT_OPTIONS: UseDetectMediaTechSupport = {
  forceAVPlayer: false,
  forceShakaPlayer: false,
  forceHlsJs: false,
  forceTataudio: false,
};

export default function useDetectMediaTechSupport(
  url?: string,
  options: UseDetectMediaTechSupport = DEFAULT_OPTIONS,
): UseDetectMediaTechSupportResult {
  /**
   * Если ссылка - не m3u8, то это значит что это ТОЧНО не HLS плейлист. Пытаемся проиграть нативно.
   */

  const hasRealSupport = useRealNativeHlsPlaybackSupport();

  const { forceAVPlayer, forceTataudio, forceHlsJs, forceShakaPlayer } = options;

  if (hasRealSupport && isIOS) {
    return 'html5';
  }

  if (forceShakaPlayer) {
    return 'shaka';
  }

  // форсим hls.js
  if (forceHlsJs) {
    return 'hls.js';
  }

  // форсим tataudio
  if (forceTataudio) {
    return 'tataudio';
  }

  // форсим avplayer
  if (forceAVPlayer) {
    return 'avplayer';
  }

  // Если это dash (запускаем dash.js)
  if (isDefined(url) && url.includes('.mpd')) {
    return 'dash.js';
  }

  // Всякие там mp4.
  if (isDefined(url) && !M3U8Parser.isM3U8ManifestUrl(url)) {
    return 'html5';
  }
  /**
   * Для WebOS нет поддержки нативного HLS, делаем фоллбек на обычный
   */
  if (isWebOSSimulator) {
    return 'hls.js';
  }

  // Для теликов только если есть реальная поддержка.
  if (hasRealSupport && isTizenOS) {
    return 'html5';
  }

  if (hasRealSupport && isWebOS) {
    return 'html5';
  }

  return 'hls.js';
}
