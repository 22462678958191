import { isUndefined } from '@package/sdk/src/core';
import type { ErrorData } from 'hls.js';

export default function isHlsHTTPError(data: ErrorData) {
  if (data.type !== 'networkError' || data.details === 'fragLoadError' || data.details === 'levelLoadError') {
    return false;
  }

  return isUndefined(data.response?.data) && data.response?.code === 0;
}
