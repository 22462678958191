export interface ProcessingMapperOptions {
  freezeData: boolean;
}

export class ProcessingMapper {
  public static process<T>(data: T, options: ProcessingMapperOptions = { freezeData: true }) {
    if (options.freezeData) {
      Object.preventExtensions(data);
      Object.freeze(data);
    }

    return data;
  }
}
