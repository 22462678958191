import { MediaChapter } from '@PLAYER/player/modules/interfaces/chapters';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

const transformChapter = (chapter: MediaChapter) => {
  return {
    id: chapter.id,
    startOffset: chapter.start_offset,
    endOffset: chapter.end_offset,
    title: chapter.title,
  };
};

export default function useVideoChapters() {
  const videoConfig = useVideoConfig();

  const _chapters = computed(() => videoConfig['content.chapters'] || []);

  const isChaptersPresent = computed(() => Boolean(_chapters.value?.length));
  const chapters = computed(() => _chapters.value?.map(transformChapter));

  return {
    isChaptersPresent,
    chapters,
  };
}
