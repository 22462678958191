import GetRequest from '@PLAYER/modules/api/getRequest/GetRequest';
import TextResponseHandler from '@PLAYER/modules/api/handlers/TextResponseHandler';

class HlsManifestRequest extends GetRequest<string> {
  protected responseHandler = new TextResponseHandler();

  public constructor(private readonly manifestUrl: string) {
    super();
  }

  protected get additionalHeaders() {
    return {};
  }

  protected get url() {
    return this.manifestUrl;
  }
}

export function fetchHlsManifest(manifestUrl: string): Promise<string> {
  const requestInstance = new HlsManifestRequest(manifestUrl);

  return requestInstance.send();
}
