<template>
  <slot></slot>
  <intersection-element-observer :root-video-el="rootVideoEl" />
</template>

<script lang="ts" setup>
import { isDesktop, isMobile, isServer } from '@PLAYER/player/base/dom';
import IntersectionElementObserver from '@PLAYER/player/components/viewport/IntersectionElementObserver.vue';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useInstanceId from '@PLAYER/player/modules/instance/use-instance-id';
import useMouseHandler from '@PLAYER/player/modules/mouse/use-mouse-handler';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useSafeRootVideoElement from '@PLAYER/player/modules/video/use-safe-root-video-element';
import { onClickOutside } from '@vueuse/core';
import { storeToRefs } from 'pinia';

const rootVideoEl = useSafeRootVideoElement();
const mouse = useMouseHandler();

const layoutStore = useLayoutStore();
const externalEventBus = useSafeExternalEventBus();

const instanceId = useInstanceId();
const { isVOD, isLive } = useProjector();
const { isWeb } = usePlatform();
const { contextMenuOptions } = storeToRefs(layoutStore);

const setFocused = () => {
  layoutStore.setIsFocused(true);

  if (window.$vijuPlayer) {
    window.$vijuPlayer.currentPlayerId = instanceId;
  }
};

const onClick = (event: MouseEvent) => {
  layoutStore.setContextMenuOptions(undefined);

  setFocused();
  externalEventBus.emit('click', new VideoPlayerExternalEvent(event));
  mouse.emit('click', event);
};

const onDblClick = (event: MouseEvent) => {
  setFocused();
  mouse.emit('dblclick', event);
};

onClickOutside(rootVideoEl, () => {
  layoutStore.setContextMenuOptions(undefined);
  layoutStore.setIsFocused(false);
});

const onContextMenu = (event: MouseEvent) => {
  if (isMobile) {
    return event.preventDefault();
  }

  if (contextMenuOptions.value) {
    layoutStore.setContextMenuOptions(undefined);
    return;
  }

  const { left, top } = rootVideoEl.value.getBoundingClientRect();
  const { clientX, clientY } = event;

  const x = clientX - left;
  const y = clientY - top;

  layoutStore.setContextMenuOptions({ x, y });
  event.preventDefault();
};

const onMouseover = (event: MouseEvent) => {
  setFocused();
  mouse.emit('mouseover', event);
};

const onMouseleave = (event: MouseEvent) => {
  mouse.emit('mouseleave', event);
};

const onMousedown = (event: MouseEvent) => {
  setFocused();
  mouse.emit('mousedown', event);
};

const onMousemove = (event: MouseEvent) => {
  mouse.emit('mousemove', event);
};

const onTouchStart = (event: TouchEvent) => {
  setFocused();
  mouse.emit('touchstart', event);
};

const onDocumentVisibilityChange = () => {
  const isVisible = document.visibilityState === 'visible';
  layoutStore.setIsDocumentVisible(isVisible);
};

const onDocumentPointerUp = () => {
  if (!window.$vijuPlayer) {
    return;
  }

  window.$vijuPlayer.userHasMadeInteraction = true;
  document.removeEventListener('pointerup', onDocumentPointerUp);
};

useEffect(() => {
  if (isServer) {
    return;
  }

  /**
   * Если плеер это VOD или ТВ - значит плеер является "главным" на странице.
   * И при любом удобном случаее стараемся уводить его в фокус.
   * Делаем так для десктопного плеера
   */
  if ((isVOD.value || isLive.value) && isDesktop && isWeb) {
    onDocumentVisibilityChange();

    document.addEventListener('visibilitychange', onDocumentVisibilityChange);
  }

  document.addEventListener('pointerup', onDocumentPointerUp);

  rootVideoEl.value.addEventListener('mouseover', onMouseover);
  rootVideoEl.value.addEventListener('mouseleave', onMouseleave);
  rootVideoEl.value.addEventListener('mousedown', onMousedown);
  rootVideoEl.value.addEventListener('mousemove', onMousemove);

  rootVideoEl.value.addEventListener('dblclick', onDblClick);
  rootVideoEl.value.addEventListener('click', onClick);
  rootVideoEl.value.addEventListener('contextmenu', onContextMenu);
  rootVideoEl.value.addEventListener('touchstart', onTouchStart, { passive: true });

  return () => {
    document.removeEventListener('visibilitychange', onDocumentVisibilityChange);

    rootVideoEl.value.removeEventListener('mousemove', onMousemove);
    rootVideoEl.value.removeEventListener('mousedown', onMousedown);
    rootVideoEl.value.removeEventListener('mouseover', onMouseover);
    rootVideoEl.value.removeEventListener('mouseleave', onMouseleave);

    rootVideoEl.value.removeEventListener('dblclick', onDblClick);
    rootVideoEl.value.removeEventListener('click', onClick);
    rootVideoEl.value.removeEventListener('contextmenu', onContextMenu);
    rootVideoEl.value.removeEventListener('touchstart', onTouchStart);
  };
});
</script>
