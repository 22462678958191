export enum PlaybackState {
  Paused = 'paused',
  Stopped = 'stopped',
  Playing = 'playing',
}

export interface RemoteDeviceInfo {
  id: string;
  name: string;
  type: 'tv' | 'androidTV';
  volume_pct: number | null;
  flags: {
    streaming_quality_change: boolean;
    volume_change: boolean;
  };
}

export interface RemoteControlPlaylistItem {
  type: 'movie' | 'series';
  content_id: string;
  // Опционально для сериала
  parent_content_id?: string;
  sign?: string;
  sign_expire_at?: string;
  profile_id?: string;
  season?: number;
  episode?: number;
}

export interface RemoteControlQualityLevelState {
  active: boolean;
  current_level: 'auto' | number; // 480, 720, 1080
  levels: [];
}

export interface BaseRemoteControlEventWithVClock {
  vclock: Record<string, any>;
}

export interface RemoteContentStartContentEvent extends BaseRemoteControlEventWithVClock {
  id: string;
  offset: number;
  profile_id?: string;
  quality: {
    hls: RemoteControlQualityLevelState;
    dash: RemoteControlQualityLevelState;
  };
  playlist: RemoteControlPlaylistItem[];
}

export interface RemoteOffsetChangeEvent extends BaseRemoteControlEventWithVClock {
  offset: number;
}

export interface RemoteControlPairedEvent {
  rc: {
    id: string;
    name: string;
  };
}

export enum RCEventTopic {
  Paired = 'events.paired',
  PairChanged = 'events.pair_changed',
  Unpair = '?',
  StartContent = 'commands.start_content',
  Stop = 'commands.stop',
  Play = 'commands.play',
  Pause = 'commands.pause',
  OffsetChange = 'commands.offset_change',
  VolumeChange = 'commands.volume_change',
  QualityChange = 'commands.quality_change',
  StateChanged = 'events.state_changed',
}

export interface RCEventPayloadMap {
  [RCEventTopic.Play]: undefined;
  [RCEventTopic.OffsetChange]: RemoteOffsetChangeEvent;
  [RCEventTopic.PairChanged]: any;
  [RCEventTopic.Paired]: RemoteControlPairedEvent;
  [RCEventTopic.Pause]: undefined;
  [RCEventTopic.Stop]: undefined;
  [RCEventTopic.QualityChange]: any;
  [RCEventTopic.VolumeChange]: any;
  [RCEventTopic.Unpair]: any;
  [RCEventTopic.StateChanged]: undefined;
  [RCEventTopic.StartContent]: RemoteContentStartContentEvent;
}

export interface RCEvent {
  data: {
    topic: RCEventTopic;
    payload: RCEventPayloadMap[RCEventTopic];
  };
}
