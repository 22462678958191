<template>
  <app-icon :width="24" :height="24">
    <svg :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">
      <title v-if="$isNativeTitleTooltipShown">{{ $t('icons.episodes') }}</title>

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 8C1.89543 8 1 8.89543 1 10V18.9996C1 20.1041 1.89543 20.9996 3 20.9996H16C17.1046 20.9996 18 20.1041 18 18.9996V10C18 8.89543 17.1046 8 16 8H3ZM8.38666 11.7897L12.2504 14.1261C12.5412 14.3022 12.5412 14.7014 12.2504 14.8717L8.38666 17.2081C8.05715 17.4077 7.61133 17.1963 7.61133 16.8324V12.1654C7.61133 11.8074 8.05715 11.5902 8.38666 11.7897Z"
        fill="currentColor"
      />
      <rect x="5" y="2" width="19" height="3" rx="1.5" fill="currentColor" />
      <rect x="24" y="2" width="15" height="3" rx="1.5" transform="rotate(90 24 2)" fill="currentColor" />
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';

const viewBox = '0 0 24 24';
</script>
