import type { Channel, Media, Moment, Serial } from '@package/sdk/src/api';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

type ContentInformation = Media | Channel | Moment;

export default function useContentInformation() {
  const videoConfig = useVideoConfig();

  const content = computed<ContentInformation>(() => videoConfig['content.media'] || ({} as ContentInformation));

  const currentTvProgram = computed(() => (content.value as Channel)?.currentProgram);

  const isSerialContent = computed(() => {
    const serial = content.value as Serial;

    return serial?.contentType === 'serial' || serial?.contentType === 'season' || serial?.contentType === 'episode';
  });

  return {
    content,
    isSerialContent,
    currentTvProgram,
  };
}
