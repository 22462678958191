import { globalSettings } from '../global-settings';

type QRCodeRenderersOptions = any;

interface CreateQRCodeOptions {
  canvasEl: HTMLCanvasElement;
  text: string;
  rendererOptions?: QRCodeRenderersOptions;
}

export class QRService {
  public async createQRCode(options: CreateQRCodeOptions) {
    const { canvasEl, text, rendererOptions = {} } = options;

    const defaultRenderOptions: QRCodeRenderersOptions = {
      margin: 3,
      color: {
        dark: '#000000', // white
        light: '#ffffff', // --c-panel-background-color
      },
      scale: 16, // better quality
    };

    const assignedQrRendererOptions = Object.assign(defaultRenderOptions, rendererOptions);

    await globalSettings.qrcode.toCanvas(canvasEl, text, assignedQrRendererOptions);
  }
}
