import storage from '../platform/memory-storage';

const ApiBaseURL = {
  ru: {
    test: 'https://api.dsml.testvip.ru',
    prod: 'https://api.dsml.viju.ru',
  },
  am: {
    test: 'https://api-dev.dsml.viju.am',
    prod: 'https://api.dsml.viju.am',
  },
};

export const getBaseUrl = () => {
  const variation = storage.get('appVariation');
  const env = storage.get('env');

  const isDevEnvironment = env === 'development';

  if (isDevEnvironment) {
    return variation === 'am' ? ApiBaseURL.am.test : ApiBaseURL.ru.test;
  }

  return variation === 'am' ? ApiBaseURL.am.prod : ApiBaseURL.ru.prod;
};

export const DSML_ENDPOINTS = {
  EVENTS_BATCH: 'events/events_batch',
  UPDATE_TOKEN: 'events/update_token',
  CREATE_TOKEN: 'events/create_token',
  EVENTS_SEND: 'events/event',
};
