import { ProcessingMapper } from '../base/processing-mapper';
import { ApiSeason } from './content-types/season';
import { EpisodeMapper } from './episode';
import { MediaMapper } from './media';
import { Season } from './types/season';

export class SeasonMapper {
  static map(api: ApiSeason): Season {
    return ProcessingMapper.process({
      ...MediaMapper.map(api),
      number: api.number,
      episodes: api.episodes.map(EpisodeMapper.map),
    });
  }
}
