<template>
  <div
    :class="$style.icon"
    :style="{
      width: width ? `${width}px` : undefined,
      height: height ? `${height}px` : undefined,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: { type: Number, default: 24 },
    height: { type: Number, default: 24 },
  },
};
</script>

<style lang="scss" module>
.icon {
  display: inline-block;
  line-height: 1em;
  color: currentColor;
  user-select: none;
  vertical-align: initial;
  flex-shrink: 0;
  text-align: center;
}
</style>
