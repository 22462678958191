import { ChannelMapper } from '@package/sdk/src/api/channels/channel';
import { ChannelPlaylistMapper } from '@package/sdk/src/api/channels/channel-playlist';
import { ApiChannel, ApiChannelPlaylist } from '@package/sdk/src/api/channels/channel-types';
import { ChannelPlaylist } from '@package/sdk/src/api/channels/types/channel';
import { ENDPOINTS } from '@package/sdk/src/api/endpoints';

import { getStoreProperty, initStore, VuexStoreNamespace } from '../../index';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export class ChannelsService {
  constructor(private readonly requestService: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.requestService.abort(message);
  }

  public async fetchChannels(forceUpdate = false): Promise<void> {
    const store = initStore();
    const tvChannels = store.getters[getStoreProperty(VuexStoreNamespace.TvChannels, 'channels')];

    if (!forceUpdate) {
      if (tvChannels.length > 0) {
        return;
      }
    }

    const { data } = await this.requestService.request<ApiChannel[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.TV_CHANNELS,
      },
      { withToken: true, skipTokenValidation: true, canAbort: false },
    );

    const channels = ChannelMapper.mapMany(data);
    const actionName = getStoreProperty(VuexStoreNamespace.TvChannels, 'setChannels');

    store.dispatch(actionName, channels);
  }

  public async fetchChannelPlaylist(id: string): Promise<ChannelPlaylist> {
    const { data } = await this.requestService.request<ApiChannelPlaylist>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.TV_CHANNELS_PLAYLIST_ID,
        params: {
          id,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return ChannelPlaylistMapper.map(data);
  }
}
