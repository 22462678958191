import { DsmlRequestBodyParams } from '../request/send-single-event-request';
import { ClientType } from './user';

export interface DsmlRequestBodyV2Params {
  event_name?: string;
  client_type?: ClientType;
  event_page?: string;
  payload?: Record<string, unknown>;
  utc_timestamp?: number;
  user_timezone?: number;
  device_type?: string;
  session_order?: number;

  os_version?: string;
  browser_version?: string;
  session_id?: string;
  partner_id?: string;
  visitor_id?: string;
  user_id?: string;
  profile_id?: string;
  item_id?: string;
  episode_id?: string;
  kinom_id?: string;
  user_ip_v4?: string;
  user_ip_v6?: string;
  app_version?: string;
}

export default function transformBodyToV2Version(raw: DsmlRequestBodyParams, page: string): DsmlRequestBodyV2Params {
  const {
    event_type_id,
    client_type,
    session_order,
    session_id,
    user_ip_v6,
    user_ip_v4,
    user_timezone,
    user_id,
    item_id,
    episode_id,
    kinom_id,
    value,
    utc_timestamp,
    profile_id,
    partner_id,
    visitor_id,
    device_type,
    os_version,
    browser_version,
    app_version,
  } = raw;

  const event_page = page;

  const payload = value
    ? value.reduce(
        (acc, b) => {
          const { property, value } = b;

          acc[property] = value;
          return acc;
        },
        {} as Record<string, unknown>,
      )
    : {};

  return {
    device_type,
    payload,
    event_name: event_type_id,
    session_order,
    client_type,
    event_page,
    user_timezone,
    os_version,
    browser_version,
    utc_timestamp,
    session_id,
    partner_id,
    profile_id,
    item_id,
    episode_id,
    kinom_id,
    user_id,
    app_version,
    user_ip_v6,
    user_ip_v4,
    visitor_id,
  };
}
