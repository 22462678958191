import useLogger from '@package/logger/src/use-logger';

import { AbstractMediaAnalyzer } from './abstract-media-analyzer';

const logger = useLogger('ShakaPlayerAnalyzer');

export class ShakaPlayerAnalyzer extends AbstractMediaAnalyzer {
  // @ts-expect-error
  constructor(private readonly shaka: shaka.Player) {
    super();

    this.registerListeners();
  }

  private onError = (event: Event) => {
    logger.error(event);
  };

  private registerListeners() {
    this.shaka.addEventListener('error', this.onError);
  }

  public dispose() {
    this.shaka.removeEventListener('error', this.onError);
  }
}
