import type { IDisposable } from '@package/sdk/src/core';

import { globalSettings } from '../services';

type SmartTvVijuPlayer = any;

export function usePlayerSentryError() {
  let disposable: IDisposable | undefined;

  const startRecordingPlayerExceptions = (player: SmartTvVijuPlayer) => {
    disposable = player.on('error', (error: any) => globalSettings.sentry.captureException(error));
  };

  globalSettings.onUnmounted(() => {
    disposable?.dispose();
  });

  return {
    startRecordingPlayerExceptions,
  };
}
