export const PHONE_NUMBER_MASK_DEFAULT = '+xxxxxxxxxxxx'; // дефолтная маска на 12 символов для поддержки номеров других стран

const PHONE_NUMBER_MASK_LIST = [
  { code: 'RU', regExp: /^7[\d{10}]?/, mask: '+x (xxx) xxx xx xx' }, // Россия | Казахстан
  { code: 'RU', regExp: /^8[\d{10}]?/, mask: 'x (xxx) xxx xx xx' }, // Россия номера с 8
  { code: 'AM', regExp: /^374[\d{8}]?/, mask: '+xxx xx xx xx xx' }, // Армения
  { code: 'BY', regExp: /^375[\d{9}]?/, mask: '+xxx (xx) xxx xx xx' }, // Беларусь
  { code: 'GE', regExp: /^995[\d{9}]?/, mask: '+xxx (xxx) xxx xxx' }, // Грузия
];

/**
 * @param mask
 * @param input string to replace masked keys
 * @param maskedKey symbol to be replaced
 * @param restKey symbol to be used for replacing
 * @example
 * inputMask('+7 (xxx) xxx xx xx','12345') // => '+7 (123) 45_ __ __'
 */
export const inputMask = (mask = '', input = '', maskedKey = 'x', restKey = '_') => {
  let result = mask;

  input
    .replace(/[^0-9]/g, '')
    .split('')
    .forEach((x) => {
      result = result?.replace(maskedKey, x);
    });
  // Tizen/WebOS doesn't support `replaceAll`
  const replaceRegExp = new RegExp(maskedKey, 'g');

  return result?.replace(replaceRegExp, restKey).trim();
};

export const getPhoneMask = (value: string): string => {
  for (let i = 0; i < PHONE_NUMBER_MASK_LIST.length; i++) {
    if (PHONE_NUMBER_MASK_LIST[i].regExp.test(value.replace(/[^0-9]/g, '').slice(0, 3))) {
      return PHONE_NUMBER_MASK_LIST[i].mask;
    }
  }

  return PHONE_NUMBER_MASK_DEFAULT;
};
