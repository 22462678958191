import { mouseEventHandler } from '../../navigation/mouse-event-handler';

type Plugin = any;

const onWindowClick = (event: MouseEvent) => {
  mouseEventHandler.processTelemetryMouseEvent(event);
};

const onWheel = (_: WheelEvent) => {};

const onMouseMove = (_: MouseEvent) => {
  // TODO: Пока нет смысла собирать эти события
};

export const telemetry = {
  install() {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('click', onWindowClick);
    window.addEventListener('wheel', onWheel);
  },
} as Plugin;
