<template></template>

<script lang="ts" setup>
import useMousePositionDetection from '@PLAYER/player/modules/mouse/use-mouse-position-detection';
import { isDefined } from '@vueuse/core';
import { ref, watch } from 'vue';

const props = defineProps<{
  timelineEl: HTMLElement;
}>();

const isPreviewVisible = ref(false);

defineExpose({
  isPreviewVisible,
});

const { normalizedMouseXFraction } = useMousePositionDetection(ref(props.timelineEl));

watch(normalizedMouseXFraction, (xFraction) => {
  isPreviewVisible.value = isDefined(xFraction);
});
</script>
