import { AppLanguage, AppVariation } from '@package/smarttv-base/src/services/localization/translate';

import { Doc } from './index';

export const privacyPolicyDoc: Doc = {
  [AppVariation.Ru]: {
    [AppLanguage.RU]: {
      title: 'Политика конфиденциальности персональной информации',
      texts: [
        'Настоящая Политика конфиденциальности персональной информации («Политика») дополняет условия <a href="/user-agreement/" style="color: #00C7B2" target="_blank">Пользовательского соглашения</a>, размещённого на Сервисе, и действует в отношении информации, которую Пользователь передает Компании в ходе использования Сервиса, а также при обращении к службе технической поддержки Сервиса.',
        'Все термины и определения, указанные с заглавной буквы в настоящем тексте, следует трактовать в значении, указанном в Пользовательском соглашении и в настоящей Политике.',
        '<b>Использование Сервиса означает безоговорочное согласие Пользователя с настоящей Политикой; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервиса.</b>',
      ],
      points: [
        'Использование Сервиса не требует предоставления персональных данных (таких как: ФИО полностью, дата рождения, адрес проживания, электронная почта, содержащая в своем адресе персональные данные, и т.п.) в связи с чем при использовании Сервиса Пользователь обязуется не вводить никаких своих персональных данных. Однако в случае, если по какой-то причине Пользователь использует свои персональные данные для регистрации и/или авторизации и они были приняты Компанией, считается, что тем самым Пользователь предоставил Компании свое согласие на сбор, обработку и хранение своих персональных данных (включая право осуществить их передачу третьим лицам в соответствии с условиями настоящей Политики и Пользовательским соглашением).',
        'Настоящая Политика применима только к информации, обрабатываемой в ходе использования Пользователем Сервиса. Компания не контролирует и не несет ответственность за обработку информации платежными системами и аффилированными лицами, осуществляющими техническую реализацию и поддержку Сервиса.',
        'Пользователь согласен и признает, что в случае предоставления своих персональных данных, он предоставляет Компании свое согласие на их обработку. Указанное согласие действует до момента получения отзыва согласия Компанией.',
        `Цели сбора и условия обработки информации, полученной от Пользователей:<br>
      &mdash; Идентификация Пользователей в целях заключения и исполнения Пользовательского соглашения;<br>
      &mdash; Обработки запросов Пользователей, обращающихся за технической поддержкой Компании по электронному адресу <a href="mailto:help@viju.ru" target="_blank" style="color: #00c7b2">help@viju.ru</a>;<br>
      &mdash; В отдельных случаях контактная информация, предоставленная Пользователем (имя, компания, телефон, адрес электронной почты), может быть использована посредством направления сообщений в WhatsApp, коротких смс-сообщений или электронных писем для осуществления информационных сообщений (например, для восстановления доступа к Сервису) и информационных рассылок, для приглашения к участию в конкурсах, опросах, направленных на повышение качества работы Сервиса, а также для получения иной информации, в том числе рекламного характера.<br>
      &mdash; Осуществления возврата денежных средств на расчетный счет банка Пользователя, в случае необходимости;<br>
      &mdash; Анализа и исследования предпочтений Пользователя при использовании Сервиса для целей улучшения работы Сервиса;<br>
      &mdash; Проведение Компанией статистических или маркетинговых мероприятий на основе обезличенных данных;<br>
      &mdash; Улучшения качества и удобства использования Сервиса, повышение его эффективности.
    `,
        'Полученная информация от Пользователя обрабатывается в соответствии с положениями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», другими законодательными и нормативными правовыми актами, определяющими порядок работы с персональными данными и требования к обеспечению их безопасности.',
        'Компания не собирает намеренно данные лиц, не достигших 18 лет.',
        'Компания не собирает и не хранит данные о кредитных картах и другие детали оплаты.',
        'Компания собирает и хранит только те данные, которые были предоставлены Пользователем при авторизации (регистрации) и необходимы исключительно для предоставления доступа к Сервису (исполнения Пользовательского соглашения), а именно: контактный номер телефона Пользователя или адрес электронной почты, не содержащий персональные данные Пользователя.',
        'Пользователь вправе отказаться от получения информационной и рекламной информации от Компании в отношении Сервиса, посредством самостоятельного отключения в параметрах электронного письма с рассылкой функции получения рассылок посредством перехода по специальной ссылке, указанной в письме, или путем направления электронного письма на адрес электронной почты <a href="mailto:help@viju.ru" target="_blank" style="color: #00c7b2">help@viju.ru.</a>',
        'Компания принимает все необходимые и достаточные организационные и технические меры для защиты полученной информации от Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.',
        `Компания может также собирать и обрабатывать следующую информацию о Пользователе, а также о лице, не являющимся авторизированным пользователем (пользователь Сервиса, не прошедший регистрацию/не оформивший Подписку): электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные об Интернет-браузере, информацию об аппаратном и программном обеспечении, данные сети wi-fi), техническая информация об устройствах Пользователя, с помощью которых осуществляется доступ к Сервису, информация об их местонахождении, а также информацию о дате и времени осуществления доступа к Сервису.<br>
      При этом, Пользователь понимает, что данная информация не нужна Компании для целей установления личности Пользователя, а служит только технической информацией для обеспечения доступа к Сервису Пользователю в соответствии с условиями Пользовательского соглашения, а также имеющимися соглашениями с третьими лицами для обеспечения технической работоспособности Сервиса.`,
        `Компания использует файлы cookie (файлы с данными о прошлых посещениях Сервиса) для персонализации Сервиса и удобства Пользователя. Пользователь может сохранить сохранение файлов cookie в настройках своего браузера.<br>
      Пользователь настоящим дает свое согласие на сбор, анализ, и использование файлов cookie в целях контроля использования Сервиса, формирования статистики, сохранения предпочтений и другой информации на устройстве Пользователя, а также для отображения содержания в ходе последующих посещений Пользователем Сервиса.`,
        `Изменение Политики конфиденциальности.<br>
      Компания оставляет за собой право вносить изменения в настоящую Политику конфиденциальности. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.`,
      ],
      edition: 'Редакция от 29 сентября 2023 г.',
    },
    [AppLanguage.EN]: null,
    [AppLanguage.AM]: null,
  },
  [AppVariation.Am]: {
    [AppLanguage.EN]: {
      title: 'Privacy Policy of Personal Information',
      texts: [
        'This Privacy Policy of Personal Information ("Policy") supplements the terms of the <a href="https://viju.am/user-agreement/" style="color: #00c7b2" target="_blank">User Agreement</a> posted on the Service and applies to information that the User provides to the Company in the course of using the Service, as well as when contacting the technical support department of the Service.',
        'All capitalized terms and definitions in this text shall be interpreted within the meaning set forth in the User Agreement and this Policy.',
        '<b>Service Use shall mean that the User unconditionally agrees with this Policy; in case of disagreement with these terms, the User should refrain from using the Service.</b>',
      ],
      points: [
        'The use of the Service shall not require provision of personal data (such as: full name, date of birth, residential address, email containing personal data in its address, etc.) and therefore, when using the Service, the User undertakes not to enter any of his or her personal data. However, if for any reason, the User provides his or her personal data and it is accepted by the Company, it shall be deemed that by doing so the User has given the Company his or her consent to process his or her personal data (including the right to transfer it to third parties in accordance with the terms of this Policy and the User Agreement).',
        'This Policy applies only to information processed in the course of the Service use. The Company does not control and shall not be liable for processing of information by payment systems and affiliated persons providing technical implementation and support of the Service.',
        'The User agrees and acknowledges that in case of providing his or her personal data, he or she provides the Company with his or her consent to process it. This consent shall be valid until the Company receives the consent withdrawal.',
        `Purposes of collection and conditions for processing of information received from the Users:<br>
      &mdash; Identification of the Users for the purposes of entry into and execution of the User Agreement.<br>
      &mdash; Processing requests from the Users applying for technical support from the Company via email address <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>.<br>
      &mdash; In some cases, the contact information provided by the User (name, company, phone number, e-mail address) may be used by sending WhatsApp messages, short sms messages or e-mails to carry out informational messages (for example, to restore access to the Service) and newsletters, to invite to participate in contests, surveys aimed at improving the Service quality, as well as to receive other information, including advertising.<br>
      &mdash; Making refunds to the User's bank account, if necessary.<br>
      &mdash; Analyze and research the User's preferences when using the Service for the purpose of its improvement.<br>
      &mdash; The Company holds statistical or marketing activities based on anonymized data.<br>
      &mdash; Improving the quality and usability of the Service, increasing its efficiency.
    `,
        'The Company shall not knowingly collect data of minors under 18.',
        'The Company shall not collect or store credit card data or other payment details.',
        'The Company shall collect and store only those data that were provided by the User during authorization (registration) and are necessary solely to provide access to the Service (execution of the User Agreement), namely: the User&#700;s contact phone number or e-mail address that does not contain personal data of the User.',
        'The User shall be entitled to refuse to receive information and advertising information from the Company in relation to the Service by independently disabling the function of receiving newsletters by clicking on a special link specified in the email or by sending an email to the following address <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>.',
        'The Company shall take all necessary and sufficient organizational and technical measures to protect the information received from the User from illegal or accidental access, destruction, modification, blocking, copying, distribution, as well as from other illegal actions by third parties.',
        `The Company may also collect and process the following information about the User, as well as about a person who is not an authorized user (a Service user, who has not registered/subscribed): electronic data (HTTP headers, IP address, cookies, web beacons/pixel tags, Internet browser data, hardware and software information, wi-fi network data), technical information about the User's devices to access the Service, information about their location, as well as information about the date and time of access to the Service.<br>
      At the same time, the User understands that the Company does not need this information for the purposes of identifying the User, and it serves only as technical information to provide the User with access to the Service in accordance with the terms of the User Agreement, as well as existing agreements with third parties to ensure the technical operability of the Service.
    `,
        `The Company shall use cookies (files with data on previous visits to the Service) for personalization of the Service and convenience of the User. The User can save cookies in the settings of his or her browser.<br>
      The User hereby consents to collection, analysis and use of cookies in order to monitor the Service use, generate statistics, store preferences and other information on the User's device, as well as to display content during subsequent visits to the Service.
    `,
        `Modification of Confidentiality Policy.<br>
      The Company reserves the right to make changes to this Confidentiality Policy. The new version of the Policy shall become effective from the moment of its posting, unless otherwise provided by the new revision of the Policy.
    `,
      ],
      edition: 'Revision dated September 29, 2023',
    },
    [AppLanguage.RU]: {
      title: 'Политика конфиденциальности персональной информации',
      texts: [
        'Настоящая Политика конфиденциальности персональной информации («Политика») дополняет условия Пользовательского соглашения, размещённого на Сервисе, и действует в отношении информации, которую Пользователь передает Компании в ходе использования Сервиса, а также при обращении к службе технической поддержки Сервиса.',
        'Все термины и определения, указанные с заглавной буквы в настоящем тексте, следует трактовать в значении, указанном в Пользовательском соглашении и в настоящей Политике.',
        '<b>Использование Сервиса означает безоговорочное согласие Пользователя с настоящей Политикой; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервиса.</b>',
      ],
      points: [
        'Использование Сервиса не требует предоставления персональных данных (таких как: ФИО полностью, дата рождения, адрес проживания, электронная почта, содержащая в своем адресе персональные данные, и т.п.) в связи с чем при использовании Сервиса Пользователь обязуется не вводить никаких своих персональных данных. Однако в случае, если по какой-то причине Пользователь использует свои персональные данные и они были приняты Компанией, считается, что тем самым Пользователь предоставил Компании свое согласие на обработку своих персональных данных (включая право осуществить их передачу третьим лицам в соответствии с условиями настоящей Политики и Пользовательским соглашением).',
        'Настоящая Политика применима только к информации, обрабатываемой в ходе использования Сервиса. Компания не контролирует и не несет ответственность за обработку информации платежными системами и аффилированными лицами, осуществляющими техническую реализацию и поддержку Сервиса.',
        'Пользователь согласен и признает, что в случае предоставления своих персональных данных, он предоставляет Компании свое согласие на их обработку. Указанное согласие действует до момента получения отзыва согласия Компанией.',
        `Цели сбора и условия обработки информации, полученной от Пользователей:<br>
      &mdash; Идентификация Пользователей в целях заключения и исполнения Пользовательского соглашения;<br>
      &mdash; Обработки запросов Пользователей, обращающихся за технической поддержкой Компании по электронному адресу <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am</a>;<br>
      &mdash; В отдельных случаях контактная информация, предоставленная Пользователем (имя, компания, телефон, адрес электронной почты), может быть использована посредством направления сообщений в WhatsApp, коротких смс-сообщений или электронных писем для осуществления информационных сообщений (например, для восстановления доступа к Сервису) и информационных рассылок, для приглашения к участию в конкурсах, опросах, направленных на повышение качества Сервиса, а также для получения иной информации, в том числе рекламного характера.<br>
      &mdash; Осуществления возврата денежных средств на расчетный счет банка Пользователя, в случае необходимости;<br>
      &mdash; Анализа и исследования предпочтений Пользователя при использовании Сервиса для целей его улучшения;<br>
      &mdash; Проведение Компанией статистических или маркетинговых мероприятий на основе обезличенных данных;<br>
      &mdash; Улучшения качества и удобства использования Сервиса, повышение его эффективности.
    `,
        'Компания не собирает намеренно данные лиц, не достигших 18 лет.',
        'Компания не собирает и не хранит данные о кредитных картах и другие детали оплаты.',
        'Компания собирает и хранит только те данные, которые были предоставлены Пользователем при авторизации (регистрации) и необходимы исключительно для предоставления доступа к Сервису (исполнения Пользовательского соглашения), а именно: контактный номер телефона Пользователя или адрес электронной почты, не содержащий персональные данные Пользователя.',
        'Пользователь вправе отказаться от получения информационной и рекламной информации от Компании в отношении Сервиса, посредством самостоятельного отключения в параметрах электронного письма с рассылкой функции получения рассылок посредством перехода по специальной ссылке, указанной в письме, или путем направления электронного письма на адрес электронной почты <a href="mailto:help@viju.am" target="_blank" style="color: #00c7b2">help@viju.am.</a>',
        'Компания принимает все необходимые и достаточные организационные и технические меры для защиты полученной информации от Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.',
        `Компания может также собирать и обрабатывать следующую информацию о Пользователе, а также о лице, не являющимся авторизированным пользователем (пользователь Сервиса, не прошедший регистрацию/не оформивший Подписку): электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные об Интернет-браузере, информацию об аппаратном и программном обеспечении, данные сети wi-fi), техническая информация об устройствах Пользователя, с помощью которых осуществляется доступ к Сервису, информация об их местонахождении, а также информацию о дате и времени осуществления доступа к Сервису.<br>
        При этом, Пользователь понимает, что данная информация не нужна Компании для целей установления личности Пользователя, а служит только технической информацией для обеспечения доступа к Сервису Пользователю в соответствии с условиями Пользовательского соглашения, а также имеющимися соглашениями с третьими лицами для обеспечения технической работоспособности Сервиса.`,
        `Компания использует файлы cookie (файлы с данными о прошлых посещениях Сервиса) для персонализации Сервиса и удобства Пользователя. Пользователь может сохранить сохранение файлов cookie в настройках своего браузера.<br>
        Пользователь настоящим дает свое согласие на сбор, анализ, и использование файлов cookie в целях контроля использования Сервиса, формирования статистики, сохранения предпочтений и другой информации на устройстве Пользователя, а также для отображения содержания в ходе последующих посещений Пользователем Сервиса.`,
        `Изменение Политики конфиденциальности.<br>
        Компания оставляет за собой право вносить изменения в настоящую Политику конфиденциальности. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.`,
      ],
      edition: 'Редакция от 29 сентября 2023 г.',
    },
    [AppLanguage.AM]: null,
  },
};
