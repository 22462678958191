import { isNumber } from '../std/types';

export function toPixel(value: number): string {
  return value + 'px';
}

export function toPercent(value: number): string {
  if (!isNumber(value)) {
    throw new TypeError('Expect value to be a number');
  }

  return value + '%';
}
