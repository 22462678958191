<template>
  <section ref="container" :class="$style.wrapper">
    <h2 :class="$style.title">{{ title }}</h2>

    <div ref="el" :class="$style.collectionsWrapper">
      <ScrollViewport tag="ul" :class="$style.collections" :x="offsetLeftPx" role="list">
        <li v-for="(item, index) in items" :key="item.id" :class="$style.collection">
          <NavigatableItem
            :tag="AppButton"
            :data-testid="`collections-${index + 1}`"
            :data-index="index"
            :prop-parent-focus-key="FocusKeys.MEDIA_CARD_COLLECTIONS_SLIDER"
            :text="item.title"
            @active="(element) => handleActiveCollection(element as HTMLElement)"
            @click="onSelectCollection(index)"
          />
        </li>
      </ScrollViewport>
    </div>

    <MediaCardCollectionsSliderList :items="currentCollectionContent" @active="handleActiveList" />
  </section>
</template>

<script setup lang="ts">
import type { Compilation } from '@package/sdk/src/api';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { ContentGetters, FocusKeys, storeToRefs, useContentStore, useSliderOffset } from '@SMART/index';
import { computed, provide, ref } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import MediaCardCollectionsSliderList from './MediaCardCollectionsSliderList.vue';

const { el, focusKey } = useNavigatable({
  focusKey: FocusKeys.MEDIA_CARD_COLLECTIONS_SLIDER,
  saveLastFocusedChild: true,
  hasGlobalAccess: true,
  isFocusBoundary: true,
  focusBoundaryDirections: ['right'],
});
provide('parentFocusKey', focusKey.value);

const contentStore = useContentStore();
const { content } = storeToRefs<unknown, ContentGetters, unknown>(contentStore);

interface Props {
  items: Compilation[];
  title: string;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'activated', offset: number): void;
}>();

const { handleUpdateOffset, offsetLeftPx } = useSliderOffset();

const container = ref<HTMLElement>();
const activeCollectionIndex = ref(0);

const currentCollection = computed(() => props.items[activeCollectionIndex.value]);
const currentCollectionContent = computed(() =>
  currentCollection.value.contents.filter((contentItem) => contentItem.id !== content.value.id),
);

const handleActiveCollection = (element: HTMLElement) => {
  handleUpdateOffset(element, el.value?.offsetWidth);
  emit('activated', container.value?.offsetTop || 0);
};

const handleActiveList = () => {
  emit('activated', container.value?.offsetTop || 0);
};

const onSelectCollection = (index: number) => (activeCollectionIndex.value = index);
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-bottom: adjust.adjustPx(32px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.list {
  display: flex;
  height: 200px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;

  width: adjust.adjustPx(376px);
  min-width: adjust.adjustPx(376px);
  height: adjust.adjustPx(508px);
  min-height: adjust.adjustPx(508px);
  border-radius: var(--g-border-round-24);
  background-color: var(--color-bg-tertiary);
  margin-right: adjust.adjustPx(32px);
  outline: none;
  justify-content: center;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }

  .subtitle {
    position: absolute;
    top: adjust.adjustPx(516px);
    left: adjust.adjustPx(8px);

    display: -webkit-box;
    width: adjust.adjustPx(376px);

    overflow: hidden;
    color: var(--color-notheme-text-primary);
    text-align: start;
    min-width: adjust.adjustPx(376px);
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include smartTvFonts.SmartTvBody-1();
  }
}

.collectionsWrapper {
  margin-bottom: adjust.adjustPx(32px);
}

.collections {
  display: flex;
}

.collection {
  height: adjust.adjustPx(80px);
  min-height: adjust.adjustPx(80px);
  margin-right: adjust.adjustPx(24px);
  border-radius: adjust.adjustPx(16px);
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  border-radius: var(--g-border-round-24);
  overflow: hidden;
  outline: none;
  justify-content: center;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--g-border-round-24);
    content: '';
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
  }
}
</style>
