import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';

import { DsmlRequestBodyV2Params } from '../code/dsml-v2-request-transformer';
import { eventBuffer } from '../code/event-buffer';
import getActualToken from '../code/get-actual-token';
import { ClientType } from '../code/user';
import { DsmlValue } from '../dsml';
import { AbstractSendEventRequest } from './abstract-send-event-request';
import BadResponseError from './api/errors/BadResponseError';

const handledStatuses = [HTTPStatusCode.Unauthorized, HTTPStatusCode.Forbidden, HTTPStatusCode.InternalServerError];

class SendSingleEventRequest extends AbstractSendEventRequest {}

export interface DsmlRequestBodyParams {
  client_type?: ClientType;
  event_type_id?: string;
  item_id?: string;
  episode_id?: string;
  kinom_id?: string;
  profile_id?: string;
  visitor_id?: string;
  partner_id?: string;
  session_id?: string;
  os_version?: string;
  browser_version?: string;
  device_type?: string;
  user_id?: string;
  user_ip_v6?: string;
  user_ip_v4: string;
  session_order?: number;
  utc_timestamp?: number;
  user_timezone?: number;
  value?: DsmlValue[];
  app_version?: string;
}

export async function sendDsmlEvent(event: DsmlRequestBodyV2Params) {
  const request = new SendSingleEventRequest(event);

  const token = await getActualToken();
  request.setToken(token);

  try {
    await request.send();
  } catch (error) {
    if (error instanceof BadResponseError && handledStatuses.includes(error.response.status)) {
      eventBuffer.add(event);
    }

    throw error;
  }
}
