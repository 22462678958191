<template>
  <input
    :class="{
      [$style.input]: true,
      [$style.inputDisabled]: disabled,
    }"
    :placeholder="placeholder"
    :disabled="disabled"
    :type="type"
    :value="input"
    @input="(event) => emit('update:input', event.target.value)"
  />
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    input?: string;
  }>(),
  {
    type: 'text',
  },
);

const emit = defineEmits(['update:input']);
</script>

<style module lang="scss">
.input {
  box-sizing: border-box;
  padding: 0 var(--g-spacing-12);
  min-height: 40px;
  border-radius: var(--g-border-round-8);
  border: 1px solid var(--color-notheme-text-primary);
  background-color: var(--color-bg-primary);
  font-family: inherit;
  font-size: 12px;
  color: var(--color-notheme-text-primary);
  outline: none;
}

.inputDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
