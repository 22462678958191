import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import VideoEventHandler from '@PLAYER/player/modules/video/video-event-handler';
import { inject } from 'vue';

const injectKey = 'videoEventHandler';

export default function useSafeVideoEventHandler() {
  const { getDefault } = useDefaultProvide();

  const eventHandler = inject<VideoEventHandler>(injectKey, getDefault(injectKey));

  if (!eventHandler) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return eventHandler;
}
