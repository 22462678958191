export enum ParentalControlStatus {
  NotSet = 'not_set',
  ChildrenAccess = 'children_access',
  NoChildrenAccess = 'no_children_access',
}

export enum ParentalControlStep {
  Hidden,
  ChildrenHaveAccessPrompt,
  ChooseProfile,
  CheckPinCode,
  SetPinCode,
  Success,
}

export enum ParentalControlModalState {
  None = 'none',
  ChosenProfile = 'chosen-profile',
  HasChildren = 'has-children',
}

export interface ParentControl {
  ageLimit: number;
}
