<template>
  <div class="page-container">
    <QrLogin v-if="type === 'qr'" @update:type="onTypeChange" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import QrLogin from './components/QrLogin.vue';

type AuthType = 'qr' | 'email';

const type = ref<AuthType>('qr');

const onTypeChange = (value: AuthType) => {
  type.value = value;
};
</script>
