<template>
  <div :class="$style.build">
    <div>App: {{ appVersion }}</div>
    <template v-if="!isRelease">
      <div>Device_UUID: {{ deviceName.uid }}</div>
      <div>Host: {{ host }}</div>
      <div>Build Date: {{ buildDate }}</div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { deviceService, environmentService } from '@SMART/index';

const isRelease = environmentService.getVariable<boolean>('isRelease');

const appVersion = environmentService.getVariable<() => string>('appVersion')();
const deviceName = deviceService.getDevice();
const buildDate = environmentService.getVariable('buildDate');

const host = document.location.host;
</script>

<style lang="scss" module>
@import '@/styles/colors';

.build {
  position: absolute;
  right: 10px;
  z-index: 10000;
  font-family: monospace;
  font-size: 8px;
  color: var(--color-text-primary);
  pointer-events: none;
}
</style>
