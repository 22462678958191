import { isString, UnexpectedComponentStateError } from '@package/sdk/src/core';

export default function useCDNImage() {
  const getCDNLink = (src: string | undefined, width: number) => {
    if (!src) {
      return '';
    }

    if (!isString(src)) {
      throw new UnexpectedComponentStateError('src');
    }

    return `${src}?w=${width}`;
  };

  const isCDNLink = (link: string | undefined) => {
    if (!isString(link)) {
      return false;
    }

    return link.includes('?w=');
  };

  return {
    isCDNLink,
    getCDNLink,
  };
}
