import getQueryParamByName from '@package/sdk/src/core/dom/get-query-param-by-name';

import type { RouterService } from '../router/router-service';
import { AbstractManufacturerService } from './abstract-manufacturer-service';
import type { DeviceService } from './device-service';
import { SetVolumeLevel } from './device-types';
import { PerformanceFeatureMap } from './performance-features';

export class DesktopService extends AbstractManufacturerService {
  constructor(
    private readonly deviceService: DeviceService,
    private readonly routerService: RouterService,
  ) {
    super(new PerformanceFeatureMap());
  }

  public setVolume(volume: SetVolumeLevel) {
    console.info(volume);
  }

  public getVolume(): number {
    return 0;
  }

  public getHumanReadableDeviceName(): string {
    return `SmartTV App ${this.deviceService.deviceUUID}`;
  }

  public getManufacturerName(): string {
    return `SmartTV App ${this.deviceService.deviceUUID}`;
  }

  public getDeviceInfo(): unknown {
    return undefined;
  }

  public async resolveStartupDeeplink(): Promise<void> {
    const deeplink = getQueryParamByName('deeplink');

    if (!deeplink) {
      return;
    }

    const route = this.routerService.resolve(deeplink);

    if (!route) {
      return;
    }

    return this.routerService.replace(route);
  }

  public init() {
    return Promise.resolve(undefined);
  }

  public exit() {
    window.close();
  }
}
