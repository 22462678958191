import { ProcessingMapper } from '../base/processing-mapper';
import { ApiCountry } from './content-types/country';
import { Country } from './types/country';

export class CountryMapper {
  static mapMany(countries: ApiCountry[]): Country[] {
    return ProcessingMapper.process(countries.map(CountryMapper.map));
  }

  static map({ code, seo_slug, seo_title, title }: ApiCountry): Country {
    return ProcessingMapper.process({
      code,
      seoSlug: seo_slug.replaceAll('-', '_'),
      seoTitle: seo_title,
      title,
      filterType: 'country',
      slug: seo_title.replaceAll('-', '_'),
    });
  }
}
