import { AppLanguage, AppVariation } from '@package/smarttv-base/src/services/localization/translate';

import type { Doc } from './index';

export const mailingRulesDoc: Doc = {
  [AppVariation.Ru]: {
    [AppLanguage.RU]: {
      title: 'Правила рассылок',
      texts: [
        'Эти правила рассылок (Правила) определяют условия рассылок Сообщений Пользователям, осуществляемые Провайдером и/или его Партнером.',
      ],
      points: [
        {
          title: '1. ТЕРМИНЫ',
          items: [
            '1.1. Провайдер – Общество с ограниченной ответственностью «Виасат Глобал» (ОГРН 5067746471766, адрес: 123242, г.Москва, вн.тер.г. Муниципальный округ Пресненский, ул Зоологическая, д. 1, стр. 1.).',
            '1.2. Сервис / Сервис «viju» – программное обеспечение, обозначенное средствами индивидуализации, принадлежащими Провайдеру, размещенное в сети Интернет на сайте viju.ru и доступное Пользователям посредством установки на различных электронных устройствах. Сервис имеет возрастное ограничение 18+.',
            '1.3. Пользователь – физическое лицо, использующее Сервис и обладающее необходимой дееспособностью для принятия условий Правил.',
            '1.4. Сообщения – информационные сообщения, в том числе новостного и/или рекламного характера, связанные с Сервисом и/или тематикой Сервиса, а также с товарами/услугами третьих лиц, в том числе Партнера, направляемые в том числе по электронной почте, в смс-сообщениях, пуш уведомлениях или посредством осуществления телефонных звонков. Настоящие Правила не применяются к рассылке Провайдером сервисных (технических) сообщений, информирующих Пользователя об использовании им Сервиса, в том числе, но не ограничиваясь: сообщений, содержащих логин и пароль для доступа к Сервису, содержащих код авторизации в Сервисе, и т.д. Указанные сообщения рассылаются Провайдером в силу регистрации Пользователя в Сервисе и отказ от рассылки таких сообщений возможен только путем удаления учетной записи в Сервисе.',
            '1.5. Подписка на рассылку – действия Пользователя при использовании Сервиса, направленные на систематическое получение Сообщений от Провайдера и Партнера.',
            '1.6. Партнер – ООО «Майндбокс» (ОГРН 1097746380380).',
          ],
        },
        {
          title: '2. ДЕЙСТВИЕ ПРАВИЛ',
          items: [
            '2.1. Правила являются публичной офертой в соответствии со ст. 437 Гражданского кодекса РФ. Подписка на рассылку означает полный и безоговорочный акцепт Правил Пользователем.',
            '2.2. В случае несогласия Пользователя с каким-либо из положений Правил, Пользователь обязан отказаться от Подписки на рассылку.',
            '2.3. Правила являются частью Пользовательского соглашения Сервиса «viju», Политики конфиденциальности Сервиса «viju».',
            '2.4. Правила и отношения Пользователя с Провайдером и Партнером, возникающие в связи с Подпиской на рассылку, регулируются законодательством РФ.',
            '2.5. Правила могут быть изменены Провайдером в одностороннем внесудебном порядке, путем внесения соответствующих изменений в настоящие Правила. Используя Сервис и/или продолжая получать Сообщения после изменения Правил, Пользователь принимает Правила полностью и безоговорочно в измененном виде. Пользователь самостоятельно осуществляет проверку изменений и/или дополнений в Правила.',
          ],
        },
        {
          title: '3. УСЛОВИЯ РАССЫЛКИ СООБЩЕНИЙ',
          items: [
            '3.1. Принимая условия Правил, Пользователь выражает свое согласие на получение Сообщений от Провайдера и Партнера по адресу электронной почты или номеру телефона, указанным Пользователем в Сервисе и/или при Подписке на рассылку.',
            '3.2. Провайдер исходит из того, что Подписка на рассылку осуществляется непосредственно Пользователем, а также что адрес электронной почты и номер телефона, указанные Пользователем при Подписке на рассылку и/или в Сервисе, являются корректными и принадлежат Пользователю. Ответственность за правомерность предоставления сведений при Подписке на рассылку и их достоверность несет исключительно Пользователь.',
            '3.3. Пользователь вправе в любое время отказаться от получения Сообщений путем перехода по соответствующей гиперссылке, содержащейся в Сообщении, либо путем направления заявления на электронный адрес службы поддержки Провайдера <a href="mailto:help@viju.ru" style="color: #00c7b2" target="_blank">help@viju.ru</a>.',
          ],
        },
      ],
      edition: 'Версия от 31 января 2024 года.',
    },
    [AppLanguage.EN]: null,
    [AppLanguage.AM]: null,
  },
  [AppVariation.Am]: {
    [AppLanguage.RU]: null,
    [AppLanguage.EN]: null,
    [AppLanguage.AM]: null,
  },
};
