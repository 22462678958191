import { isTrue } from '../../core/std/types';
import { ProcessingMapper, ProcessingMapperOptions } from '../base/processing-mapper';
import { AvailableContentType } from '../content/types/content-type';
import { SeoTextMapper } from '../seo/seo-text';
import { ChannelProgramMapper } from './channel-program';
import { ApiChannel } from './channel-types';
import { Channel, ChannelProgram } from './types/channel';

export class ChannelMapper {
  static mapMany(items: ApiChannel[], options: ProcessingMapperOptions = { freezeData: true }): Channel[] {
    return ProcessingMapper.process(
      items.map((item) => ChannelMapper.map(item, options)),
      options,
    );
  }

  static map(
    {
      use_dvr,
      access_kind,
      logo_white,
      logo_black,
      current_program,
      next_program,
      programs,
      seo_text,
      availability,
      locked_info,
      ...rest
    }: ApiChannel,
    options: ProcessingMapperOptions = { freezeData: true },
  ): Channel {
    return ProcessingMapper.process(
      {
        ...rest,
        availability: isTrue(availability) ? AvailableContentType.AVAILABLE : AvailableContentType.UNAVAILABLE,
        useDvr: use_dvr,
        accessKind: access_kind,
        logoWhite: logo_white,
        logoBlack: logo_black,
        lockedInfo: locked_info,
        currentProgram: current_program ? ChannelProgramMapper.map(current_program) : ({} as ChannelProgram),
        nextProgram: next_program ? ChannelProgramMapper.map(next_program) : undefined,
        programs: programs ? ChannelProgramMapper.mapMany(programs) : [],
        seoText: seo_text ? SeoTextMapper.map(seo_text) : undefined,
      },
      options,
    );
  }
}
