import useSafeRootVideoElement from '@PLAYER/player/modules/video/use-safe-root-video-element';
import { useElementSize } from '@vueuse/core';
import { computed } from 'vue';

const MEDIUM_VIEW_BREAKPOINT = 858;
const MOBILE_VIEW_BREAKPOINT = 568;
const COMPACT_VIEW_BREAKPOINT = 480;
const TINY_VIEW_BREAKPOINT = 360;
const TINEST_VIEW_BREAKPOINT = 320;
const VIEW_BREAKPOINT_800 = 800;

export default function useRootVideoElBounds() {
  const rootVideoEl = useSafeRootVideoElement();
  const { width, height } = useElementSize(rootVideoEl);

  const isMediumView = computed(() => width.value <= MEDIUM_VIEW_BREAKPOINT);
  const isSmallView = computed(() => width.value <= COMPACT_VIEW_BREAKPOINT);
  const isTinyView = computed(() => width.value <= TINY_VIEW_BREAKPOINT);
  const isTinestView = computed(() => width.value <= TINEST_VIEW_BREAKPOINT);

  const isMobileView = computed(() => width.value <= MOBILE_VIEW_BREAKPOINT);
  const isViewBreakpoint800 = computed(() => width.value >= VIEW_BREAKPOINT_800);

  return {
    isTinestView,
    isTinyView,
    isSmallView,
    isMediumView,
    isMobileView,
    appWidth: width,
    appHeight: height,
    isViewBreakpoint800,
  };
}
