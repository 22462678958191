import { ApplicationError } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';
import type { AxiosError } from 'axios';

const getDevMessage = (message: string, url: string) => {
  return `${url}: ${message}`;
};

export class ApiError extends ApplicationError {
  public status?: HTTPStatusCode;

  public readonly name = 'ApiError';

  public readonly devMessage: string;

  constructor(
    error: AxiosError | Error,
    private readonly url: string,
  ) {
    super();

    const axiosError = error as AxiosError<{ message: string }>;

    this.status = axiosError.response?.status || axiosError.status;
    this.message = axiosError.response?.data?.message || error.message;
    this.devMessage = getDevMessage(this.message, url);
  }

  public is(status: HTTPStatusCode) {
    return this.status === status;
  }

  public toJSON(): Record<string, any> {
    const { url, status, message } = this;

    return {
      url,
      status,
      message,
    };
  }
}
