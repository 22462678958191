import { globalSettings } from '../services';
import { AuthState } from './use-auth-store';
import { CatalogGetters, CatalogState } from './use-catalog-store';
import { ContentGetters, ContentState } from './use-content-store';
import { MainPageGetters, MainPageState } from './use-main-page-store';
import { SessionGetters, SessionState } from './use-session-store';
import { TvChannelGetters, TvChannelState } from './use-tv-channels-store';
import { VuexStoreNamespace } from './utils/namespaces';

export * from './use-auth-store';
export * from './use-catalog-store';
export * from './use-content-store';
export * from './use-main-page-store';
export * from './use-session-store';
export * from './use-tv-channels-store';
export * from './utils';

const isClient = typeof window !== 'undefined';

export interface StoreModuleMap {
  [VuexStoreNamespace.Auth]: AuthState;
  [VuexStoreNamespace.Content]: ContentState;
  [VuexStoreNamespace.Session]: SessionState;
  [VuexStoreNamespace.Catalog]: CatalogState;
  [VuexStoreNamespace.MainPage]: MainPageState;
  [VuexStoreNamespace.TvChannels]: TvChannelState;
}

export interface GettersModuleMap {
  [VuexStoreNamespace.Content]: ContentGetters;
  [VuexStoreNamespace.Auth]: unknown;
  [VuexStoreNamespace.Session]: SessionGetters;
  [VuexStoreNamespace.Catalog]: CatalogGetters;
  [VuexStoreNamespace.TvChannels]: TvChannelGetters;
  [VuexStoreNamespace.MainPage]: MainPageGetters;
}

type MappedStoreActions<Actions> = { [Property in keyof Actions]: Actions[Property] };

export type AbstractStore<Actions> = MappedStoreActions<Actions> & {
  commit(property: string, value: any, options?: { root: boolean }): void;

  dispatch(property: string, value: any): void;

  registerModule(namespace: VuexStoreNamespace, module: any): void;

  $patch(value: any): void;
};

export type InitializedModuleStore<_, __, Actions, ModuleName extends VuexStoreNamespace> = AbstractStore<Actions> & {
  readonly state: StoreModuleMap[ModuleName];
  readonly getters: GettersModuleMap[ModuleName];
};

type AddVuexModulePrefix<Prefix extends string, Type> = {
  [Property in keyof Type as `${Prefix}/${Property & string}`]: Type[Property];
};

type GlobalState = AddVuexModulePrefix<'auth', AuthState> &
  AddVuexModulePrefix<'session', SessionState> &
  AddVuexModulePrefix<'catalog', CatalogState> &
  AddVuexModulePrefix<'tvChannels', TvChannelState> &
  AddVuexModulePrefix<'mainPage', MainPageState> &
  AddVuexModulePrefix<'content', ContentState>;

type GlobalGetters = AddVuexModulePrefix<'auth', {}> &
  AddVuexModulePrefix<'session', SessionGetters> &
  AddVuexModulePrefix<'catalog', CatalogGetters> &
  AddVuexModulePrefix<'tvChannels', TvChannelGetters> &
  AddVuexModulePrefix<'mainPage', MainPageGetters> &
  AddVuexModulePrefix<'content', ContentGetters>;

export interface GlobalStore extends AbstractStore<unknown> {
  readonly state: GlobalState;
  readonly getters: GlobalGetters;
}

let store: GlobalStore;

declare global {
  interface Window {
    $smartTvStore: unknown;
  }
}

export const initStore = () => {
  if (store) {
    if (isClient) {
      window.$smartTvStore = store;
    }

    return store;
  }

  if (globalSettings.vue) {
    globalSettings.vue.use(globalSettings.vuex);
    const Store = globalSettings.vuex.Store;

    store = new Store({
      modules: {},
      strict: process.env.NODE_ENV !== 'production',
    });
  }

  return store;
};
