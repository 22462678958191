import useProjector from '@PLAYER/player/modules/hooks/use-projector';

import { PlayerFocusKeys } from './focus-keys';

export default function useSmartNavigatable() {
  const { isKinom } = useProjector();

  if (!window.$smarttv || isKinom.value) {
    return new Proxy(
      {
        useNavigatable: () => ({
          el: null,
          focused: null,
          focusKey: null,
          focusSelf: () => {},
          addFocusable: () => {},
          removeFocusable: () => {},
        }),
        SpatialNavigation: null,
        PlayerFocusKeys,
      },
      {},
    );
  }
  return { ...window.$smarttv, PlayerFocusKeys };
}
