<template>
  <button ref="buttonEl" :tabindex="tabIndex" :class="$style.button" :disabled="isDisabled">
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { AnyFunction } from '@PLAYER/player/base/function';
import useKeyboardShortcuts from '@PLAYER/player/modules/keyboard/use-keyboard-shortcuts';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    isDisabled?: boolean;
    tabIndex?: number;
    onClick: AnyFunction;
  }>(),
  {
    isDisabled: false,
    tabIndex: 0,
  },
);

const { onSmartTvKeydown } = useKeyboardShortcuts();

const buttonEl = ref<HTMLElement>();
const onKeydown = onSmartTvKeydown(props.onClick);

onMounted(() => {
  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  buttonEl.value.addEventListener('click', props.onClick);
  buttonEl.value.addEventListener('keydown', onKeydown);
});

onBeforeUnmount(() => {
  if (!buttonEl.value) {
    throw new UnexpectedComponentStateError('buttonEl');
  }

  buttonEl.value.removeEventListener('click', props.onClick);
  buttonEl.value.removeEventListener('keydown', onKeydown);
});
</script>

<style module lang="scss">
.button {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  color: inherit;
  cursor: pointer;
  pointer-events: all;

  &[disabled] {
    cursor: default;
  }
}
</style>
