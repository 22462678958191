import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { getCurrentInstance, hasInjectionContext, inject, Ref, ref } from 'vue';

const injectKey = 'rootVideoEl';

export default function useSafeRootVideoElement() {
  const { getDefault } = useDefaultProvide();

  const rootVideoEl = inject<Ref<HTMLDivElement>>(injectKey, getDefault(injectKey));

  if (!rootVideoEl) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return ref(rootVideoEl.value);
}
