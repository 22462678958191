<template>
  <div data-smart-tv-control>
    <smart-tv-video-control-button :text="text" aria-label="''" aria-keyshortcuts="Space" :on-click="requestDebugMenu">
      <span>{{ text }}</span>
    </smart-tv-video-control-button>
  </div>
</template>

<script lang="ts" setup>
import useDebugActions from '@PLAYER/player/modules/debug/use-debug-actions';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';

const { requestDebugMenu } = useDebugActions();

const text = 'Отладка';
</script>
