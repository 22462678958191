<template>
  <app-icon :width="bounds.width" :height="bounds.height">
    <svg :viewBox="getSVGViewboxByIconSize(size)" xmlns="http://www.w3.org/2000/svg">
      <title v-if="$isNativeTitleTooltipShown">{{ $t('vod.nextMedia') }}</title>
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.5 1.5C9.22386 1.5 9 1.72386 9 2V10C9 10.2761 9.22386 10.5 9.5 10.5H10C10.2761 10.5 10.5 10.2761 10.5 10V2C10.5 1.72386 10.2761 1.5 10 1.5H9.5ZM7.64558 5.3897L2.68593 1.84709C2.18953 1.49252 1.5 1.84736 1.5 2.45739V9.54261C1.5 10.1526 2.18953 10.5075 2.68593 10.1529L7.64558 6.6103C8.06434 6.31118 8.06434 5.68882 7.64558 5.3897Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.25 1.25C12.8358 1.25 12.5 1.58579 12.5 2V14C12.5 14.4142 12.8358 14.75 13.25 14.75H14C14.4142 14.75 14.75 14.4142 14.75 14V2C14.75 1.58579 14.4142 1.25 14 1.25H13.25ZM10.4684 7.08455L3.0289 1.77064C2.28429 1.23878 1.25 1.77104 1.25 2.68609V13.3139C1.25 14.229 2.28429 14.7612 3.02889 14.2294L10.4684 8.91545C11.0965 8.46678 11.0965 7.53322 10.4684 7.08455Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19 3C18.4477 3 18 3.44772 18 4V20C18 20.5523 18.4477 21 19 21H20C20.5523 21 21 20.5523 21 20V4C21 3.44772 20.5523 3 20 3H19ZM15.2912 10.7794L5.37186 3.69418C4.37905 2.98504 3 3.69473 3 4.91478V19.0852C3 20.3053 4.37905 21.015 5.37186 20.3058L15.2912 13.2206C16.1287 12.6224 16.1287 11.3776 15.2912 10.7794Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.9167 3.25C25.1343 3.25 24.5 3.88426 24.5 4.66667V27.3333C24.5 28.1157 25.1343 28.75 25.9167 28.75H27.3333C28.1157 28.75 28.75 28.1157 28.75 27.3333V4.66667C28.75 3.88426 28.1157 3.25 27.3333 3.25H25.9167ZM20.6625 14.2708L6.61013 4.23343C5.20366 3.22881 3.25 4.23419 3.25 5.96261V26.0374C3.25 27.7658 5.20366 28.7712 6.61013 27.7666L20.6625 17.7292C21.849 16.8817 21.849 15.1183 20.6625 14.2708Z"
            fill="currentColor"
          />
        </svg>
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { getBoundsByIconSize, getSVGViewboxByIconSize, IconSize } from '@PLAYER/player/components/icons/icon-types';
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>
