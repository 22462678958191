import useState from '@PLAYER/player/modules/global/use-state';
import { QualityLevel } from '@PLAYER/player/modules/interfaces/hls';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const MANIFEST_STORE_NAME = 'manifest';

const useManifestStore = defineStore(MANIFEST_STORE_NAME, () => {
  const currentHlsInitializerViewKey = ref(0);

  const manifestUrl = ref<string | undefined>(undefined);
  const setManifestUrl = (value: string | undefined) => {
    manifestUrl.value = value;

    currentHlsInitializerViewKey.value += 1;
  };

  const [autoplayManifestStartOffset, setAutoplayManifestStartOffset] = useState<boolean>(false);
  const [manifestStartOffset, setManifestStartOffset] = useState<number>(0);
  const [realSelectedQualityLevel, setRealSelectedQualityLevel] = useState<number>(-1);
  const [userSelectedQualityLevel, setUserSelectedQualityLevel] = useState<number>(-1);
  const [customManifestTotalDuration, setCustomManifestTotalDuration] = useState<number>(0);
  const [customManifestFragmentStart, setCustomManifestFragmentStart] = useState(0);
  const [customManifestVideoCurrentTime, setCustomManifestVideoCurrentTime] = useState(0);

  const [qualityLevels, setQualityLevels] = useState<QualityLevel[]>([]);
  const [isAutoQualityLevelMode, setIsAutoQualityLevelMode] = useState<boolean>(true);

  const hasQualityLevels = computed(() => qualityLevels.value.length > 0);
  const currentQualityLevelDetail = computed(() =>
    qualityLevels.value.find((level) => level.id === realSelectedQualityLevel.value),
  );

  const hasSubtitles = computed(() => false);

  const setCommonQualityLevels = (quality: number) => {
    setRealSelectedQualityLevel(quality);
    setUserSelectedQualityLevel(quality);
  };

  return {
    customManifestVideoCurrentTime,
    userSelectedQualityLevel,
    setCommonQualityLevels,
    setUserSelectedQualityLevel,
    setCustomManifestVideoCurrentTime,
    manifestStartOffset,
    setManifestStartOffset,
    customManifestFragmentStart,
    setCustomManifestFragmentStart,
    customManifestTotalDuration,
    setCustomManifestTotalDuration,
    autoplayManifestStartOffset,
    setAutoplayManifestStartOffset,
    currentHlsInitializerViewKey,
    manifestUrl,
    setManifestUrl,
    hasSubtitles,
    hasQualityLevels,
    currentQualityLevelDetail,
    isAutoQualityLevelMode,
    setIsAutoQualityLevelMode,
    realSelectedQualityLevel,
    setRealSelectedQualityLevel,
    qualityLevels,
    setQualityLevels,
  };
});

export default useManifestStore;
