<template>
  <div :class="$style.container">
    <div v-for="(column, id) in resolvedItems" :key="`column-${id}`" :class="$style.column">
      <div v-for="(item, id) in column" :key="`item-${id}`" :class="$style.item">
        <slot :item="item" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toPercent } from '@package/sdk/src/core';
import { computed } from 'vue';

interface Props {
  items: unknown[];
  columnNumber: number;
}

const props = withDefaults(defineProps<Props>(), {
  columnNumber: 1,
});

const columnWidthPercent = computed(() => toPercent(100 / props.columnNumber));

const resolvedItems = computed(() => {
  if (!props.items.length) {
    return [];
  }

  const result: (typeof props.items)[] = [];

  for (let i = 0; i < props.items.length; i++) {
    const item = props.items[i];
    const column = i % props.columnNumber;

    if (!result[column]) {
      result[column] = [];
    }

    result[column].push(item);
  }

  return result;
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.container {
  display: flex;
  width: 100%;

  flex-wrap: nowrap;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: v-bind(columnWidthPercent);
}

.item {
  margin: {
    bottom: adjust.adjustPx(12px);
    left: adjust.adjustPx(6px);
    right: adjust.adjustPx(6px);
  }
}
</style>
