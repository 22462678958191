import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { AnyFunction } from '@package/sdk/src/core/structures/common';
import type { AxiosStatic } from 'axios';

export interface InternalRef<T = any> {
  value: T;
}

export type ComputedLikeFunction = <T = any>(expression: () => T) => InternalRef<T>;

export type RefLikeFunction = <T = any>(value?: T) => InternalRef<T>;

export interface CompositionApi {
  vueCompositionApi?: {
    install: (Vue: any) => void;
  };
  onActivated: VoidFunction;
  onDeactivated: VoidFunction;
  computed: ComputedLikeFunction;
  ref: RefLikeFunction;
  onMounted: VoidFunction;
  nextTick: VoidFunction;
  vue: {
    use: AnyFunction;
  };
  vuex: any;
}

export interface Router {
  router: any;
  routes: any[];
}

// @ts-expect-error
const defaultCompositionApi: CompositionApi = {
  ref: (val: any) => ({ value: val }),
  onMounted: (_: AnyFunction) => {},
  onBeforeUnmount: (_: AnyFunction) => {},
  onActivated: (_: AnyFunction) => {},
  onDeactivated: (_: AnyFunction) => {},
  computed: (expression: AnyFunction) => ({ value: expression() }),
  nextTick: (_: AnyFunction) => {},
  vue: {
    use: () => {},
  },
  vuex: {},
} as CompositionApi;

export class GlobalSettings {
  private $axios: AxiosStatic | undefined;

  constructor(
    private readonly $vue: CompositionApi = defaultCompositionApi as CompositionApi,
    private readonly $vueRouter: Router = {} as Router,
    private $axiosIsCancel: any = {},
    private $cryptoJs: any = {},
    private $jwt: any = {},
    private $dateFns: any = {},
    private $qrcode: any = {},
    private $uuidv4: any = {},
    private $inject: any = {},
    private $onUnmounted: any = {},
    private $sentry: any = {},
    private $onBeforeUnmount: (_: AnyFunction) => void = () => {},
    private $vueVersion: 'vue2' | 'vue3' = 'vue3',
  ) {}

  get vueVersion() {
    return this.$vueVersion;
  }

  set vueVersion(version: 'vue2' | 'vue3') {
    this.$vueVersion = version;
  }

  get computed() {
    return this.$vue.computed;
  }

  set computed(value) {
    this.$vue.computed = value;
  }

  get ref(): RefLikeFunction {
    return this.$vue.ref as RefLikeFunction;
  }

  set ref(value) {
    this.$vue.ref = value;
  }

  get nextTick() {
    return this.$vue.nextTick;
  }

  set nextTick(value) {
    this.$vue.nextTick = value;
  }

  get vue() {
    return this.$vue.vue;
  }

  set vue(value) {
    this.$vue.vue = value;
  }

  get vuex() {
    return this.$vue.vuex;
  }

  set vuex(value) {
    this.$vue.vuex = value;
  }

  get vueCompositionApi() {
    return this.$vue.vueCompositionApi;
  }

  set vueCompositionApi(value) {
    this.$vue.vueCompositionApi = value;
  }

  get onActivated() {
    return this.$vue.onActivated;
  }

  set onActivated(val: any) {
    this.$vue.onActivated = val;
  }

  get onDeactivated() {
    return this.$vue.onDeactivated;
  }

  set onDeactivated(val: any) {
    this.$vue.onDeactivated = val;
  }

  get onMounted() {
    return this.$vue.onMounted;
  }

  set onMounted(value: any) {
    this.$vue.onMounted = value;
  }

  get router() {
    return this.$vueRouter.router;
  }

  set router(value) {
    this.$vueRouter.router = value;
  }

  get routes() {
    return this.$vueRouter.routes;
  }

  set routes(value) {
    this.$vueRouter.routes = value;
  }

  get axios(): AxiosStatic {
    if (!this.$axios) {
      throw new UnexpectedComponentStateError('$axios');
    }

    return this.$axios;
  }

  set axios(value) {
    this.$axios = value;
  }

  get axiosIsCancel() {
    return this.$axiosIsCancel;
  }

  set axiosIsCancel(value) {
    this.$axiosIsCancel = value;
  }

  get cryptoJs() {
    return this.$cryptoJs;
  }

  set cryptoJs(value) {
    this.$cryptoJs = value;
  }

  get jwt() {
    return this.$jwt;
  }

  set jwt(value) {
    this.$jwt = value;
  }

  get dateFns() {
    return this.$dateFns;
  }

  set dateFns(value) {
    this.$dateFns = value;
  }

  get qrcode() {
    return this.$qrcode;
  }

  set qrcode(value) {
    this.$qrcode = value;
  }

  get uuidv4() {
    return this.$uuidv4;
  }

  set uuidv4(value) {
    this.$uuidv4 = value;
  }

  get inject() {
    return this.$inject;
  }

  set inject(value) {
    this.$inject = value;
  }

  get onUnmounted() {
    return this.$onUnmounted;
  }

  set onUnmounted(value) {
    this.$onUnmounted = value;
  }

  get sentry() {
    return this.$sentry;
  }

  set sentry(value) {
    this.$sentry = value;
  }

  get onBeforeUnmount() {
    return this.$onBeforeUnmount;
  }

  set onBeforeUnmount(value) {
    this.$onBeforeUnmount = value;
  }
}

export const globalSettings = new GlobalSettings();

declare global {
  interface Window {
    globalApp: GlobalSettings;
  }
}

const isClient = typeof window !== 'undefined';

if (isClient) {
  window.globalApp = globalSettings;
}
