// @ts-nocheck

import ru from '../../../../../translations/smarttv/ru.json';

/**
 * @see https://datatracker.ietf.org/doc/html/rfc5646
 */
export enum AppLanguage {
  RU = 'ru',
  EN = 'en',
  AM = 'am',
}

export enum AppVariation {
  Ru = 'ru',
  Am = 'am',
}

export enum AppCurrency {
  Rub = '₽',
  Dram = '֏',
}

export function createCommonLocalizeDictionary(lang: AppLanguage) {
  if (lang === AppLanguage.RU) {
    return ru;
  }

  if (lang === AppLanguage.EN) {
    return {} as unknown as typeof ru;
  }

  return {};
}

export const translations = createCommonLocalizeDictionary(AppLanguage.RU);
type TranslationKey = any;

export const AppLanguageManager = new (class {
  public currentLang = AppLanguage.RU;
  public _currentCurrency = AppCurrency.Rub;

  public translations = createCommonLocalizeDictionary(this.currentLang);

  public get currency(): AppCurrency {
    return this._currentCurrency;
  }

  public setTranslations(lang: AppLanguage) {
    this.currentLang = lang;

    this.translations = createCommonLocalizeDictionary(this.currentLang);
  }

  public setCurrency(currency: AppCurrency) {
    this._currentCurrency = currency;
  }

  public setLang(lang: AppLanguage) {
    this.currentLang = lang;

    this.setTranslations(lang);
  }
})();

const getValue = (keyString: string) => {
  const keys = keyString?.split('.');

  let cur = translations;

  keys?.forEach((key) => {
    cur = cur?.[key];
  });
  return cur as unknown as string;
};

export function t(key: TranslationKey, params?: { [s: string]: string | number } | undefined): string {
  let translation = AppLanguageManager.translations[key];

  if (!translation) {
    return key;
  }

  if (!params) {
    return translation;
  }

  Object.entries({ ...params, appCurrency: AppLanguageManager.currency }).forEach(([keyToReplace, value = '']) => {
    translation = translation
      .replace(`{{ ${keyToReplace} }}`, value.toString())
      .replace(`{{${keyToReplace}}}`, value.toString());
  });

  return translation;
}

export const translate = t;

/**
 * Функция для получения правильного ключа для числительных
 *
 * @param key
 * @param count
 * @returns {string}
 */
function pluralize(key: TranslationKey, count: number): TranslationKey {
  let n = Math.abs(count);

  n = n % 100;
  if (n >= 5 && n <= 20) {
    return (key + '5') as TranslationKey;
  }

  n = n % 10;
  if (n === 1) {
    return (key + '1') as TranslationKey;
  }

  if (n >= 2 && n <= 4) {
    return (key + '2') as TranslationKey;
  }

  return (key + '5') as TranslationKey;
}

/**
 * Перевод ключей с числительными
 *
 * {
 *   away1: 'Вас не было {{ value }} день',
 *   away2: 'Вас не было {{ value }} дня',
 *   away5: 'Вас не было {{ value }} дней'
 * }
 * @example tPlural('away', 2, { value: 32 }) -> 'Вас не было 32 дня'
 *
 * @param key
 * @param count
 * @param params
 * @returns {string}
 */
export function tPlural(key: string, count: number, params?: Record<string, string | number>): string | string[] {
  const pluralKey = pluralize(key as TranslationKey, count);

  return t(pluralKey, params);
}

export const i18n = {
  install: (app: any) => {
    if (app.config?.globalProperties) {
      app.config.globalProperties.$t = t;
      app.config.globalProperties.$tp = tPlural;
    } else {
      app.prototype.$t = t;
      app.prototype.$tp = tPlural;
    }
  },
};
