<template>
  <div data-hideable :class="{ [$style.hidden]: !isControlsPlaybackShown }">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';

const { isControlsPlaybackShown } = useVideoControlsVariables();
</script>

<style lang="scss" module>
.hidden {
  display: none !important;
}
</style>
