import { ApplicationError } from '@package/sdk/src/core';
import type { ErrorData } from 'hls.js';

const toString = (data?: ErrorData) => {
  return `${data?.event}:${data?.details} - ${data?.error?.message}`;
};

export enum HlsPlayerErrorLevel {
  Warning,
  Critical,
}

export class HlsRuntimeError extends ApplicationError {
  public readonly name = 'HlsRuntimeError';

  constructor(data: ErrorData) {
    super(toString(data));
  }

  public get level(): HlsPlayerErrorLevel {
    return HlsPlayerErrorLevel.Critical;
  }

  public toJSON(): Record<string, any> {
    return {
      level: HlsPlayerErrorLevel.Critical,
    };
  }
}
