export interface ThrottleOptions {
  timeout: number;
  /** Call callback before timeout */
  immediate?: boolean;
}
export const throttleWithImmediate = <P>(callback: (params: P) => void, options: ThrottleOptions) => {
  const { timeout, immediate } = options;
  let timer: number | undefined;

  return (params: P) => {
    if (!timer) {
      if (immediate) {
        callback(params);
      }

      timer = window.setTimeout(() => {
        if (!immediate) {
          callback(params);
        }

        timer = undefined;
      }, timeout);
    }
  };
};
