import { getSafeTizenDeviceInfo } from './tizen/device';
import { getSafeWebOSDeviceInfo } from './webos/device';

const isClient = typeof window !== 'undefined';

const REAL_DEVICE_USER_AGENT = isClient ? window.navigator.userAgent : '';

export const isWebOS = isClient ? 'PalmSystem' in window : false;
export const isTizenOS = isClient ? /Tizen/.test(REAL_DEVICE_USER_AGENT) : false;

export const isServiceWorkerModeActivated = (): boolean => {
  try {
    return self.name.length > 0;
  } catch (error) {
    return false;
  }
};

interface SmartTVInformation {
  osName: 'WebOS' | 'TizenOS' | 'Web';
  vendorName: 'Samsung' | 'LG' | 'Web';
  modelName: string;
  modelCode: string;
}

export async function getSmartTVInformation(): Promise<SmartTVInformation> {
  const isServiceWorker = isServiceWorkerModeActivated();

  const osName: SmartTVInformation['osName'] = (() => {
    if (isServiceWorker) {
      return 'Web';
    }

    if (isTizenOS) {
      return 'TizenOS';
    }

    if (isWebOS) {
      return 'WebOS';
    }

    return 'Web';
  })();

  const vendorName: SmartTVInformation['vendorName'] = (() => {
    if (isServiceWorker) {
      return 'Web';
    }

    if (isTizenOS) {
      return 'Samsung';
    }

    if (isWebOS) {
      return 'LG';
    }

    return 'Web';
  })();

  let modelName: SmartTVInformation['modelName'] = 'Desktop APP';
  let modelCode: SmartTVInformation['modelCode'] = 'Unknown';

  const requestMetadata = false;

  if (isWebOS && !isServiceWorker && requestMetadata) {
    const deviceInfo = await getSafeWebOSDeviceInfo();

    if (deviceInfo?.modelName) {
      modelName = deviceInfo.modelName;
    }
  }

  if (isTizenOS && !isServiceWorker && requestMetadata) {
    const deviceInfo = getSafeTizenDeviceInfo();

    modelName = deviceInfo.modelName;
    modelCode = deviceInfo.modelCode;
  }

  return {
    osName,
    vendorName,
    modelCode,
    modelName,
  };
}
