import type { Moment } from '@package/sdk/src/api';
import { isDefined } from '@package/sdk/src/core';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

export default function useContentLinkedKinom() {
  const videoConfig = useVideoConfig();

  const linkedKinom = computed(() => videoConfig['content.linkedKinom'] as Moment);
  const hasLinkedKinom = computed(() => isDefined(linkedKinom.value));

  const linkedKinomStartOffset = computed(() => linkedKinom.value?.startOffset);
  const linkedKinomDuration = computed(() => linkedKinom.value?.duration);

  return {
    linkedKinom,
    linkedKinomStartOffset,
    linkedKinomDuration,
    hasLinkedKinom,
  };
}
