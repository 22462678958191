import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

export default function useExperimentalFeature() {
  const videoConfig = useVideoConfig();

  const isLazyLoadingInteractionEnabled = computed(
    () => videoConfig['experimental.feature.lazyInteractionLoading'] ?? false,
  );
  const isViewportIntersectionPlayback = computed(
    () => videoConfig['experimental.feature.viewportIntersectionPlayback'] ?? false,
  );

  const isAmbientModeEnabled = computed(() => videoConfig['experimental.feature.ambientMode'] ?? false);
  const isChromecastEnabled = computed(() => true);

  const isTizenAVPlayerAPIEnabled = computed(() => videoConfig['experimental.feature.tizenAVPlayerAPI'] ?? false);
  const isTataudioAPIEnabled = computed(() => videoConfig['experimental.feature.tataudioPlayerAPI'] ?? false);
  const isPlaybackMyMouseEnabled = computed(() => videoConfig['experimental.feature.playbackMyMouse'] ?? false);
  const isHlsJsAPIEnabled = computed(() => videoConfig['experimental.feature.hlsJsPlayerAPI'] ?? false);
  const isShakaPlayerAPIEnabled = computed(() => videoConfig['experimental.feature.shakaPlayerAPI'] ?? false);

  const isVRVideo = computed(() => videoConfig['experimental.feature.vrVideoCameraMode']);

  return {
    isTataudioAPIEnabled,
    isTizenAVPlayerAPIEnabled,
    isPlaybackMyMouseEnabled,
    isShakaPlayerAPIEnabled,
    isAmbientModeEnabled,
    isVRVideo,
    isLazyLoadingInteractionEnabled,
    isViewportIntersectionPlayback,
    isHlsJsAPIEnabled,
    isChromecastEnabled,
  };
}
