import type { Season } from '@package/sdk/src/api';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

export default function useMediaSeasons() {
  const videoConfig = useVideoConfig();

  const _seasons = computed<Season[]>(() => (videoConfig['content.playlists'] as unknown as Season[]) || []);
  const seasons = computed(() => _seasons.value.sort((a, b) => a.number - b.number));

  const isSeasonsPresent = computed(() => Boolean(seasons.value.length));

  const isNextContentViewShown = computed(() => isSeasonsPresent.value);

  return {
    isNextContentViewShown,
    isSeasonsPresent,
    seasons,
  };
}
