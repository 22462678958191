import { deviceService, globalSettings } from '../services';

export default function useTransitionName() {
  const isTransitionEnabled = deviceService.getFeatureState('transitionComponent');

  const transitionName = globalSettings.computed(() => {
    if (!isTransitionEnabled) {
      return '';
    }

    return 'fade';
  });

  return {
    transitionName,
  };
}
