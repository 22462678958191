import { AvailableContentType } from '@package/sdk/src/api/content/types/content-type';
import { Media } from '@package/sdk/src/api/content/types/media';
import { Moment } from '@package/sdk/src/api/content/types/moment';
import { Disposable } from '@package/sdk/src/core/lifecycle/disposable';
import { LRUCache } from '@package/sdk/src/core/structures/lru-cache';

const transformMomentToMedia = (moment: Moment): Media => {
  return {
    accessKind: 'subscription',
    genres: [],
    title: moment.contentTitle,
    slug: moment.contentSlug || moment.contentId,
    id: moment.contentId,
    poster: moment.contentPoster || '',
    contentType: moment.contentType,
    countries: [],
    directors: [],
    duration: 0,
    imdbRating: 0,
    canCreateUGC: false,
    releaseDate: '',
    inUserCollection: moment.inUserCollection || false,
    imdbRatingVoteCount: 0,
    kinopoiskRating: 0,
    kinopoiskRatingVoteCount: 0,
    description: '',
    year: moment.contentYear as number,
    background: moment.preview || '',
    actors: [],
    ageLimit: 0,
    smokingAdText: true,
    availability: moment.contentAvailability || AvailableContentType.AVAILABLE,
  };
};

export class ContentCacheManager extends Disposable {
  private readonly controller: LRUCache<string, Media> = new LRUCache<string, Media>(10, 1);

  constructor() {
    super();
  }

  public clear() {
    this.controller.clear();
  }

  public addToCache(content: Media | Moment, serialId?: string): void {
    const moment = content as Moment;

    if (moment.contentType && moment.contentSlug && moment.contentId) {
      const transformedMedia = transformMomentToMedia(moment);

      return this.controller.set(serialId ?? transformedMedia.id, transformedMedia);
    }

    const media = content as Media;

    this.controller.set(serialId ?? media.id, media);
  }

  public getFromCache(id: string) {
    return this.controller.get(id);
  }
}
