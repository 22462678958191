import { isIOS } from '@PLAYER/player/base/dom';
import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

export default function useDVR() {
  const videoConfig = useVideoConfig();

  const isDVR = computed(() => {
    // в iOS плеере DVR работает неадекватно (не присылает нам duration корректный).
    if (isIOS) {
      return false;
    }

    return videoConfig['video.isDVR'];
  });

  return {
    isDVR,
  };
}
