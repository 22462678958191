<template>
  <section :class="$style.help">
    <help-contacts-text-block @vue:mounted="onVNodeMounted" />
    <help-contacts-qr-block v-if="isVNodeMounted" />
  </section>
</template>

<script setup lang="ts">
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';

import HelpContactsQrBlock from '@/pages/settings/components/HelpContactsQrBlock.vue';
import HelpContactsTextBlock from '@/pages/settings/components/HelpContactsTextBlock.vue';

const { isVNodeMounted, onVNodeMounted } = useVNodeMounted();
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.help {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: adjust.adjustPx(394px) !important;
}
</style>
