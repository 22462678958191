const isCSSVariableName = (name: string): boolean => {
  if (name && name.length > 2 && name[0] === '-' && name[1] === '-') {
    return true;
  }

  return false;
};

const getCSSVariableName = (name: string): string => {
  if (isCSSVariableName(name)) {
    return name;
  }

  return '--' + name;
};

export const setCSSVariable = (name: string, value: string, element = document.documentElement): void => {
  const v = getCSSVariableName(name);
  element.style.setProperty(v, value);
};
