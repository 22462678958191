import { Disposable, EventEmitter } from '@package/sdk/src/core';

import type { DeviceService } from '../device/device-service';
import { OperationSystem } from '../device/device-types';

interface LifecycleEventMap {
  'will-shutdown': void;
}

const isClient = typeof window !== 'undefined';

export class LifecycleService extends Disposable {
  private emitter: EventEmitter<LifecycleEventMap> = new EventEmitter<LifecycleEventMap>();

  private isEnabled = true;

  constructor(private readonly deviceService: DeviceService) {
    super();

    if (isClient) {
      this.registerListeners();
    }
  }

  public onWillShutdown(callback: VoidFunction) {
    return this.emitter.on('will-shutdown', callback);
  }

  private registerListeners() {
    if (!this.isEnabled) {
      return;
    }

    const isDesktop = this.deviceService.os === OperationSystem.Desktop;

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;

      this.emitter.emit('will-shutdown');
    };

    if (!isDesktop) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }
  }
}
