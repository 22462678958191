import { ProcessingMapper } from '../base/processing-mapper';
import { ApiChannelPlaylist } from './channel-types';
import { ChannelPlaylist } from './types/channel';

export class ChannelPlaylistMapper {
  static mapMany(items: ApiChannelPlaylist[]): ChannelPlaylist[] {
    return ProcessingMapper.process(items.map(ChannelPlaylistMapper.map));
  }

  static map({ url, dvr_url, started_at }: ApiChannelPlaylist): ChannelPlaylist {
    return ProcessingMapper.process({
      url: url || '',
      dvrUrl: dvr_url || '',
      startedAt: started_at,
    });
  }
}
