import { PlayerInstanceConstructorOptions } from '@PLAYER/player/modules/instance/interfaces';
import { CustomComponentMap, VijuPlayer } from '@PLAYER/player/modules/instance/player';
import SmartTvOnboardingPopupControls from '@PLAYER/player/versions/smart/components/popups/SmartTvOnboardingPopupControls.vue';
import { App } from 'vue';

import Root from './components/Root.vue';

class SmartTvVijuPlayer extends VijuPlayer {
  constructor(options: PlayerInstanceConstructorOptions, components?: CustomComponentMap) {
    super(options, Root as unknown as App<Element>, components);
  }

  protected registerComponents(app: App<Element>) {
    app.component('SmartTvOnboardingPopupControls', SmartTvOnboardingPopupControls);
  }
}

export { SmartTvVijuPlayer };
