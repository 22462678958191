import { ApplicationError } from '@package/sdk/src/core';
import VideoPlayerEvent from '@PLAYER/player/base/event';
import { TimeSeconds } from '@PLAYER/player/base/number';

export type ChromecastConnectedState = 'disconnected' | 'connected' | 'idle' | 'buffering' | 'playing' | string;

export class ChromecastInstanceEvent<T> extends VideoPlayerEvent<T> {
  constructor(data: T) {
    super(data);
  }
}

export enum ChromecastErrorCode {
  SourceNotProvided = 'source-not-provided',
  ChromecastIsNotAvailable = 'chromecast-is-not-available',
  CouldNotConnectWithDevice = 'couldnt-connect-with-device',
  Unknown = 'unknown',
}

export class ChromecastInstanceError extends ApplicationError {
  public readonly name = 'ChromecastInstanceError';
  public readonly code: ChromecastErrorCode;
  public readonly originalChromecastError?: any;

  constructor(options: { code: ChromecastErrorCode; originalChromecastError?: any; message?: string }) {
    super();

    this.message = options.message || '';
    this.code = options.code;
    this.originalChromecastError = options.originalChromecastError;
  }

  public toJSON(): Record<string, any> {
    const { code, originalChromecastError = {} } = this;

    return {
      code,
      originalChromecastError,
    };
  }
}

export interface ChromecastDeviceNameChangeEvent {
  deviceName: string;
}

export interface ChromecastStateChangeEvent {
  state: ChromecastConnectedState;
}

export interface ChromecastTimeupdateEvent {
  currentTime: TimeSeconds;
}

export interface ChromecastVolumechangeEvent {
  volume: number;
}

export interface ChromecastEventMap {
  devicenamechange: ChromecastInstanceEvent<ChromecastDeviceNameChangeEvent>;
  statechange: ChromecastInstanceEvent<ChromecastStateChangeEvent>;
  timeupdate: ChromecastInstanceEvent<ChromecastTimeupdateEvent>;
  volumechange: ChromecastInstanceEvent<ChromecastVolumechangeEvent>;

  pause: ChromecastInstanceEvent<undefined>;
  mute: ChromecastInstanceEvent<undefined>;
  unmute: ChromecastInstanceEvent<undefined>;

  disconnect: ChromecastInstanceEvent<undefined>;
  connect: ChromecastInstanceEvent<undefined>;

  subtitlechange: ChromecastInstanceEvent<undefined>;
  error: Error;
  available: ChromecastInstanceEvent<undefined>;

  buffering: ChromecastInstanceEvent<undefined>;
  playing: ChromecastInstanceEvent<undefined>;
  ended: ChromecastInstanceEvent<undefined>;
}
