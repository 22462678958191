<template></template>

<script lang="ts" setup>
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { TimeSeconds } from '@PLAYER/player/base/number';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onUnmounted, watch } from 'vue';

const { exactDisplayedCurrentTime, duration } = storeToRefs(useVideoUIStore());
const externalEventBus = useSafeExternalEventBus();
const videoEventHandler = useSafeVideoEventHandler();

const layoutStore = useLayoutStore();
const { isUserWatchedFreeThresholdTime } = storeToRefs(layoutStore);
const { isLive } = useProjector();

const onPlay = () => {
  externalEventBus.emit('play');
};

const onPlaying = () => {
  externalEventBus.emit('playing');
};

const onLoadedMetadata = () => {
  externalEventBus.emit('loadedmetadata');
};

const onPause = () => {
  externalEventBus.emit('pause');
};

const onSeeked = () => externalEventBus.emit('seeked');

const onEnd = () => {
  externalEventBus.emit('ended');
};

videoEventHandler.addEventListener('seeked', onSeeked);
videoEventHandler.addEventListener('play', onPlay);
videoEventHandler.addEventListener('pause', onPause);
videoEventHandler.addEventListener('ended', onEnd);
videoEventHandler.addEventListener('playing', onPlaying);
videoEventHandler.addEventListener('loadedmetadata', onLoadedMetadata);

onBeforeUnmount(() => {
  videoEventHandler.removeEventListener('seeked', onSeeked);
  videoEventHandler.removeEventListener('playing', onPlaying);
  videoEventHandler.removeEventListener('loadedmetadata', onLoadedMetadata);
  videoEventHandler.removeEventListener('play', onPlay);
  videoEventHandler.removeEventListener('pause', onPause);
  videoEventHandler.removeEventListener('ended', onEnd);
});

const checkFreeContentShowTooltipOffset = (offset: TimeSeconds) => {
  if (isUserWatchedFreeThresholdTime.value) {
    return;
  }

  const thresholdValueFreeContent =
    duration.value * ConstantsConfigPlayer.getProperty('freeContentThresholdWatchTimeFloat');

  if (offset >= thresholdValueFreeContent) {
    externalEventBus.emit('media-free-content-threshold-activated', new VideoPlayerExternalEvent(undefined));
    layoutStore.setIsUserWatchedFreeThresholdTime(true);
  }
};

const onCurrentTimeUpdated = (offset: TimeSeconds) => {
  if (!offset || isLive.value || !duration.value) {
    return;
  }

  checkFreeContentShowTooltipOffset(offset);

  const event = new VideoPlayerExternalEvent({ offset, isBeforeEndOfVideo: false });

  externalEventBus.emit('media-watching-progress-updated', event);
};

watch(exactDisplayedCurrentTime, onCurrentTimeUpdated);

onUnmounted(() => {
  layoutStore.setIsUserWatchedFreeThresholdTime(false);
});
</script>
