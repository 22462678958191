import { ProcessingMapper } from '../base/processing-mapper';
import { CountryMapper } from '../content/country';
import { GenreMapper } from '../content/genre';
import { ApiContentRecommendationsRandom, ContentRecommendationsRandom } from './recommendation-types';

export class RecommendationsRandomMapper {
  static map({
    content_type,
    age_limit,
    season_count,
    countries,
    genres,
    ...rest
  }: ApiContentRecommendationsRandom): ContentRecommendationsRandom {
    return ProcessingMapper.process({
      ...rest,
      contentType: content_type,
      ageLimit: age_limit,
      seasonCount: season_count,
      countries: countries ? CountryMapper.mapMany(countries) : [],
      genres: genres ? GenreMapper.mapMany(genres) : [],
    });
  }
}
