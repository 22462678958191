export function getTizenVersion(userAgent = navigator.userAgent) {
  try {
    const agents = userAgent.split(' ');

    for (let i = 0; i < agents.length; i++) {
      if (agents[i].substring(0, 5) === 'Tizen') {
        return parseFloat(agents[i + 1]);
      }
    }

    return 0;
  } catch (error) {
    return 0;
  }
}

if (import.meta.vitest) {
  const userAgents = {
    '7.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 7.0) AppleWebKit/537.36 (KHTML, like Gecko) 94.0.4606.31/7.0 TV Safari/537.36',
    '6.5':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 6.5) AppleWebKit/537.36 (KHTML, like Gecko) 85.0.4183.93/6.5 TV Safari/537.36',
    '6.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 6.0) AppleWebKit/537.36 (KHTML, like Gecko) 76.0.3809.146/6.0 TV Safari/537.36',
    '5.5':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 5.5) AppleWebKit/537.36 (KHTML, like Gecko) 69.0.3497.106.1/5.5 TV Safari/537.36',
    '5.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 5.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/5.0 TV Safari/537.36',
    '4.0':
      'Mozilla/5.0 (SMART-TV; LINUX; Tizen 4.0) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 TV Safari/537.36',
    '3.0': 'Mozilla/5.0 (SMART-TV; LINUX; Tizen 3.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/3.0 TV Safari/538.1',
    '2.4':
      'Mozilla/5.0 (SMART-TV; Linux; Tizen 2.4.0) AppleWebKit/538.1 (KHTML, like Gecko) Version/2.4.0 TV Safari/538.1',
    '2.3':
      'Mozilla/5.0 (SMART-TV; Linux; Tizen 2.2) AppleWebkit/538.1 (KHTML, like Gecko) SamsungBrowser/1.0 TV Safari/538.1',
  };

  const { it, expect } = import.meta.vitest;

  it('Tizen 7.0 return 7', () => {
    expect(getTizenVersion(userAgents['7.0'])).toBe(7);
  });

  it('Tizen 6.0 return 6', () => {
    expect(getTizenVersion(userAgents['6.0'])).toBe(6);
  });

  it('Tizen 6.5 return 6.5', () => {
    expect(getTizenVersion(userAgents['6.5'])).toBe(6.5);
  });

  it('Tizen 6.0 return 6', () => {
    expect(getTizenVersion(userAgents['6.0'])).toBe(6.0);
  });

  it('Tizen 5.5 return 5.5', () => {
    expect(getTizenVersion(userAgents['5.5'])).toBe(5.5);
  });

  it('Tizen 5.0 return 5', () => {
    expect(getTizenVersion(userAgents['5.0'])).toBe(5);
  });

  it('Tizen 4.0 return 4', () => {
    expect(getTizenVersion(userAgents['4.0'])).toBe(4);
  });

  it('Tizen 3.0 return 3', () => {
    expect(getTizenVersion(userAgents['3.0'])).toBe(3);
  });

  it('Tizen 2.4 return 2.4', () => {
    expect(getTizenVersion(userAgents['2.4'])).toBe(2.4);
  });

  it('Tizen 2.3 return 2.3', () => {
    expect(getTizenVersion(userAgents['2.3'])).toBe(2.2);
  });

  it('None Tizen platform return 0', () => {
    expect(getTizenVersion('broken user agent')).toBe(0);
  });
}
