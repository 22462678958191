<template>
  <div :class="$style.iconWrapper" tabindex="0">
    <svg
      :class="$style.iconSvg"
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :class="$style.iconPath"
        d="M17.4541 9.66602L9.49956 1.33268L1.54501 9.66602"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style module lang="scss">
@import '@package/ui/src/styles/adjust-smart-px';

.iconWrapper {
  max-width: 100%;
  max-height: 100%;
}

.iconSvg {
  width: adjustPx(25px);
  height: adjustPx(25px);
}

.iconPath {
  stroke: var(--color-notheme-text-primary);
  stroke-width: 2;
  opacity: 0.4;
}
</style>
