import { toPercent, toPixel } from '@package/sdk/src/core';
import {
  isChromium,
  isFirefox,
  isIOS,
  isMobile,
  isSafari,
  isServer,
  isTizenOS,
  isWebOS,
} from '@PLAYER/player/base/dom';
import { t } from '@PLAYER/player/modules/localization/translate';
import { getTestElementIdentifier } from '@PLAYER/player/modules/test/get-test-element-identifier';
import { isClient } from '@vueuse/core';
import { createPinia } from 'pinia';
import { App } from 'vue';

export const globalProperties = {
  $t: t,
  $toPixel: toPixel,
  $toPercent: toPercent,
  $isMobile: isMobile,
  $isFirefox: isFirefox,
  $isDesktop: !isMobile,
  $iOS: isIOS,
  $isWebOS: isWebOS,
  $isTizenOS: isTizenOS,
  $isSafari: isSafari,
  $isChrome: isChromium,
  $isClient: isClient,
  $isServer: isServer,
  $isNativeTitleTooltipShown: false,
  getTestElementIdentifier,
};

export default function patchVueApp(app: App<unknown>, props?: Partial<typeof globalProperties>) {
  Object.assign(app.config.globalProperties, globalProperties, props);

  const pinia = createPinia();
  app.use(pinia);
}
