<template>
  <UIModal>
    <section ref="el" :class="$style.modal">
      <my-channel-modal-images v-if="isVNodeMounted" />
      <my-channel-modal-actions @finish="onFinish" @vue:mounted="onVNodeMounted" />
    </section>
  </UIModal>
</template>

<script setup lang="ts">
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import { customEventsService, FocusKeys } from '@SMART/index';
import { onMounted, provide } from 'vue';

import UIModal from '@/components/modal/UIModal.vue';
import MyChannelModalActions from '@/components/my-channel-modal/MyChannelModalActions.vue';
import MyChannelModalImages from '@/components/my-channel-modal/MyChannelModalImages.vue';

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.MY_CHANNEL_MODAL,
  isFocusBoundary: true,
  saveLastFocusedChild: true,
  focusBoundaryDirections: ['right'],
});

const { isVNodeMounted, onVNodeMounted } = useVNodeMounted({ withTimeout: true, timeout: 750 });

const emit = defineEmits<{
  (e: 'finish'): void;
}>();

const onFinish = () => {
  emit('finish');
};

provide('parentFocusKey', focusKey.value);

onMounted(() => {
  focusSelf();

  customEventsService.setOnPressBackCallback(onFinish, true);
});
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: adjustPx(206px) adjustPx(48px) adjustPx(206px) 0px;
  background: var(--color-bg-primary);
}
</style>
