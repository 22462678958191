import { isDefined } from '@package/sdk/src/core/std/types';
import { scrollTo } from 'scroll-polyfill';

export function scrollToElement(element: HTMLElement, options: ScrollToOptions) {
  if (isDefined(element.scrollTo)) {
    return element.scrollTo(options);
  }

  return scrollTo(element, options);
}
