<template>
  <root-video-z-index-layer :class="$style.kinomPreview" fullscreen>
    <app-img
      v-if="content.preview"
      :image-class="$style.image"
      :src="getCDNLink(content.preview, $isMobile ? 360 : 1200)"
      :alt="content.title"
    />
  </root-video-z-index-layer>
</template>

<script setup lang="ts">
import useCDNImage from '@package/content-utils/src/code/use-cdn-image';
import AppImg from '@package/ui/src/components/AppImg.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';

const { content } = useContentInformation();
const { getCDNLink } = useCDNImage();
</script>

<style module lang="scss">
.kinomPreview {
  z-index: var(--z-index-kinom-preview-layer);
  background-color: var(--color-notheme-bg-banner-button-pressed);
}

.image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: var(--g-border-round-24);
  transform: var(--g-player-default-transform-scale);
  user-select: none;
  object-fit: contain;
}
</style>
