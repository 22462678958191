// @ts-nocheck
import { isFunction } from '@package/sdk/src/core';

import { globalSettings, InternalRef } from '../../services/global-settings';
import { InitializedModuleStore } from '../index';

export type RefStoreModule<State, Getters> = {
  [property in keyof (State & Getters)]: InternalRef<(State & Getters)[property]>;
};

export const storeToRefs = <State, Getters, Actions>(
  store: InitializedModuleStore<State, Getters, Actions>,
  computed: Function = globalSettings.computed,
): RefStoreModule<State, Getters> => {
  return Object.keys(store).reduce((result, key) => {
    if (isFunction(store[key])) {
      // mutation or action, we can't convert it to ref
      return {
        ...result,
      };
    }

    const storeRef = computed({
      get: () => store[key]?.value,
      set: (value: any) => store.$patch({ [key]: value }),
    });

    return {
      ...result,
      [key]: storeRef,
    };
  }, {});
};
