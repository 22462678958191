import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { inject } from 'vue';

export default function usePlatform() {
  const { getDefault } = useDefaultProvide();

  const isWeb = inject<boolean>('platform.web', getDefault('platform.web'));
  const isSmartTV = inject<boolean>('platform.smart', getDefault('platform.smart'));
  const isEmbed = inject<boolean>('platform.embed', getDefault('platform.embed'));

  return {
    isWeb,
    isSmartTV,
    isEmbed,
  };
}
