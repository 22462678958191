import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { VijuPlayer } from '@PLAYER/player/modules/instance/player';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { inject } from 'vue';

const injectKey = 'trackedPlayers';

export default function useTrackedPlayers() {
  const { getDefault } = useDefaultProvide();

  const trackedPlayers = inject(injectKey, getDefault(injectKey)) as Set<VijuPlayer>;

  if (!trackedPlayers) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return trackedPlayers;
}
