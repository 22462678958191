// @ts-nocheck

import { isUndefinedOrNull } from '../../std/types';

const TIZEN_VENDOR_NAME = 'Samsung';

interface TizenDeviceInfo {
  vendorName: 'Samsung';
  modelName: string;
  modelCode: string;
}

export const getSafeTizenDeviceInfo = (): TizenDeviceInfo => {
  if (isUndefinedOrNull(window.webapis)) {
    return {
      vendorName: TIZEN_VENDOR_NAME,
      modelCode: 'unknown',
      modelName: 'unknown',
    };
  }

  try {
    const realModelName = window.webapis.productinfo.getRealModel();
    const realModelCode = window.webapis.productinfo.getModelCode();

    if (realModelName) {
      return {
        vendorName: TIZEN_VENDOR_NAME,
        modelName: realModelName,
        modelCode: realModelCode,
      };
    }
  } catch (error) {
    return {
      vendorName: TIZEN_VENDOR_NAME,
      modelCode: 'unknown',
      modelName: 'unknown',
    };
  }

  return {
    vendorName: TIZEN_VENDOR_NAME,
    modelName: 'unknown',
    modelCode: 'unknown',
  };
};
