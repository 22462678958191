import type { InternalRef } from '../services/global-settings';
import { globalSettings } from '../services/global-settings';

interface LazyLoadingBlocksOptions {
  readonly items: InternalRef<unknown[]>;
  startIndex?: number;
  indexSpread?: number; // кол-во элементов между текущим элементом в фокусе и концом списка
}

export function useLazyLoadingBlocks(options: LazyLoadingBlocksOptions) {
  const { items, startIndex = 0, indexSpread = 2 } = options;
  const currentFocusedIndex = globalSettings.ref(startIndex);

  const currentMountedIndex = globalSettings.computed<number>(() => currentFocusedIndex.value + indexSpread);

  const normalizedBlocks = globalSettings.computed<unknown[]>(() => {
    const res = items.value.slice(0, currentMountedIndex.value);
    return res;
  });

  const onVNodeFocused = (index: number) => {
    currentFocusedIndex.value = index + 1;
  };

  return {
    onVNodeFocused,
    normalizedBlocks,
    currentFocusedIndex,
  };
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  const items = globalSettings.ref([{ test: 1 }, { test: 2 }, { test: 3 }, { test: 4 }]);

  it('should currentFocusedIndex to be 5', () => {
    const { currentFocusedIndex, onVNodeFocused, normalizedBlocks } = useLazyLoadingBlocks({
      items,
      startIndex: 5,
    });

    expect(currentFocusedIndex.value).toBe(5);
  });

  it('should normalizedBlocks have length to equals 2', () => {
    const { normalizedBlocks } = useLazyLoadingBlocks({
      items,
      startIndex: 0,
    });

    expect(normalizedBlocks.value).toHaveLength(2);
  });
}
