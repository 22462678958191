export type IconSize = 'extra-large' | 'large' | 'medium' | 'small' | 'extra-small';

export interface IconBounds {
  width: number;
  height: number;
}

const SIZE_ICON_MAP: Record<IconSize, IconBounds> = {
  'extra-large': { width: 48, height: 48 },
  large: { width: 32, height: 32 },
  medium: { width: 24, height: 24 },
  small: { width: 16, height: 16 },
  'extra-small': { width: 12, height: 12 },
};

export const getBoundsByIconSize = (size: IconSize): IconBounds => {
  return SIZE_ICON_MAP[size];
};

export const getSVGViewboxByIconSize = (size: IconSize): string => {
  const { width, height } = getBoundsByIconSize(size);

  return `0 0 ${width} ${height}`;
};
