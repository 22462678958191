import { ApplicationError } from '@package/sdk/src/core';

export default class BadResponseError extends ApplicationError {
  public name = 'BadResponseError';

  public constructor(public response: Response) {
    super(`Get bad response when performing ${response.url} request: ${response.status}`);
  }

  public toJSON(): Record<string, any> {
    const { response } = this;

    return {
      url: response.url,
      status: response.status,
    };
  }
}
