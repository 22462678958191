import { AbstractManufacturerService } from './abstract-manufacturer-service';
import { VidaaFeatureMap } from './performance-features';

export namespace VidaaOS {
  export interface GetSupportForHDRInfo {
    HDR10: boolean;
    HLS: boolean;
    HDR10Plus: boolean;
    DOVI: boolean;
  }
}

declare global {
  function Hisense_GetDeviceId(): string;

  function Hisense_GetBrand(): string;

  function Hisense_GetModelName(): string;

  function Hisense_GetFirmWareVersion(): string;

  function Hisense_GetChipSetName(): string;

  function Hisense_GetOSVersion(): string;

  function Hisense_GetFeatureCode(): string;

  function Hisense_GetCapabilityCode(): string;

  function Hisense_Get4KSupportState(): boolean;

  function Hisense_GetSupportForHDR(): 'not-support' | 'HDR10' | 'HLG' | 'HDR10+HLG';

  function Hisense_GetSupportForHDRInfo(): VidaaOS.GetSupportForHDRInfo;

  function Hisense_GetSupportForDolbyAtmos(): boolean;

  function Hisense_GetMute(): 1 | 0; // 1 is muted, 0 - is unmuted
  function Hisense_GetVolume(): number; // 0 - 100;
  function Hisense_GetTTSEnable(): 1 | 0; // 1 is enable
  function Hisense_GetTTSLanguage(): string;

  function Hisense_GetTTSVolume(): number; // 0 - 100
  function Hisense_GetCountryCode(): string;

  function Hisense_GetMenuLanguageCode(): string;

  function Hisense_setStartBandwidth(): boolean;

  function Hisense_GetNetStatus(): boolean;

  function Hisense_GetNetType(): 'off' | 'wired' | 'wireless';

  function Hisense_GetIPAddress(): string;

  function Hisense_disableVKB(): boolean; // true if is disabled
  function Hisense_enableVKB(): boolean; // true if is disabled
  function Hisense_GetUuid(): string; // from U6

  interface Window {
    Hisense_GetDeviceId(): string;

    Hisense_GetBrand(): string;

    Hisense_GetModelName(): string;

    Hisense_GetFirmWareVersion(): string;

    Hisense_GetChipSetName(): string;

    Hisense_GetOSVersion(): string;

    Hisense_GetFeatureCode(): string;

    Hisense_GetCapabilityCode(): string;

    Hisense_Get4KSupportState(): boolean;

    Hisense_GetSupportForHDR(): 'not-support' | 'HDR10' | 'HLG' | 'HDR10+HLG';

    Hisense_GetSupportForHDRInfo(): VidaaOS.GetSupportForHDRInfo;

    Hisense_GetSupportForDolbyAtmos(): boolean;

    Hisense_GetMute(): 1 | 0; // 1 is muted, 0 - is unmuted
    Hisense_GetVolume(): number; // 0 - 100;
    Hisense_GetTTSEnable(): 1 | 0; // 1 is enable
    Hisense_GetTTSLanguage(): string;

    Hisense_GetTTSVolume(): number; // 0 - 100
    Hisense_GetCountryCode(): string;

    Hisense_GetMenuLanguageCode(): string;

    Hisense_setStartBandwidth(): boolean;

    Hisense_GetNetStatus(): boolean;

    Hisense_GetNetType(): 'off' | 'wired' | 'wireless';

    Hisense_GetIPAddress(): string;

    Hisense_disableVKB(): boolean; // true if is disabled
    Hisense_enableVKB(): boolean; // true if is disabled
    Hisense_GetUuid(): string; // from U6
  }
}

export class VidaaService extends AbstractManufacturerService {
  constructor() {
    super(new VidaaFeatureMap());
  }

  public setVolume(volume: number) {
    console.info(volume);
  }

  public getVolume(): number {
    return 0;
  }

  public resolveStartupDeeplink(): Promise<void> {
    return Promise.resolve(undefined);
  }

  public getHumanReadableDeviceName(): string {
    return `Hisense ${window.Hisense_GetModelName()}`;
  }

  public getManufacturerName(): string {
    return 'Hisense';
  }

  public getDeviceInfo(): unknown {
    return {
      appId: window.Hisense_GetDeviceId(),
      modelName: window.Hisense_GetModelName(),
    };
  }

  public init() {
    return Promise.resolve(undefined);
  }

  public exit() {
    window.close();
  }
}
