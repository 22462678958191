<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.125 6.875V3.125H9.875V6.08333L8.35255 4.39172C8.16091 4.17879 7.82991 4.17009 7.62735 4.37265L5.35355 6.64645C5.15829 6.84171 4.84171 6.84171 4.64645 6.64645L3.85355 5.85355C3.65829 5.65829 3.34171 5.65829 3.14645 5.85355L2.125 6.875ZM1.75 1.875C1.26675 1.875 0.875 2.26675 0.875 2.75V9.25C0.875 9.73325 1.26675 10.125 1.75 10.125H10.25C10.7332 10.125 11.125 9.73325 11.125 9.25V2.75C11.125 2.26675 10.7332 1.875 10.25 1.875H1.75ZM5.75 4.5C5.75 4.91421 5.41421 5.25 5 5.25C4.58579 5.25 4.25 4.91421 4.25 4.5C4.25 4.08579 4.58579 3.75 5 3.75C5.41421 3.75 5.75 4.08579 5.75 4.5Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.125 9.375V3.625H13.875V8.19444L11.5288 5.58758C11.2414 5.26819 10.7449 5.25513 10.441 5.55898L7.03033 8.96967C6.73744 9.26256 6.26256 9.26256 5.96967 8.96967L4.78033 7.78033C4.48744 7.48744 4.01256 7.48744 3.71967 7.78033L2.125 9.375ZM1.625 1.875C0.934645 1.875 0.375 2.43464 0.375 3.125V12.875C0.375 13.5654 0.934645 14.125 1.625 14.125H14.375C15.0654 14.125 15.625 13.5654 15.625 12.875V3.125C15.625 2.43464 15.0654 1.875 14.375 1.875H1.625ZM7.625 5.75C7.625 6.37132 7.12132 6.875 6.5 6.875C5.87868 6.875 5.375 6.37132 5.375 5.75C5.375 5.12868 5.87868 4.625 6.5 4.625C7.12132 4.625 7.625 5.12868 7.625 5.75Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 14V6H20V12.4444L16.7051 8.78344C16.3218 8.35759 15.6598 8.34018 15.2547 8.7453L10.7071 13.2929C10.3166 13.6834 9.68342 13.6834 9.29289 13.2929L7.70711 11.7071C7.31658 11.3166 6.68342 11.3166 6.29289 11.7071L4 14ZM3.5 4C2.67157 4 2 4.67157 2 5.5V18.5C2 19.3284 2.67157 20 3.5 20H20.5C21.3284 20 22 19.3284 22 18.5V5.5C22 4.67157 21.3284 4 20.5 4H3.5ZM11.5 9C11.5 9.82843 10.8284 10.5 10 10.5C9.17157 10.5 8.5 9.82843 8.5 9C8.5 8.17157 9.17157 7.5 10 7.5C10.8284 7.5 11.5 8.17157 11.5 9Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.5 19.0002V7.3335H27.5V16.815L22.6656 11.4434C22.1226 10.8401 21.1847 10.8154 20.6108 11.3893L14.1684 17.8318C13.6152 18.385 12.7182 18.385 12.1649 17.8318L9.9184 15.5852C9.36516 15.032 8.46817 15.032 7.91493 15.5852L4.5 19.0002ZM3.95833 4.8335C2.87678 4.8335 2 5.71027 2 6.79183V25.2085C2 26.2901 2.87677 27.1668 3.95833 27.1668H28.0417C29.1232 27.1668 30 26.2901 30 25.2085V6.79183C30 5.71027 29.1232 4.8335 28.0417 4.8335H3.95833ZM15.2917 11.7502C15.2917 12.9238 14.3403 13.8752 13.1667 13.8752C11.9931 13.8752 11.0417 12.9238 11.0417 11.7502C11.0417 10.5766 11.9931 9.62516 13.1667 9.62516C14.3403 9.62516 15.2917 10.5766 15.2917 11.7502Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'extra-large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.5 28.5V11.5H40.5V25.4444L33.4102 17.5669C32.6437 16.7152 31.3196 16.6804 30.5094 17.4906L21.4142 26.5858C20.6332 27.3668 19.3668 27.3668 18.5858 26.5858L15.4142 23.4142C14.6332 22.6332 13.3668 22.6332 12.5858 23.4142L7.5 28.5ZM7 8.5C5.61929 8.5 4.5 9.61929 4.5 11V37C4.5 38.3807 5.61929 39.5 7 39.5H41C42.3807 39.5 43.5 38.3807 43.5 37V11C43.5 9.61929 42.3807 8.5 41 8.5H7ZM23 18C23 19.6569 21.6569 21 20 21C18.3431 21 17 19.6569 17 18C17 16.3431 18.3431 15 20 15C21.6569 15 23 16.3431 23 18Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from '../components/AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from '../types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
