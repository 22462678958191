import 'whatwg-fetch';
import 'normalize.css';
import '@package/ui/src/fonts/font-face.scss';

import { isFunction } from '@package/sdk/src/core';
import { useCurrentPlayer } from '@PLAYER/player/modules/instance/player';
import { SmartTvVijuPlayer } from '@PLAYER/player/versions/smart';
import { WebVijuPlayer } from '@PLAYER/player/versions/web';
import { isClient } from '@vueuse/core';
import ResizeObserver from 'resize-observer-polyfill';

if (isClient && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

if (!isFunction(String.prototype.replaceAll)) {
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr as string);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr as string);
  };
}

export { SmartTvVijuPlayer, useCurrentPlayer, WebVijuPlayer };
