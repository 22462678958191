import { ProcessingMapper } from '../base/processing-mapper';
import { ApiSeoText } from './seo-types/seo-text';
import { SeoText } from './types/seo-text';

export class SeoTextMapper {
  static mapMany(texts: ApiSeoText[]): SeoText[] {
    return ProcessingMapper.process(texts.map(SeoTextMapper.map));
  }

  static map({
    title,
    description,
    page_title,
    meta_title,
    meta_description,
    meta_keywords,
    meta_og_title,
    meta_og_description,
    transliteration_title,
    watching_text,
    season_text,
    episode_text,
    trailer_text,
    actors_text,
  }: ApiSeoText): SeoText {
    return ProcessingMapper.process({
      title,
      description,
      pageTitle: page_title,
      metaTitle: meta_title,
      metaDescription: meta_description,
      metaKeywords: meta_keywords,
      metaOgTitle: meta_og_title,
      metaOgDescription: meta_og_description,
      transliterationTitle: transliteration_title,
      watchingText: watching_text,
      seasonText: season_text,
      episodeText: episode_text,
      trailerText: trailer_text,
      actorsText: actors_text,
    });
  }
}
