import { isUndefinedOrNull } from '@package/sdk/src/core';
import { isMobile } from '@PLAYER/player/base/dom';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export default function usePlaylistMode() {
  const { isKinom } = useProjector();
  const { isFullScreenEnabled } = storeToRefs(useVideoUIStore());
  const { currentPlaylist } = storeToRefs(usePlaylistStore());

  const isPlaylistModeFullscreen = computed(
    () => isKinom.value && isMobile && isFullScreenEnabled.value && !isUndefinedOrNull(currentPlaylist.value),
  );

  return {
    isPlaylistModeFullscreen,
  };
}
