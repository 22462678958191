<template>
  <NavigatableItem
    :class="[{ [$style.play]: true, [$style.primary]: variation === 'primary' }, className]"
    :active-class="activeClass ? activeClass : $style.playActive"
    :tag="AppButton"
    :extra-props="extraProps"
    :focus-key="focusKey || FocusKeys.PLAY_CONTENT_BUTTON"
    :text="primaryButtonText"
    :disabled="isContentDisabled && shouldDisableContentWhenUnavailable"
    @click="onPlayContent"
    @active="$emit('active')"
  />
</template>

<script setup lang="ts">
import * as playerHelpers from '@package/media-player/src/player/helpers';
import type { AbstractContentAccessKind, Episode, Moment, Season, Serial } from '@package/sdk/src/api';
import { AvailableContentType, ContentAccessTypes, MediaContentType } from '@package/sdk/src/api';
import { isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { ContentGetters, ContentState, EpisodeData, SessionGetters, SessionState } from '@SMART/index';
import {
  FocusKeys,
  RouterPage,
  routerService,
  storeToRefs,
  useAuthStore,
  useContentStore,
  useMediaContentActions,
  useOfferActions,
  useSessionStore,
} from '@SMART/index';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import AppButton from '@/components/app-button/AppButton.vue';
import useSessionVariables from '@/sdk/session/use-session-variables';

interface Props {
  className?: string;
  activeClass?: string;
  contentType?: MediaContentType;
  shouldDisableContentWhenUnavailable?: boolean;
  contentId?: string;
  extraProps?: Record<string, any>;
  skipModal?: boolean;
  withAccessKind?: boolean;
  focusKey?: string;
  moment?: Moment;
  variation?: 'primary';

  onPlayCallback?: () => void;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'active'): void;
  (event: 'on-play-content'): void;
  (event: 'close-modal'): void;
}>();

const route = useRoute();
const contentStore = useContentStore();

const authStore = useAuthStore();
const { openOffersPage } = useOfferActions();
const { openPlayerPage, getContentPage } = useMediaContentActions();
const { isAvailable, isUnavailableSoon } = playerHelpers.useContentAvailability();

const { isActiveSubscription, isPartnerSubscription, isPartnerUser, subscription, currentOffer, hasTrialOffer } =
  storeToRefs<SessionState, SessionGetters, unknown>(useSessionStore());

const { isAuth } = useSessionVariables();

const { content, isLoadingContent } = storeToRefs<ContentState, ContentGetters, unknown>(contentStore);

const isUnavailableContentModalShown = ref(false);
const modalTitle = ref('');
const availableEpisodeId = ref('');

const isContentAvailable = computed(() => isAvailable(content.value) || isUnavailableSoon(content.value));

const isContentDisabled = computed(
  () => !isContentAvailable.value || isLoadingContent.value || (isPartnerUser.value && !isActiveSubscription.value),
);

const getAvailableEpisodeId = () => {
  const serial = content.value as Serial | undefined;

  if (!serial?.seasons) {
    return;
  }

  const seasons = serial.seasons;
  const count = seasons.length;

  for (let availableSeasonIndex = 0; availableSeasonIndex < count; availableSeasonIndex++) {
    const season = seasons[availableSeasonIndex];
    if (season.availability === AvailableContentType.AVAILABLE) {
      const availableEpisodeIndex = seasons[availableSeasonIndex].episodes.findIndex(
        (episode) => episode.availability === AvailableContentType.AVAILABLE,
      );

      if (availableEpisodeIndex !== -1) {
        return {
          id: seasons[availableSeasonIndex].episodes[availableEpisodeIndex].id,
          availableEpisodeIndex,
          availableSeasonIndex,
        };
      }
    }
  }
};

const primaryButtonText = computed(() => {
  const episodeInfo = getAvailableEpisodeId();

  const serial = content.value as Serial | undefined;

  let episodeData: EpisodeData | undefined;
  let episodeAccessKind: AbstractContentAccessKind | undefined;

  if (serial?.seasons && episodeInfo) {
    const season = serial.seasons[episodeInfo?.availableSeasonIndex] as Season;
    const episode = season.episodes[episodeInfo?.availableEpisodeIndex] as Episode;
    episodeAccessKind = episode.accessKind;

    episodeData = {
      season: season.number,
      episode: episode.number,
    };
  }

  return playerHelpers.getPlayButtonText({
    isAuth: isAuth.value,
    isActiveSubscription: isActiveSubscription.value,
    isPartnerSubscription: isPartnerSubscription.value || isPartnerUser.value,
    canContinueWatch: isDefined(content.value?.watchingItem),
    isVOD: true,
    isLive: false,
    isWatchButtonWithContentTypeText: true,
    offer: currentOffer.value,
    hasTrialOffer: hasTrialOffer.value,
    subscription: subscription.value,
    episodeData,
    accessKind: props.withAccessKind ? episodeAccessKind : undefined,
  });
});

const onPlayContent = async () => {
  if (!content.value) {
    throw new UnexpectedComponentStateError('content');
  }

  const isSerial = props.contentType
    ? route.params.type === MediaContentType.Serial
    : content.value.contentType !== MediaContentType.Movie;

  const serial = content.value as Serial;

  const firstSeason = serial?.seasons && serial.seasons[0];
  const firstEpisode = firstSeason?.episodes && firstSeason.episodes[0];

  let episodeId = isSerial
    ? content.value.watchingItem?.contentId || availableEpisodeId.value || firstEpisode?.id || props.contentId
    : undefined;

  let seasonIndex = props.moment?.seasonNumber ? String(props.moment.seasonNumber) : undefined;
  let episodeIndex = props.moment?.episodeNumber ? String(props.moment.episodeNumber) : undefined;

  const isRedirectToContentPage = [RouterPage.MyChannelPage, RouterPage.MomentsPage, RouterPage.MediaCardPage].includes(
    routerService.route.name,
  );

  if (isRedirectToContentPage) {
    authStore.setBackUrl(getContentPage({ id: content.value.id, contentType: content.value.contentType, title: '' }));
  }

  if (
    !isActiveSubscription.value &&
    (isPartnerSubscription.value || firstEpisode?.accessKind !== ContentAccessTypes.AllUsers)
  ) {
    return openOffersPage();
  }

  if (isSerial && !serial?.watchingItem && serial && !availableEpisodeId.value) {
    const seasons = serial?.seasons;

    if (seasons && !isAvailable(firstEpisode)) {
      const result = getAvailableEpisodeId();
      episodeId = result?.id;
    }
  }

  if (isSerial && serial?.watchingItem) {
    const isEpisodeAvailable = serial.seasons.find((season) =>
      // eslint-disable-next-line array-callback-return
      season.episodes.find((episode) => {
        if (episode.id === serial.watchingItem?.contentId) {
          return episode.availability === AvailableContentType.AVAILABLE;
        }
      }),
    );

    if (!isEpisodeAvailable) {
      const result = getAvailableEpisodeId();
      episodeId = result?.id;
      seasonIndex = String(result?.availableSeasonIndex);
      episodeIndex = String(result?.availableEpisodeIndex);
    }
  }

  if (!isContentAvailable.value) {
    return openOffersPage();
  }

  isUnavailableContentModalShown.value = false;

  emit('on-play-content');

  if (props.onPlayCallback) {
    return props.onPlayCallback();
  }

  return openPlayerPage({
    id: content.value.id,
    kinomId: props.moment?.id,
    kinomTitle: props.moment?.title,
    seasonIndex,
    episodeId,
    episodeIndex,
    title: content.value.title,
    contentType: content.value.contentType,
  });
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.play {
  display: flex;
  align-items: center;

  &Active {
    background-color: var(--color-bg-accent);
  }

  svg {
    display: inline-flex;
    width: adjustPx(34px);
    height: adjustPx(34px);
    margin-right: adjustPx(15px);
  }
}

.primary {
  border-color: transparent;
  background-color: var(--color-bg-ghost);
  overflow: hidden;

  &.active,
  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: var(--color-bg-accent);
  }
}

.modalUnavailable {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--color-bg-secondary-60);
  flex-flow: column;
  justify-content: center;

  &Title {
    width: adjustPx(800px);
    font-weight: 500;
    font-size: adjustPx(48px);
    text-align: center;
  }

  &Navigation {
    display: flex;
    flex-direction: column;
    margin-top: adjustPx(119px);

    &Control {
      button {
        min-width: adjustPx(287px);

        &::after {
          box-shadow: none !important;
        }
      }

      &:first-child {
        margin-bottom: adjustPx(20px);
      }
    }
  }
}
</style>
