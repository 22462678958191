// @ts-nocheck

import useLogger from '@package/logger/src/use-logger';
import { isUndefined, isUndefinedOrNull, toDisposable } from '@package/sdk/src/core';
import { QualityLevel } from '@PLAYER/player/modules/interfaces/hls';
import {
  MediaSourceEventErrorType,
  MediaSourceTechAbrLevelQualityChanged,
  MediaSourceTechEvent,
  MediaSourceTechEventError,
  MediaSourceTechEventFragmentChanged,
  MediaSourceTechEventFragmentLoaded,
  MediaSourceTechEventManifestParsed,
  MediaSourceTechEventQualityLevelSwitched,
} from '@PLAYER/player/tech/events/media-source-tech-event';
import { HlsTechConstructorOptions } from '@PLAYER/player/tech/hls/hls-tech-interfaces';
import MediaSourceTech, {
  MediaSourceLoadOptions,
  MediaSourceTechBufferInfo,
} from '@PLAYER/player/tech/media-source-tech';
import loadHlsJsModule from '@PLAYER/player/tech-loaders/hls-js-loader';
import type { ErrorData, FPSDropLevelCappingData, HlsConfig, LevelSwitchedData, ManifestParsedData } from 'hls.js';
import type Hls from 'hls.js';

const defaultHlsConfig: Partial<HlsConfig> = {
  startLevel: 0,
  maxBufferLength: 30,
  enableWorker: true,
  backBufferLength: 15,
};

const hlsErrorMapToMediaSource: Record<string, MediaSourceEventErrorType> = {
  bufferStalledError: 'buffer-error',
  manifestLoadError: 'manifest-network-error',
  manifestLoadTimeout: 'manifest-network-error',
  manifestParsingError: 'manifest-parsing-error',
};

const logger = useLogger('hls-instance', 'media-player');

export default class HlsMediaTech extends MediaSourceTech<HTMLVideoElement> {
  private tech: Hls;
  private readonly hlsConfig: Partial<HlsConfig>;

  constructor(options: HlsTechConstructorOptions) {
    super();

    this.hlsConfig = Object.assign({}, defaultHlsConfig, options.hlsConfig);
  }

  public get hlsInstance() {
    return this.tech;
  }

  public get bandwidth(): number {
    if (!this.tech) {
      return 0;
    }

    return Number((this.tech.bandwidthEstimate / 1024).toFixed(2));
  }

  public get videoCodec(): string {
    if (!this.tech) {
      return '';
    }

    return this.tech.levels[this.tech.currentLevel]?.videoCodec;
  }

  public get audioCodec(): string {
    if (!this.tech) {
      return '';
    }

    return this.tech.levels[this.tech.currentLevel]?.audioCodec;
  }

  public get currentQualityLevelHeight(): number {
    if (!this.tech) {
      return 0;
    }

    return this.tech.levels[this.tech.currentLevel]?.height;
  }

  public get latency(): number {
    if (!this.tech) {
      return 0;
    }

    return this.tech.latency;
  }

  public get buffer(): MediaSourceTechBufferInfo {
    if (!this.tech) {
      return { length: 0, start: 0 };
    }

    const { mainForwardBufferInfo } = this.tech;

    return {
      length: mainForwardBufferInfo?.len || 0,
      start: mainForwardBufferInfo?.start || 0,
    };
  }

  public async init() {
    const HlsModule = await loadHlsJsModule();

    this.tech = new HlsModule(this.hlsConfig);
    logger.info('init', this.hlsConfig);

    this.disposableStore.add(
      toDisposable(() => {
        this.tech.stopLoad();
        this.tech.detachMedia();
        this.tech.destroy();
      }),
    );

    this.registerListeners();
  }

  public async stopLoad(): Promise<void> {
    logger.info('stopLoad');

    this.tech.stopLoad();
  }

  public startLoad(offset = 0) {
    logger.info('startLoad');

    this.tech.startLoad(offset);
  }

  public async loadSource(options: MediaSourceLoadOptions): Promise<void> {
    const { src, offset } = options;

    this.tech.startLoad(offset);
    this.tech.loadSource(src);
  }

  public recoverMediaError(): Promise<void> {
    logger.info('recoverMediaError');

    this.tech.recoverMediaError();

    return Promise.resolve(undefined);
  }

  public async attachMedia(element: HTMLVideoElement): Promise<void> {
    await super.attachMedia(element);

    logger.info('attachMedia');

    this.tech.attachMedia(element);
  }

  public async detachMedia(): Promise<void> {
    await super.detachMedia();

    logger.info('detachMedia');

    this.tech.detachMedia();
  }

  public setNextLevel(level: number) {
    logger.info('setNextLevel', level);

    this.tech.currentLevel = level;
  }

  public getNextLevel() {
    return this.tech.nextLevel;
  }

  public requestSaveMediaOffline(): Promise<void> {
    return Promise.resolve(undefined);
  }

  protected registerListeners(): void {
    const onFragLoaded = () => {
      const firstLevel = this.tech.levels[0];
      const levelTotalDuration = firstLevel?.details?.totalduration;

      if (isUndefined(levelTotalDuration)) {
        return;
      }

      const startFragmentTime = firstLevel.details?.fragments[0].start as number;
      const endFragmentTime = firstLevel.details?.fragments[0].end as number;

      const event = new MediaSourceTechEvent<MediaSourceTechEventFragmentLoaded>({
        tech: 'hls.js',
        originalEvent: firstLevel,
        data: { startFragmentTime, levelTotalDuration, endFragmentTime },
      });

      this.emitter.emit('fragment-loaded', event);
    };

    const onFragChanged = () => {
      const currentLevel = this.tech.levels[this.tech.currentLevel];
      const currentFragments = currentLevel.details?.fragments;

      if (isUndefinedOrNull(currentFragments)) {
        return;
      }

      const currentFragStartTime = currentFragments[0]?.start;

      if (isUndefinedOrNull(currentFragStartTime)) {
        return;
      }

      const currentTime = this.videoEl?.currentTime - currentFragStartTime;
      const levelTotalDuration = currentLevel.details?.totalduration;

      this.emitter.emit(
        'fragment-changed',
        new MediaSourceTechEvent<MediaSourceTechEventFragmentChanged>({
          tech: 'hls.js',
          // since its new event, there is no need in original event
          originalEvent: undefined,
          data: {
            currentTime,
            startFragmentTime: currentFragStartTime,
            levelTotalDuration,
          },
        }),
      );
    };

    const onFragBuffered = () => {
      this.emitter.emit('fragment-buffered');
    };

    const onError = (_: string, error: ErrorData) => {
      const hlsDetailsError = error.details;

      const errorType = hlsErrorMapToMediaSource[hlsDetailsError] || '';

      const isFatalError = false;

      this.emitter.emit(
        'error',
        new MediaSourceTechEvent<MediaSourceTechEventError>({
          tech: 'hls.js',
          originalEvent: error,
          data: {
            errorType,
            fatal: isFatalError,
          },
        }),
      );
    };

    const onManifestParsed = (_: string, manifest: ManifestParsedData) => {
      const levels: QualityLevel[] = manifest.levels
        .map((level, index) => ({
          width: level.width,
          height: level.height,
          id: index,
        }))
        .filter((level) => level.width > 0);

      const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventManifestParsed>({
        tech: 'hls.js',
        originalEvent: manifest,
        data: {
          qualityLevels: levels,
        },
      });

      this.emitter.emit('manifest-parsed', mediaEvent);
    };

    const onQualityLevelSwitched = (_: string, data: LevelSwitchedData) => {
      const newLevelId = data.level;

      const mediaEvent = new MediaSourceTechEvent<MediaSourceTechEventQualityLevelSwitched>({
        tech: 'hls.js',
        originalEvent: data,
        data: {
          level: newLevelId,
        },
      });

      this.emitter.emit('quality-level-switched', mediaEvent);
    };

    const onSubtitleTrackLoaded = () => {
      this.emitter.emit('subtitle-track-loaded');
    };

    const onSubtitleTrackUpdated = () => {
      this.emitter.emit('subtitle-track-updated');
    };

    const onAbrChanged = (_: string, data: FPSDropLevelCappingData) => {
      const { droppedLevel, level } = data;

      const currentLevel = this.tech.levels.find((lvl) => lvl.id === droppedLevel);
      const newLevel = this.tech.levels.find((lvl) => lvl.id === level);

      if (newLevel && currentLevel) {
        this.emitter.emit(
          'abr-quality-level-changed',
          new MediaSourceTechEvent<MediaSourceTechAbrLevelQualityChanged>({
            tech: 'hls.js',
            originalEvent: data,
            data: {
              oldLevelHeight: currentLevel.height,
              newLevelHeight: newLevel.height,
            },
          }),
        );
      }
    };

    // Событие смены качества, если это произошло автоматически
    this.tech.on('hlsFpsDropLevelCapping', onAbrChanged);

    this.tech.on('hlsSubtitleTrackLoaded', onSubtitleTrackLoaded);
    this.tech.on('hlsSubtitleTracksUpdated', onSubtitleTrackUpdated);

    // ошибка
    this.tech.on('hlsError', onError);
    // событие когда с сети выкачался новый кусок
    this.tech.on('hlsFragLoaded', onFragLoaded);
    // событие когда сменился кусок видео во время проигрывания
    this.tech.on('hlsFragChanged', onFragChanged);
    // событие когда кусок видео прогрузился
    this.tech.on('hlsFragBuffered', onFragBuffered);

    // событие парсинга манифеста
    this.tech.on('hlsManifestParsed', onManifestParsed);
    // событие когда поменялось качество
    this.tech.on('hlsLevelSwitched', onQualityLevelSwitched);
  }

  public dispose() {
    logger.info('HlsMediaTech - dispose');

    super.dispose();
  }
}
