const isClient = typeof window !== 'undefined';

/**
 *
 * @param {string} name
 * @returns {string | undefined}
 */
export default function getQueryParamByName(name: string): string | undefined {
  if (!isClient) {
    return undefined;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(name);

  if (value) {
    return value;
  }

  return undefined;
}
