import type { StorageKeys } from './storage-types';

type LocalStorageKey = StorageKeys | string;

export interface IStorageService {
  getItem<T>(key: LocalStorageKey, defaultValue: T): T;
  getItem<T>(key: LocalStorageKey): T | null;

  setItem<T>(key: LocalStorageKey, value: T): void;
  removeItem(key: LocalStorageKey): void;

  clearAll(): void;
}

const isClient = typeof window !== 'undefined';

export class StorageService implements IStorageService {
  public getItem<T>(key: LocalStorageKey, defaultValue?: T): T | null {
    if (!isClient) {
      return null;
    }

    const storageData = window.localStorage.getItem(key);

    if (!storageData) {
      return null;
    }

    const json = this.getJson<T>(storageData);

    if (json) {
      return json;
    }

    if (defaultValue) {
      return defaultValue;
    }

    return json ?? (storageData as T);
  }

  public clearAll() {
    if (!isClient) {
      return;
    }

    window.localStorage.clear();
  }

  public setItem<T>(key: LocalStorageKey, value: T): void {
    if (!isClient) {
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public removeItem(key: LocalStorageKey): void {
    if (!isClient) {
      return;
    }

    window.localStorage.removeItem(key);
  }

  private getJson<T>(item: string): T | null {
    try {
      return JSON.parse(item);
    } catch {
      return null;
    }
  }
}
