import { Disposable } from '@package/sdk/src/core';

import { sendEventsBatch } from '../request/send-events-batch-request';
import type { DsmlRequestBodyV2Params } from './dsml-v2-request-transformer';

export class EventBuffer extends Disposable {
  private _buffer: DsmlRequestBodyV2Params[] = [];
  private readonly _MAX_BUFFER_SIZE: number = 300;

  constructor(events: DsmlRequestBodyV2Params[] = []) {
    super();
    this.add(...events);
  }

  public add(...events: DsmlRequestBodyV2Params[]): void {
    if (this._buffer.length >= this._MAX_BUFFER_SIZE) {
      this._buffer.shift();
    }

    this._buffer.push(...events);
  }

  public get length(): number {
    return this._buffer.length;
  }

  private _clear(): void {
    this._buffer = [];
  }

  public async sendAll(): Promise<void> {
    await sendEventsBatch(this._buffer);
    this._clear();
  }
}

export const eventBuffer = new EventBuffer();
