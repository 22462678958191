import { AnalyticEventNameNew, AnalyticPayloadLabelName } from '@package/sdk/src/analytics';
import { roundNumber } from '@PLAYER/player/base/number';
import { AnalyticValue } from '@PLAYER/player/modules/analytics/analytics';
import useAnalytics from '@PLAYER/player/modules/analytics/use-analytics';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useAnalyticsStore from '@PLAYER/player/modules/store/analytics-store';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';

export default function usePlaybackAnalytics() {
  const analytics = useAnalytics();
  const analyticsStore = useAnalyticsStore();
  const { normalizedDisplayedCurrentTime } = useVideoPlayerVariables();
  const { isKinom, isVOD } = useProjector();

  const setStartTimeStreaming = (value: number) => {
    analyticsStore.setStartTimeContentStreaming(value);
    analyticsStore.setStartViewingDate(Date.now());
  };

  const setFinishTimeStreaming = (value: number) => {
    analyticsStore.setFinishTimeContentStreaming(value);
    analyticsStore.setFinishViewingDate(Date.now());
  };

  const patchAnalyticsPayload = (values: AnalyticValue[]) => {
    if (isKinom.value || isVOD.value) {
      values.push({
        label: AnalyticPayloadLabelName.ViewingTime,
        value: roundNumber(normalizedDisplayedCurrentTime.value),
      });
    }
  };

  const onPlayBackwardAnalytics = () => {
    const values: AnalyticValue[] = [];

    patchAnalyticsPayload(values);
    analytics.sendAnalytic({ name: AnalyticEventNameNew.ClickPlayer10secRewind, values });
  };

  const onPlayForwardAnalytics = () => {
    const values: AnalyticValue[] = [];

    patchAnalyticsPayload(values);
    analytics.sendAnalytic({ name: AnalyticEventNameNew.ClickPlayer10secForward, values });
  };

  return {
    setStartTimeStreaming,
    setFinishTimeStreaming,
    onPlayBackwardAnalytics,
    onPlayForwardAnalytics,
  };
}
