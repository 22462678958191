import { globalSettings } from '../services/index';
import { adjustPx } from './style';

export const useSliderOffset = (fixedOffsetRightPx = adjustPx(60)) => {
  const offsetLeftPx = globalSettings.ref(0);
  const prevOffsetRightPx = globalSettings.ref(0);

  const handleUpdateOffset = (element: HTMLElement, parentWidth = 0) => {
    const { offsetLeft = 0, offsetWidth = 0 } = element.parentElement || {};
    const offsetRight = offsetLeft + offsetWidth;
    if (parentWidth - offsetRight < fixedOffsetRightPx) {
      const sign = Math.sign(offsetRight - prevOffsetRightPx.value);
      const offset =
        sign > 0
          ? offsetRight - parentWidth - offsetLeftPx.value + fixedOffsetRightPx
          : prevOffsetRightPx.value - offsetRight;
      offsetLeftPx.value = offsetLeftPx.value + sign * offset;
    } else if (parentWidth - offsetLeft > fixedOffsetRightPx) {
      offsetLeftPx.value = 0;
    }
    prevOffsetRightPx.value = offsetRight;
  };

  return {
    offsetLeftPx,
    prevOffsetRightPx,
    handleUpdateOffset,
  };
};
