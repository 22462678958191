import { ProcessingMapper } from '../base/processing-mapper';
import { ApiPaymentMethod } from './payments-types/payment-method';

export interface UsePaymentsActions {
  id: string;
  maskedNumber: string;
}

export class PaymentMethodMapper {
  static mapMany(methods: ApiPaymentMethod[]): UsePaymentsActions[] {
    return ProcessingMapper.process(methods.map(PaymentMethodMapper.map));
  }

  static map({ masked_number, ...rest }: ApiPaymentMethod): UsePaymentsActions {
    return ProcessingMapper.process({
      maskedNumber: masked_number,
      ...rest,
    });
  }
}
