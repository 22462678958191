import { ApplicationError } from './application-error';

export class WebOsSystemError extends ApplicationError {
  public readonly name = 'WebOsSystemError';

  constructor(message: string) {
    super(message);
  }

  public toJSON(): Record<string, any> {
    return {};
  }
}
