<template>
  <div ref="el" :class="{ [$style.wrapper]: true }" :style="{ left: $toPixel(normalizedPixelPosition) }">
    <timeline-frame-preview-image
      :ref="(comp) => (wrapperEl = comp?.$el)"
      :class="{ [$style.smartTvActive]: isSmartTV && focused }"
      :src="moment.preview"
      :on-click="onSkipToMoment"
    >
      <template #text>
        <p :class="$style.description">
          <span :class="$style.text">{{ $t('media.kinom.skipToMoment') }}</span>
          <span :class="$style.time">{{ startOffsetTime }}</span>
        </p>
      </template>
    </timeline-frame-preview-image>
  </div>
</template>

<script lang="ts" setup>
import { Moment } from '@package/sdk/src/api';
import TimelineFramePreviewImage from '@PLAYER/player/components/timeline/TimelineFramePreviewImage.vue';
import { AppDateManager } from '@PLAYER/player/modules/date/date-manager';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';
import { useElementSize } from '@vueuse/core';
import { computed, ref } from 'vue';

const props = defineProps<{
  moment: Moment;
}>();

const emit = defineEmits<{
  (e: 'closed'): void;
}>();

const onSkipToMoment = () => {
  playbackActions.seekTo({ offset: rawStartTime.value, coldStart: false });

  emit('closed');
};

const { isSmartTV } = usePlatform();
const { useNavigatable } = useSmartNavigatable();

const { el, focused } = useNavigatable({
  onEnterPress: onSkipToMoment,
});

const { appWidth } = useRootVideoElBounds();
const playbackActions = usePlaybackActions();
const { normalizedDuration } = useVideoPlayerVariables();

const wrapperEl = ref<HTMLElement>();
const { width: wrapperWidth } = useElementSize(wrapperEl);

const timelineWidth = computed(() => appWidth.value - 100);
const linkedKinom = computed(() => props.moment);
const rawStartTime = computed(() => linkedKinom.value?.startOffset);
const startOffsetTime = computed(() => AppDateManager.formatDuration(rawStartTime.value));

const currentHandlePosition = computed(() => rawStartTime.value / normalizedDuration.value);

const normalizedPosition = computed(() => {
  if (currentHandlePosition.value <= 0) {
    return 0;
  }

  if (currentHandlePosition.value >= 1) {
    return 1;
  }

  return currentHandlePosition.value;
});

const normalizedPixelPosition = computed(() => {
  const halfWrapperWidth = wrapperWidth.value / 2;
  const targetPositionWrapperPx = normalizedPosition.value * timelineWidth.value;

  const targetPosition = targetPositionWrapperPx - halfWrapperWidth;

  if (targetPosition < 0) {
    return 0;
  }

  if (targetPositionWrapperPx >= timelineWidth.value) {
    return timelineWidth.value - 160;
  }

  return targetPosition;
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.wrapper {
  position: absolute;
  bottom: 0;
  z-index: var(--z-index-video-controls);
  width: 160px;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--g-spacing-4) var(--g-spacing-8) var(--g-spacing-6);
}

.text {
  color: var(--color-notheme-text-primary);

  @include webFonts.WebLabel-3();
}

.time {
  margin-top: var(--g-spacing-2);
  color: var(--color-notheme-text-secondary);

  @include webFonts.WebBody-3();
}

.smartTvActive {
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
}
</style>
