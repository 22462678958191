import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

export default function useProjector() {
  const videoConfig = useVideoConfig();

  const projector = computed(() => videoConfig['video.projector']);

  const isMyChannelPlayer = computed(() => projector.value === 'my-channel');
  const isContentBackgroundPlayer = computed(() => projector.value === 'content-background');
  const isSmartTvMainPagePlayer = computed(() => projector.value === 'smarttv-main-page-player');

  const isKinom = computed(
    () =>
      projector.value === 'kinom' ||
      isMyChannelPlayer.value ||
      isContentBackgroundPlayer.value ||
      isSmartTvMainPagePlayer.value,
  );
  const isLive = computed(() => projector.value === 'live');
  const isVOD = computed(() => projector.value === 'vod');

  return {
    isSmartTvMainPagePlayer,
    projector,
    isKinom,
    isLive,
    isVOD,
    isContentBackgroundPlayer,
    isMyChannelPlayer,
  };
}
