import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import useLogger from '@package/logger/src/use-logger';
import { isTrue, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { UnexpectedPropertyConditionError } from '@package/sdk/src/core/errors/unexpected-property-condition-error';
import { ensurePromiseExist, isChromium, isFirefox, isIOS, isMobile, isSafari } from '@PLAYER/player/base/dom';
import { TimeSeconds } from '@PLAYER/player/base/number';
import { VideoPlayerHTML5Error, VideoPlayerScope } from '@PLAYER/player/errors/video-player-html5-error';
import { ChromecastMetadataOptions } from '@PLAYER/player/modules/chromecast/chromecast-instance';
import ChromecastInstanceManager from '@PLAYER/player/modules/chromecast/chromecast-manager';
import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useFullscreenActions from '@PLAYER/player/modules/video/use-fullscreen-actions';
import useSafeVideoEventHandler from '@PLAYER/player/modules/video/use-safe-video-event-handler';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import useRemoteControlEvent from '@PLAYER/player/versions/smart/modules/remote-control/use-remote-control-event';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount } from 'vue';

import useSafeRootVideoElement from './use-safe-root-video-element';
import useSafeVideoElement from './use-safe-video-element';

type LockOrientationType = 'portrait' | 'landscape';

const lockOrientation = async (orientation: LockOrientationType) => {
  if (!isMobile) {
    return;
  }

  try {
    const promise = ensurePromiseExist(window.screen.orientation.lock(orientation));

    await promise;
  } catch (error) {
    if (error.name === 'NotSupportedError') {
      throw new VideoPlayerHTML5Error(VideoPlayerScope.Orientation, error.message);
    }
  }
};

const _requestFullScreen = async (videoEl: HTMLElement, orientation: LockOrientationType = 'landscape') => {
  if (videoEl.requestFullscreen) {
    await videoEl.requestFullscreen();
  } else if (videoEl.webkitRequestFullscreen) {
    await videoEl.webkitRequestFullscreen();
  } else if (videoEl.mozRequestFullScreen) {
    await videoEl.mozRequestFullScreen();
  } else if (videoEl.msRequestFullscreen) {
    await videoEl.msRequestFullscreen();
  } else if (videoEl.webkitEnterFullScreen) {
    await videoEl.webkitEnterFullScreen();
  } else {
    throw new VideoPlayerHTML5Error(VideoPlayerScope.Fullscreen, 'FullscreenAPI not available');
  }

  await lockOrientation(orientation);
};

interface ChangeCurrentTimeOptions {
  seconds: TimeSeconds;
  manually: boolean;
}

export default function useVideoInteractions() {
  const videoEl = useSafeVideoElement();
  const rootVideoEl = useSafeRootVideoElement();
  const eventBus = useSafeEventBus();
  const fullscreenActions = useFullscreenActions();
  const videoEventHandler = useSafeVideoEventHandler();
  const logger = useLogger('use-video-interactions', 'media-player');

  const { isLive } = useProjector();

  const { normalizedDuration, mediaSourceTechPlaybackType } = useVideoPlayerVariables();

  const videoUIStore = useVideoUIStore();
  const { isInitialized, isPlayingOnRemoteDevice, isPictureInPictureEnabled, displayedCurrentTime } =
    storeToRefs(videoUIStore);
  const { width: windowWidth, height: windowHeight } = useWindowSize({
    listenOrientation: true,
  });
  const videoControlsStore = useVideoControlsStore();
  const { isShouldPlayVideoAfterRewind } = storeToRefs(videoControlsStore);
  const { manifestUrl } = storeToRefs(useManifestStore());
  const { triggerRemoteControlEvent } = useRemoteControlEvent();

  const execute = <T>(callback: () => T) => {
    if (!manifestUrl.value) {
      return;
    }

    return Reflect.apply(callback, undefined, []);
  };

  const openPictureInPicture = async () => {
    const pictureInPictureWindow = await execute(() => {
      /**
       * API not available in Firefox
       */
      if (isFirefox) {
        return;
      }

      try {
        return videoEl.requestPictureInPicture();
      } catch (error) {
        logger.error(error.toString());
      }
    });

    if (pictureInPictureWindow) {
      logger.info(
        `PIP window opened. Width - ${pictureInPictureWindow.width}. Height - ${pictureInPictureWindow.height}`,
      );
    }

    return pictureInPictureWindow;
  };

  const closePictureInPicture = () =>
    execute(() => {
      /**
       * API not available in Firefox
       */
      if (isFirefox) {
        return;
      }

      try {
        return document.exitPictureInPicture();
      } catch (error) {
        logger.error(error.toString());
      }
    });

  const requestFullscreen = () =>
    execute(async () => {
      if (isPictureInPictureEnabled.value) {
        await closePictureInPicture();
      }

      const element = (() => {
        if (isIOS) {
          return document.fullscreenEnabled ? rootVideoEl.value : videoEl.domElement;
        }

        return rootVideoEl.value;
      })();

      try {
        const orientation = ((): LockOrientationType => {
          return 'landscape';
        })();

        await _requestFullScreen(element, orientation);
      } catch (error) {
        if (error instanceof VideoPlayerHTML5Error) {
          throw error;
        }

        if (error instanceof Error) {
          throw new VideoPlayerHTML5Error(VideoPlayerScope.Fullscreen, error.message);
        }

        throw error;
      }
    });

  const exitFullscreen = () => execute(() => fullscreenActions.exitFullScreen());

  const startLoad = () =>
    execute(() => {
      if (isInitialized.value) {
        return logger.debug(new UnexpectedPropertyConditionError('isInitialized', 'true', 'false'));
      }

      if (!manifestUrl.value) {
        return logger.debug(new UnexpectedComponentStateError('manifestUrl'));
      }

      const src = manifestUrl.value;
      videoUIStore.setIsAutoStartLoad(true);
      eventBus.emit('onSourceUpdated', new VideoPlayerInternalEvent({ src }));
    });

  const play = () =>
    execute(() => {
      if (isPlayingOnRemoteDevice.value) {
        return videoEventHandler.trigger('play');
      }

      const playPromise = ensurePromiseExist(videoEl.play());

      if (playPromise) {
        playPromise.catch((_) => {
          /* */
        });
      }
    });

  const goToLive = () =>
    execute(() => {
      const videoDuration =
        (mediaSourceTechPlaybackType.value === 'html5' ? normalizedDuration.value : videoEl.duration) -
        ConstantsConfigPlayer.getProperty('liveTimeoutEdgeSeconds');

      videoEl.currentTime = Math.max(0, videoDuration, videoEl.currentTime);

      return play();
    });

  const pause = () =>
    execute(() => {
      if (isPlayingOnRemoteDevice.value) {
        return videoEventHandler.trigger('pause');
      }

      return videoEl.pause();
    });

  const setVolume = (volume: number) => {
    try {
      videoEl.volume = volume;
    } catch (error) {
      logger.error(error);
    }
  };

  const changePlaybackRate = (rate: number) =>
    execute(() => {
      videoEl.playbackRate = rate;
    });

  const requestRemotePlay = (metadata?: ChromecastMetadataOptions) =>
    execute(() => {
      if (!manifestUrl.value) {
        throw new UnexpectedComponentStateError('manifestUrl');
      }

      if (isIOS || isSafari) {
        return videoEl.webkitShowPlaybackTargetPicker();
      }

      if (isChromium) {
        videoEl.pause();

        ChromecastInstanceManager.requestChromecastSession(manifestUrl.value, metadata);
      }
    });

  const stopRemotePlay = () =>
    execute(() => {
      if (!isPlayingOnRemoteDevice.value) {
        throw new UnexpectedPropertyConditionError('isPlayingOnRemoteDevice', 'false', 'true');
      }

      ChromecastInstanceManager.stopChromecastSession();
      videoUIStore.setIsPlayingOnRemoteDevice(false);
    });

  let seekedTimeoutId: number;

  const onSeekedOnce = async () => {
    if (isTrue(isShouldPlayVideoAfterRewind.value)) {
      await videoEl.play();
    }

    const onAfterSeeked = () => {
      videoControlsStore.setIsShouldPlayVideoAfterRewind(undefined);
      const isPaused = videoEl.paused;

      triggerRemoteControlEvent({
        paused: isPaused,
        offset: displayedCurrentTime.value,
      });
    };

    if (seekedTimeoutId) {
      window.clearTimeout(seekedTimeoutId);
    }

    seekedTimeoutId = window.setTimeout(
      onAfterSeeked,
      ConstantsConfigPlayer.getProperty('seekedSendRemoteControlEventTimeoutMs'),
    );
  };

  const changeVideoElTime = (options: ChangeCurrentTimeOptions) =>
    execute(() => {
      const { seconds, manually } = options;

      videoEl.currentTime = seconds;

      if (manually) {
        videoEventHandler.addOnceEventListener('seeked', onSeekedOnce);
      }
    });

  const seekRemotePlayer = (options: ChangeCurrentTimeOptions) =>
    execute(async () => {
      if (!ChromecastInstanceManager.chromecast || isLive.value) {
        return;
      }

      const { seconds } = options;

      await ChromecastInstanceManager.chromecast.seek(seconds);

      ChromecastInstanceManager.chromecast.play();
    });

  const doChangeCurrentTime = (options: ChangeCurrentTimeOptions) =>
    execute(() => {
      if (isPlayingOnRemoteDevice.value) {
        return seekRemotePlayer(options);
      }

      return changeVideoElTime(options);
    });

  const changeCurrentTime = (options: ChangeCurrentTimeOptions) =>
    execute(() => {
      const { seconds, manually } = options;

      const normalizedTime = (() => {
        if (seconds < 0) {
          return 0;
        }

        return seconds;
      })();

      videoUIStore.setDisplayedCurrentTime(normalizedTime);

      doChangeCurrentTime({
        seconds: normalizedTime,
        manually,
      });
    });

  onBeforeUnmount(() => {
    if (seekedTimeoutId) {
      window.clearTimeout(seekedTimeoutId);
    }
  });

  return {
    stopRemotePlay,
    changePlaybackRate,
    startLoad,
    requestRemotePlay,
    openPictureInPicture,
    closePictureInPicture,
    goToLive,
    changeCurrentTime,
    setVolume,
    requestFullscreen,
    exitFullscreen,
    play,
    pause,
  };
}
