import { MediaErrorStatusCode } from '@package/sdk/src/core/media/media-error';

import { AbstractMediaAnalyzer, MediaAnalyzerEvent } from './abstract-media-analyzer';

export class Html5Analyzer extends AbstractMediaAnalyzer {
  constructor(private readonly mediaEl: HTMLVideoElement) {
    super();

    this.registerListeners();
  }

  private onError = () => {
    const mediaError = this.mediaEl.error;

    // Если ошибки нет - делаем вид, что ничего не понимаем, и что это критическая ошибка. ПО ИДЕИ - такого быть не должно.
    if (!mediaError) {
      return this.emitter.emit('playback-failed', new MediaAnalyzerEvent('playback-failed'));
    }

    const code = mediaError.code as MediaErrorStatusCode;

    switch (code) {
      // Чанку стало плохо, затупило прогрузка - но по идее проигрывание потом восстановится
      case MediaErrorStatusCode.MEDIA_ERR_NETWORK:
        return this.emitter.emit('playback-interrupted', new MediaAnalyzerEvent('playback-interrupted'));
      // Устройство не смогло продолжить проигрывание, либо кодеки не поддерживаются.
      case MediaErrorStatusCode.MEDIA_ERR_DECODE:
      case MediaErrorStatusCode.MEDIA_ERR_SRC_NOT_SUPPORTED:
        return this.emitter.emit('playback-failed', new MediaAnalyzerEvent('playback-failed'));
    }
  };

  private registerListeners() {
    this.mediaEl.addEventListener('error', this.onError);
  }

  public dispose() {
    this.mediaEl.removeEventListener('error', this.onError);
  }
}
