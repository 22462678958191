<template>
  <div :class="$style.wrapper">
    <app-slot-button :on-click="onClick" :tab-index="-1" :class="$style.button">
      <IconRemoteButtonUp />
    </app-slot-button>

    <div :class="$style.wrapperCenter">
      <app-slot-button :on-click="onClick" :tab-index="-1" :class="$style.button">
        <IconRemoteButtonLeft :is-active="isActiveLeft" />
      </app-slot-button>
      <app-slot-button :on-click="onClick" :tab-index="-1" :class="[$style.button, $style.buttonCenter]">
        <IconRemoteButtonCenter :is-active="isActiveCenter" />
      </app-slot-button>
      <app-slot-button :on-click="onClick" :tab-index="-1" :class="$style.button">
        <IconRemoteButtonRight :is-active="isActiveRight" />
      </app-slot-button>
    </div>

    <app-slot-button :on-click="onClick" :tab-index="-1" :class="$style.button">
      <IconRemoteButtonDown :is-active="isActiveDown" />
    </app-slot-button>
  </div>
</template>

<script lang="ts" setup>
import IconRemoteButtonCenter from '@PLAYER/player/components/icons/IconRemoteButtonCenter.vue';
import IconRemoteButtonDown from '@PLAYER/player/components/icons/IconRemoteButtonDown.vue';
import IconRemoteButtonLeft from '@PLAYER/player/components/icons/IconRemoteButtonLeft.vue';
import IconRemoteButtonRight from '@PLAYER/player/components/icons/IconRemoteButtonRight.vue';
import IconRemoteButtonUp from '@PLAYER/player/components/icons/IconRemoteButtonUp.vue';
import AppSlotButton from '@PLAYER/player/components/ui/AppSlotButton.vue';

const onClick = () => {
  //
};

defineProps<{
  isActiveCenter?: boolean;
  isActiveLeft?: boolean;
  isActiveRight?: boolean;
  isActiveDown?: boolean;
}>();
</script>

<style module lang="scss">
@import '@package/ui/src/styles/adjust-smart-px';

.wrapper {
  display: flex;
  align-items: center;
  padding: adjustPx(14px);
  width: adjustPx(206px);
  height: adjustPx(206px);
  border-radius: 50%;
  border: 1px solid var(--color-notheme-text-primary);
  background-color: var(--color-bg-secondary);
  flex-direction: column;
  justify-content: space-between;
}

.wrapperCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button {
  padding: 0;
  width: adjustPx(25px);
  height: adjustPx(25px);
  border: none;
  background-color: transparent;
}

.buttonCenter {
  width: adjustPx(86px);
  height: adjustPx(86px);
}
</style>
