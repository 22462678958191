<template>
  <app-player-button
    :class="{
      [$style.framePreview]: true,
      [$style.framePreviewSmartTv]: isSmartTV,
    }"
    :on-click="onClick"
    @blur="onBlur"
    @focus="onFocus"
  >
    <template v-if="isImageShown">
      <app-image
        v-if="isImageLoaded"
        :image-class="$style.image"
        :width="isSmartTV ? 192 : 160"
        background-color="var(--color-notheme-bg-hover-70)"
        :src="src"
      />
      <div v-else :class="$style.loader">
        <icon-preloader :class="$style.icon" is-light-mode />
      </div>
    </template>
    <slot name="text"></slot>
  </app-player-button>
</template>

<script setup lang="ts">
import { AnyFunction } from '@PLAYER/player/base/function';
import IconPreloader from '@PLAYER/player/components/icons/IconPreloader.vue';
import AppImage from '@PLAYER/player/components/ui/AppImage.vue';
import AppPlayerButton from '@PLAYER/player/components/ui/AppPlayerButton.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import { computed, ref } from 'vue';

defineProps<{
  src: string;
  onClick: AnyFunction;
}>();

const emit = defineEmits<{
  (event: 'focus'): void;
  (event: 'blur'): void;
}>();

const { isMediumView } = useRootVideoElBounds();
const { isSmartTV } = usePlatform();

const isImageLoaded = ref(true);

const isImageShown = computed(() => {
  if (isSmartTV) {
    return true;
  }

  return !isMediumView.value;
});

const onBlur = () => {
  emit('blur');
};

const onFocus = () => {
  emit('focus');
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.framePreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 52px;
}

.framePreviewSmartTv {
  border: adjust.adjustPx(8px) solid transparent;
  outline: 0;

  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
  }
}

.image {
  border-top-left-radius: var(--g-border-round-8);
  border-top-right-radius: var(--g-border-round-8);
}

.loader {
  width: 160px;
  border-radius: var(--g-border-round-32);
  background-color: var(--color-states-bg-tertiary-pressed);
}

.icon {
  width: 100% !important;
}
</style>
