<template>
  <root-video-z-index-layer
    :class="{
      [$style.loader]: true,
      [$style.loaderBackground]: !isInitialized,
    }"
    fullscreen
  >
    <div :class="$style.body">
      <template v-if="isSplashScreenShown">
        <icon-logo />
      </template>
      <template v-else>
        <icon-preloader :class="$style.icon" :is-light-mode="type === 'light'" />
      </template>
    </div>
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import IconLogo from '@PLAYER/player/components/icons/IconLogo.vue';
import IconPreloader from '@PLAYER/player/components/icons/IconPreloader.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import { LoaderType } from '@PLAYER/player/modules/hooks/use-loader-actions';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

defineProps<{
  type: LoaderType;
}>();

const { isKinom, isLive } = useProjector();
const { isInitialized } = storeToRefs(useVideoUIStore());

const isSplashScreenShown = computed(() => {
  if (isKinom.value) {
    return false;
  }

  return !isInitialized.value;
});
</script>

<style lang="scss" module>
.icon {
  z-index: 1;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  position: absolute;
  z-index: var(--z-index-main-loader);
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.loaderBackground {
  background-color: var(---color-notheme-dim-black-40);
}
</style>
