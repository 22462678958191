import { ProcessingMapper } from '../base/processing-mapper';
import { ApiMovie } from '../content/content-types/movie';
import { MediaMapper } from '../content/media';
import { Movie } from '../content/types/movie';

export class MovieMapper {
  static mapMany(items: ApiMovie[]): Movie[] {
    return ProcessingMapper.process(items.map(MovieMapper.map));
  }

  static map(api: ApiMovie): Movie {
    return ProcessingMapper.process({
      ...MediaMapper.map(api),
      tvChannelSlug: api.tv_channel_slug,
      timeshiftEndTime: api.timeshift_end_time,
      isCartoon: api['cartoon?'],
    });
  }
}
