import { ApplicationError } from '@package/sdk/src/core';

class PromiseCancelledError extends ApplicationError {
  public readonly name = 'PromiseCancelledError';

  constructor() {
    super();
  }

  public toJSON(): Record<string, any> {
    return {};
  }
}

export class CancellablePromise<T> extends Promise<T> {
  private reject: (reason?: any) => void;

  public constructor(
    executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void,
  ) {
    let savedReject: (reason?: any) => void;

    const nexExecutor = (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => {
      savedReject = reject;

      executor(resolve, reject);
    };

    super(nexExecutor as any);

    this.reject = savedReject!;
  }

  public cancel() {
    this.reject(new PromiseCancelledError());
  }
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('CancellablePromise - rejects', () => {
    const cancellablePromise = new CancellablePromise((_, __) => {});

    expect(cancellablePromise.cancel).toBeDefined();
  });
}
