import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { MediaPlayerPlugin } from '@PLAYER/player/modules/plugin/media-player-plugin';
import { inject } from 'vue';

const injectKey = 'app.plugins';

export default function useSafeMediaPlayerPlugins(): MediaPlayerPlugin[] {
  const { getDefault } = useDefaultProvide();

  const plugins = inject<MediaPlayerPlugin[]>(injectKey, getDefault(injectKey));

  if (!plugins) {
    throw new UnexpectedComponentStateError(injectKey);
  }

  return plugins;
}
