import { isFunction, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import { Ref } from 'vue';

type TouchCallbackFunc = (event: TouchEvent) => void;

export default function useLongTouchPressed(
  element: Ref<HTMLElement | undefined>,
  callback: TouchCallbackFunc,
  options: {
    timeout: number;
    onTouchEndAction?: TouchCallbackFunc;
    onTouchMoveAction?: TouchCallbackFunc;
  },
) {
  let timeoutId: number | undefined;
  const { timeout, onTouchEndAction, onTouchMoveAction } = options;

  useEffect(() => {
    const onTouchstart = (event: TouchEvent) => {
      timeoutId = window.setTimeout(() => {
        timeoutId = undefined;
        Reflect.apply(callback, undefined, [event]);
      }, timeout);
    };

    const onTouchMove = (event: TouchEvent) => {
      if (isFunction(onTouchMoveAction)) {
        Reflect.apply(onTouchMoveAction, undefined, [event]);
      }
    };

    const onTouchEnd = (event: TouchEvent) => {
      if (timeoutId) {
        window.clearTimeout(timeoutId as number);
      }

      if (isFunction(onTouchEndAction)) {
        Reflect.apply(onTouchEndAction, undefined, [event]);
      }
    };

    if (!element.value) {
      throw new UnexpectedComponentStateError('useLongTouchPressed#element');
    }

    element.value.addEventListener('touchstart', onTouchstart);
    element.value.addEventListener('touchend', onTouchEnd);
    element.value.addEventListener('touchmove', onTouchMove);

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }

      element.value?.removeEventListener('touchstart', onTouchstart);
      element.value?.removeEventListener('touchend', onTouchEnd);
      element.value?.removeEventListener('touchmove', onTouchMove);
    };
  });
}
