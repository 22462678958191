<template>
  <root-video-z-index-layer
    data-video-interaction
    :class="[$style.controlsLayer, $style.smartVideoControlsTimelineLayer]"
  >
    <div v-if="isTimelinePlaybackShown" ref="el" :class="$style.timeline">
      <timeline-playback-buttons v-show="isTimelinePlaybackButtonsShown" />
      <timeline-playback
        v-show="isControlsShown && isControlsPlaybackShown"
        :ref="(comp) => (timelineEl = comp?.$el)"
        :current-time="normalizedDisplayedCurrentTime"
        :duration="normalizedDuration"
        :buffer-length="bufferInfo.length"
        :buffer-start="bufferInfo.start"
        :is-timeline-hover-handle-shown="false"
        :is-timeline-playback-buttons-shown="true"
        :current-handle-position="currentHandlePosition"
        :class="$style.playback"
      />
      <vod-playback-time-view
        v-show="isShownPlaybackTime && isControlsShown && isControlsPlaybackShown"
        :duration-css-class-name="$style.durationCssClassName"
        :current-time-css-class-name="$style.currentTimeCssClassName"
      />
    </div>
    <transition :name="transitionName">
      <smart-tv-video-controls v-if="isControlsShown && isControlsPlaybackShown" />
    </transition>
    <transition :name="transitionName">
      <kinom-metadata-player-controller
        v-if="isKinomMetadataControllerShown"
        :buffer-length="0"
        :buffer-start="0"
        :loop="loop"
      />
    </transition>
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import { DisposableStore } from '@package/sdk/src/core';
import { TimeSeconds } from '@PLAYER/player/base/number';
import VodPlaybackTimeView from '@PLAYER/player/components/controls/VodPlaybackTimeView.vue';
import KinomMetadataPlayerController from '@PLAYER/player/components/timeline/KinomMetadataPlayerController.vue';
import TimelinePlayback from '@PLAYER/player/components/timeline/TimelinePlayback.vue';
import TimelinePlaybackButtons from '@PLAYER/player/components/timeline/TimelinePlaybackButtons.vue';
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import useStreamingAnalytics from '@PLAYER/player/modules/analytics/use-streaming-analytics';
import useTimelineAnalytics from '@PLAYER/player/modules/analytics/use-timeline-analytics';
import useTransitionName from '@PLAYER/player/modules/animation/use-transition-name';
import usePlaybackVariables from '@PLAYER/player/modules/hooks/use-playback-variables';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import useVideoTimeline from '@PLAYER/player/modules/timeline/use-video-timeline';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import SmartTvVideoControls from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControls.vue';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';
import { storeToRefs } from 'pinia';
import { getCurrentInstance, nextTick, onBeforeUnmount, onMounted, onUnmounted, provide, ref, watch } from 'vue';

defineProps<{
  loop: boolean;
}>();

const videoUIStore = useVideoUIStore();
const videoInteractions = useVideoInteractions();
const playbackAnalytics = usePlaybackAnalytics();
const playbackActions = usePlaybackActions();

const { isLive } = useProjector();

const { transitionName } = useTransitionName();
const { bufferInfo, prevDisplayedCurrentTime, isPlaying } = storeToRefs(videoUIStore);
const { isTimelinePlaybackShown, isKinomMetadataControllerShown } = usePlaybackVariables();
const { isControlsShown, isShownPlaybackTime, isTimelinePlaybackButtonsShown, isControlsPlaybackShown } =
  useVideoControlsVariables();

const disposableStore = new DisposableStore();

const { sendAutoStreamingEvent } = useStreamingAnalytics();
const { normalizedDuration, normalizedDisplayedCurrentTime, isCustomMediaTech } = useVideoPlayerVariables();

const timelineEl = ref<HTMLElement>();

const { useNavigatable, PlayerFocusKeys, SpatialNavigation } = useSmartNavigatable();

const { el, focusSelf, focused, addFocusable, removeFocusable } = useNavigatable({
  focusKey: PlayerFocusKeys?.PLAYER_TIMELINE,
  onEnterPress: () => {
    playbackActions.togglePlayback();
  },
});

provide('timelineFocused', focused);

watch(
  () => isTimelinePlaybackShown.value,
  async (value) => {
    if (value) {
      await nextTick();
      addFocusable();

      focusSelf();
      return;
    }

    removeFocusable();
  },
);

watch(
  () => isControlsShown.value && isControlsPlaybackShown.value,
  () => {
    if (SpatialNavigation?.getCurrentFocusKey() === PlayerFocusKeys.PLAYER_TIMELINE) {
      return;
    }

    SpatialNavigation?.setFocus(PlayerFocusKeys.PLAYER_PLAY_BUTTON);
  },
);

const { customManifestFragmentStart } = storeToRefs(useManifestStore());

const { currentHandlePosition, lastTimeChangedManually } = useVideoTimeline(timelineEl, {
  onStartCallback: () => {
    if (isPlaying.value) {
      sendAutoStreamingEvent();
    }

    playbackAnalytics.setFinishTimeStreaming(normalizedDisplayedCurrentTime.value);
  },
  pause: videoInteractions.pause,
  play: videoInteractions.play,
  changeCurrentTime: (time: TimeSeconds) => {
    focusSelf();

    if (isLive.value && isCustomMediaTech.value) {
      return videoInteractions.changeCurrentTime({
        seconds: time + customManifestFragmentStart.value,
        manually: true,
      });
    }

    videoInteractions.changeCurrentTime({
      seconds: time,
      manually: true,
    });
  },
  duration: normalizedDuration,
  currentTime: normalizedDisplayedCurrentTime,
  isPlaying,
});

const timelineAnalytics = useTimelineAnalytics();

watch(lastTimeChangedManually, (val) => {
  // Обнуляем информацию о буффере.
  videoUIStore.setBufferInfo({ length: 0, start: 0 });
  timelineAnalytics.onTimelineTimeChangeManually(val, prevDisplayedCurrentTime.value);
});

onBeforeUnmount(() => disposableStore.dispose());
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.controlsLayer {
  bottom: adjust.adjustPx(48px);
  z-index: var(--z-index-video-controls);
  display: flex;
  flex-direction: column;
  padding: 0 adjust.adjustPx(32px);
  width: 100%;
}

.smartVideoControlsTimelineLayer {
  --timeline-playback-offset: 100px;
  --timeline-playback-buttons-bottom-pos: calc(var(--g-spacing-24) + var(--timeline-playback-offset));
}

.currentTimeCssClassName {
  @include smartTvFonts.SmartTvCaption-1();
}

.durationCssClassName {
  @include smartTvFonts.SmartTvCaption-1();
}

.playback {
  margin-right: var(--g-spacing-12);
  height: 0;
}

.timeline {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  margin-bottom: var(--g-spacing-12);
}
</style>
