<template>
  <teleport to="#app">
    <section ref="el" :class="$style.filtersModal">
      <header :class="$style.header">
        <NavigatableItem
          :active-class="$style.active"
          :class="$style.closeButton"
          :tag="AppButton"
          transition-duration="0.1s"
          @click="emit('close')"
        >
          <template #icon>
            <IconClose />
          </template>
        </NavigatableItem>

        <span>{{ $t('pages.catalog.filters') }}</span>
      </header>

      <div :class="$style.filters">
        <div :class="$style.genres">
          <div :class="$style.filterHeader">{{ $t('pages.catalog.genres') }}</div>
          <ScrollViewport
            ref="genresScroll"
            :class="$style.scroll"
            orientation="vertical"
            @vue:mounted="onGenresVNodeMounted"
          >
            <ColumnGrid v-slot="{ item }" :items="filters.genres" :column-number="2">
              <NavigatableItem
                :class="$style.filter"
                :tag="FilterButton"
                :text="item.title"
                :active-class="$style.active"
                :variation="'smart-button-secondary'"
                :selected="item.selected"
                transition-duration="0.1s"
                @active="onScroll('genres', $event)"
                @click="emit('filter:select', item)"
              />
            </ColumnGrid>
          </ScrollViewport>
        </div>
        <template v-if="isGenresMounted">
          <div :class="$style.delimiter" />
          <div :class="$style.countries">
            <div :class="$style.filterHeader">{{ $t('pages.catalog.country') }}</div>
            <ScrollViewport ref="countriesScroll" :class="$style.scroll" orientation="vertical">
              <ColumnGrid v-slot="{ item }" :items="filters.countries" :column-number="2">
                <NavigatableItem
                  :class="$style.filter"
                  :tag="FilterButton"
                  :text="item.title"
                  :active-class="$style.active"
                  :variation="'smart-button-secondary'"
                  :selected="item.selected"
                  transition-duration="0.1s"
                  @active="onScroll('countries', $event)"
                  @click="emit('filter:select', item)"
                />
              </ColumnGrid>
            </ScrollViewport>
          </div>
          <div :class="$style.delimiter" />
          <div :class="$style.periods">
            <div :class="$style.filterHeader">{{ $t('pages.catalog.periods') }}</div>
            <ScrollViewport ref="periodsScroll" :class="$style.scroll" orientation="vertical">
              <ColumnGrid v-slot="{ item }" :items="filters.periods" :column-number="1">
                <NavigatableItem
                  :class="$style.filter"
                  :tag="FilterButton"
                  :text="item.title"
                  :active-class="$style.active"
                  :variation="'smart-button-secondary'"
                  :selected="item.selected"
                  transition-duration="0.1s"
                  @active="onScroll('periods', $event)"
                  @click="emit('filter:select', item)"
                />
              </ColumnGrid>
            </ScrollViewport>
          </div>
        </template>
      </div>
    </section>
  </teleport>
</template>

<script setup lang="ts">
import { FilterItem } from '@package/sdk/src/api';
import { customEventsService, scrollToElement } from '@package/smarttv-base/src';
import useVNodeMounted from '@package/smarttv-base/src/utils/use-vnode-mounted';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconClose from '@SMART/assets/icons/33x33/close.svg';
import { FocusKeys } from '@SMART/index';
import { onMounted, onUnmounted, provide, Ref, ref, useTemplateRef } from 'vue';

import AppButton from '@/components/app-button/AppButton.vue';
import ColumnGrid from '@/components/grid/ColumnGrid.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import { MappedFilters } from './ContentSelect.vue';
import FilterButton from './FilterButton.vue';

interface Props {
  filters: MappedFilters;
}

defineProps<Props>();

const emit = defineEmits<{
  (event: 'filter:select', item: FilterItem): void;
  (event: 'close'): void;
}>();

const { el, focusKey, focusSelf } = useNavigatable({
  focusKey: FocusKeys.FILTERS_MODAL,
  isFocusBoundary: true,
});
provide('parentFocusKey', focusKey.value);

const { isVNodeMounted: isGenresMounted, onVNodeMounted: onGenresVNodeMounted } = useVNodeMounted({
  withTimeout: false,
});

const genresScroll = useTemplateRef('genresScroll');
const countriesScroll = useTemplateRef('countriesScroll');
const periodsScroll = useTemplateRef('periodsScroll');

const scrolls: { [index: string]: typeof genresScroll } = {
  genresScroll,
  countriesScroll,
  periodsScroll,
};

const onScroll = (type: string, item?: HTMLElement) => {
  scrollToElement(scrolls[type + 'Scroll'].value?.$el, { top: item?.offsetTop });
};

onMounted(async () => {
  focusSelf();

  customEventsService.setOnPressBackCallback(() => {
    emit('close');
  }, true);
});

onUnmounted(async () => {
  customEventsService.setOnPressBackCallback();
  SpatialNavigation.setFocus(FocusKeys.CATALOG_FILTERS_BUTTON);
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.filtersModal {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  height: adjust.adjustPx(700px);
  border-radius: adjust.adjustPx(40px) adjust.adjustPx(40px) 0 0;
  background-color: var(--color-bg-modal);
  flex-direction: column;

  padding: {
    top: adjust.adjustPx(40px);
    left: adjust.adjustPx(32px);
    right: adjust.adjustPx(32px);
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: adjust.adjustPx(40px);
  min-height: adjust.adjustPx(88px);

  @include smartTvFonts.SmartTvTitle-3();

  .closeButton {
    position: absolute;
    left: 0;
    width: adjust.adjustPx(88px);
    height: adjust.adjustPx(88px);
  }
}

.filters {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.filterHeader {
  margin-bottom: adjust.adjustPx(32px);
  padding: 0 adjust.adjustPx(24px);

  @include smartTvFonts.SmartTvSubtitle-1();
}

.scroll {
  height: calc(100% - adjust.adjustPx(96px));
}

.active {
  background-color: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}

.filter {
  &:hover {
    background-color: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
  }
}

.genres {
  width: 40%;
  max-width: 40%;
  height: adjust.adjustPx(532px);
  overflow: hidden;
  padding-right: adjust.adjustPx(40px);
}

.countries {
  padding: 0 adjust.adjustPx(40px);
  width: 40%;
  max-width: 40%;
  height: adjust.adjustPx(532px);
  overflow: hidden;
}

.periods {
  width: 20%;
  max-width: 20%;
  height: adjust.adjustPx(532px);
  overflow: hidden;
  padding-left: adjust.adjustPx(40px);
}

.delimiter {
  width: 2px;
  height: adjust.adjustPx(500px);
  background-color: var(--color-stroke-div-primary);
}
</style>
