import useLogger from '@package/logger/src/use-logger';
import { ensurePromiseExist, isMobile } from '@PLAYER/player/base/dom';

const logger = useLogger('use-fullscreen-actions', 'media-player');

const exitFullScreen = async () => {
  try {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }

    if (!isMobile) {
      return;
    }

    const promise = ensurePromiseExist(window.screen.orientation.lock('portrait'));

    if (promise) {
      await promise;
    }
  } catch (error) {
    logger.error(error);
  }
};

export default function useFullscreenActions() {
  return {
    exitFullScreen,
  };
}
