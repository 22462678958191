import {
  DesktopKeyCode,
  TizenKeyCode,
  TvKeyCode,
  UnexpectedComponentStateError,
  WebOSKeyCode,
} from '@package/sdk/src/core';
import { isTizenOS, isWebOS } from '@PLAYER/player/base/dom';

export default function getSmartTVKeyWithPlatform(code: TvKeyCode): number {
  let keyCode: number | undefined;

  switch (true) {
    case isWebOS:
      keyCode = WebOSKeyCode.get(code);
      break;
    case isTizenOS:
      keyCode = TizenKeyCode.get(code);
      break;
    default:
      keyCode = DesktopKeyCode.get(code);
  }

  if (!keyCode) {
    throw new UnexpectedComponentStateError('keyCode');
  }

  return keyCode;
}
