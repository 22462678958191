<template>
  <div :class="$style.console">
    <div :class="$style.header">
      <span :class="$style.title">{{ $t('debug.terminal') }} - {{ $t('debug.terminal.console') }}</span>
      <app-checkbox v-model:input="isCurrentPlayerModel" :text="$t('debug.console.playerFilter')" />
      <app-input v-model:input="filter" type="text" :placeholder="$t('debug.filter')" />
      <app-button :on-click="onShowLogs" variation="button-secondary" :text="$t('debug.logs.hide')" />
    </div>
    <div :class="$style.body">
      <div v-for="log in messages" :key="nanoid(5) + log.date" :class="$style.message">
        <span
          :class="{
            [$style.message]: true,
            [$style.warn]: log.type === 'warn',
            [$style.success]: log.type === 'success',
            [$style.info]: log.type === 'info',
            [$style.error]: log.type === 'error',
          }"
        >
          {{ log.tag || 'Unknown' }}: {{ log.type }} - {{ log.args.map((log) => JSON.stringify(log)).join(', ') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useLogger, { LoggerEmitter } from '@package/logger/src/use-logger';
import { DisposableStore, KeyCode } from '@package/sdk/src/core';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import AppCheckbox from '@PLAYER/player/components/ui/AppCheckbox.vue';
import AppInput from '@PLAYER/player/components/ui/AppInput.vue';
import useKeyboardHandler from '@PLAYER/player/modules/hooks/use-keyboard-handler';
import useInstanceId from '@PLAYER/player/modules/instance/use-instance-id';
import { nanoid } from 'nanoid';
import { computed, onBeforeUnmount, ref } from 'vue';

const logger = useLogger('DebugInformationConsole.vue', 'media-player');
const keyboard = useKeyboardHandler();
const currentPlayerId = useInstanceId();

const emit = defineEmits<{
  (event: 'on-show-logs'): void;
}>();

type Log = (typeof LoggerEmitter.messages)[0];

const MAXIMUM_MESSAGES_SIZE = 1000;

const onShowLogs = () => emit('on-show-logs');
const isMediaPlayerLog = (log: Log) => log.tag.includes('media-player');
const getFilteredLogs = () => LoggerEmitter.messages.filter(isMediaPlayerLog);

const disposableStore = new DisposableStore();
disposableStore.add(keyboard.on(KeyCode.Escape, onShowLogs));

const logMessages = ref<Log[]>(getFilteredLogs());

LoggerEmitter.on('log', (log) => {
  if (!isMediaPlayerLog(log)) {
    return;
  }

  logMessages.value.unshift(log);
});

const filter = ref('');
const isCurrentPlayerModel = ref(true);

const messages = computed<Log[]>(() => {
  if (!filter.value) {
    return logMessages.value;
  }

  return logMessages.value.filter((log) => {
    return log.message?.toLowerCase().includes(filter.value.toLowerCase());
  });
});

onBeforeUnmount(() => {
  disposableStore.dispose();
});
</script>

<style lang="scss" module>
.console {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  margin-right: auto;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}

@supports (position: sticky) {
  .header {
    position: sticky;
    top: -20px;
  }
}

.message {
  background-color: inherit !important;
  font-weight: lighter;
  font-size: 16px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.success {
  color: green;
}

.info {
  color: cornflowerblue;
}

.error {
  color: red;
}

.warn {
  color: orange;
}
</style>
