import type { ApiMedia } from '@package/sdk/src/api/content/content-types/media';
import { MediaMapper } from '@package/sdk/src/api/content/media';
import type { Media } from '@package/sdk/src/api/content/types/media';
import { ENDPOINTS } from '@package/sdk/src/api/endpoints';
import { formatTextWithSlice } from '@package/sdk/src/core';

import type { DeviceService } from '../device/device-service';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import type {
  ApiHistory,
  History,
  SearchByTextParams,
  SearchHistoryParams,
  SetSearchHistoryParams,
} from './search-types';

export class SearchService {
  constructor(
    private readonly $http: RequestService,
    private readonly $device: DeviceService,
  ) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async searchByText({ text, content, page = 1, limit = 9, perPage = 9 }: SearchByTextParams): Promise<Media[]> {
    const { data } = await this.$http.request<ApiMedia[]>(
      {
        method: HTTPRequestMethod.Get,
        url: ENDPOINTS.CONTENT_SEARCH_SEMANTIC,
        query: {
          page,
          limit,
          term: text,
          per_page: perPage,
          content_type: content,
          fully_typed_query: true,
          visitor_id: this.$device.getVisitorId(),
          device_type: (await this.$device.getDevice()).type,
        },
      },
      { withToken: true, skipTokenValidation: true },
    );

    return MediaMapper.mapMany(data);
  }

  public async getSearchHistory({ page, perPage }: SearchHistoryParams): Promise<History[]> {
    try {
      const { data = [] } = await this.$http.request<ApiHistory[]>(
        {
          method: HTTPRequestMethod.Get,
          url: ENDPOINTS.SEARCH_HISTORIES,
          query: {
            page,
            per_page: perPage,
          },
        },
        { withToken: true },
      );

      return data.map((item) => ({
        createdAt: item.created_at,
        criterias: item.criterias,
        query: formatTextWithSlice(item.query),
        resultMeter: item.result_meter,
        searchType: item.search_type,
        userId: item.user_id,
        visitorId: item.visitor_id,
      }));
    } catch (e) {
      return [];
    }
  }

  public async setSearchHistory({ query, resultCount }: SetSearchHistoryParams): Promise<void> {
    await this.$http.request(
      {
        method: HTTPRequestMethod.Post,
        url: ENDPOINTS.SEARCH_HISTORIES,
        data: {
          query,
          result_count: resultCount,
        },
      },
      { withToken: true },
    );
  }
}
