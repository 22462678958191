import useLogger from '@package/logger/src/use-logger';

const logger = useLogger('vibrate');

export function vibrate(pattern: VibratePattern): void {
  try {
    window.navigator.vibrate?.(pattern);
  } catch (e) {
    // it is ok
    logger.error(e);
  }
}
