import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import { watchOnce } from '@vueuse/core';
import { storeToRefs } from 'pinia';

export default function onInitialPlaybackStarted(callback: (isPlaying: boolean) => void) {
  const { isPlaying } = storeToRefs(useVideoUIStore());

  if (isPlaying.value) {
    return Reflect.apply(callback, undefined, [true]);
  }

  watchOnce(isPlaying, callback);
}
