<template>
  <div :class="$style.skeleton"></div>
</template>

<script setup lang="ts"></script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.skeleton {
  width: adjustPx(500px);
  height: adjustPx(80px);
  border-radius: adjustPx(16px);
  background: linear-gradient(
      90.17deg,
      rgba(0, 0, 0, 0.0138889) 0.16%,
      rgba(0, 12, 14, 0.2) 45.21%,
      rgba(0, 0, 0, 0.2) 52.24%,
      rgba(0, 0, 0, 0) 99.87%
    ),
    var(--color-bg-primary);
  min-height: adjustPx(80px);
  margin-top: adjustPx(16px);
  margin-bottom: adjustPx(16px);
}
</style>
