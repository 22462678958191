import { Disposable } from '@package/sdk/src/core';

export default class VideoPlayerEvent<T> extends Disposable {
  private _defaultPrevented = false;

  protected constructor(public readonly data: T) {
    super();
  }

  public get defaultPrevented(): boolean {
    return this._defaultPrevented;
  }

  public preventDefault(): void {
    this._defaultPrevented = true;
  }

  public toString() {
    try {
      return JSON.stringify(this.data);
    } catch {
      return String(this.data);
    }
  }

  public [Symbol.toPrimitive](hint: 'string' | 'number' | 'default'): unknown {
    if (hint === 'string') {
      try {
        return JSON.stringify(this.data);
      } catch {
        return String(this.data);
      }
    }

    return true;
  }
}
