import { LinkedMap, Touch } from './linked-map';

export class LRUCache<K, V> extends LinkedMap<K, V> {
  private _limit: number;
  private _ratio: number;

  constructor(limit: number, ratio = 1) {
    super();
    this._limit = limit;
    this._ratio = Math.min(Math.max(0, ratio), 1);
  }

  public get limit(): number {
    return this._limit;
  }

  public set limit(limit: number) {
    this._limit = limit;
    this.checkTrim();
  }

  public get ratio(): number {
    return this._ratio;
  }

  public set ratio(ratio: number) {
    this._ratio = Math.min(Math.max(0, ratio), 1);
    this.checkTrim();
  }

  public get(key: K): V | undefined {
    return super.get(key, Touch.AsNew);
  }

  public peek(key: K): V | undefined {
    return super.get(key, Touch.None);
  }

  public set(key: K, value: V): void {
    super.set(key, value, Touch.AsNew);
    this.checkTrim();
  }

  private checkTrim() {
    if (this.size > this._limit) {
      this.trimOld(Math.round(this._limit * this._ratio));
    }
  }
}
