import { ProcessingMapper } from '../base/processing-mapper';
import { DeviceInfo } from '../environment/device';
import { ParentalControlStatus } from '../profiles/types/profile';
import { ApiDeviceInfo } from './device';

export class DeviceInfoMapper {
  static mapMany(infos: ApiDeviceInfo[]): DeviceInfo[] {
    return ProcessingMapper.process(infos.map(DeviceInfoMapper.map));
  }

  static map({ parental_control_status, app_version, push_token, ...rest }: ApiDeviceInfo): DeviceInfo {
    return ProcessingMapper.process({
      appVersion: app_version,
      pushToken: push_token,
      parentalControlStatus: parental_control_status || ParentalControlStatus.NotSet,
      ...rest,
    });
  }
}
