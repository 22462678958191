<template>
  <smart-tv-video-control-button
    :class="{
      [$style.live]: true,
      [$style.liveSmartTv]: isSmartTV,
    }"
    :data-tid="getTestElementIdentifier(ElementTestIdentifierScope.Common, 'toLiveControl')"
    :text="text"
    :is-text-shown="true"
    :on-click="onSeekToLive"
  >
    <span
      :class="{
        [$style.point]: true,
        [$style.pointLive]: isCurrentTimeLive,
      }"
    />
    <span>
      {{ text }}
    </span>
  </smart-tv-video-control-button>
</template>

<script lang="ts" setup>
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { translate } from '@PLAYER/player/modules/localization/translate';
import {
  ElementTestIdentifierScope,
  getTestElementIdentifier,
} from '@PLAYER/player/modules/test/get-test-element-identifier';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import { computed } from 'vue';

const { isCurrentTimeLive, onSeekToLive } = defineProps<{
  isCurrentTimeLive: boolean;
  onSeekToLive: VoidFunction;
}>();

const { isSmartTV } = usePlatform();

const text = computed(() => (isCurrentTimeLive ? translate('controls.live') : translate('controls.goToLive')));
</script>

<style module lang="scss">
.live {
  --color-bg-negative-30: rgba(249, 77, 87, 0.3);
  min-width: 65px;
}

.liveSmartTv {
  text-transform: initial;
}

@keyframes pulse-1 {
  from {
    box-shadow: 0 0 0px 0px var(--color-bg-negative-30);
  }
  to {
    box-shadow: 0 0 0px 8px var(--color-bg-negative-30);
  }
}

@keyframes pulse-2 {
  from {
    box-shadow: 0 0 0px 0px var(--color-bg-negative);
  }
  to {
    box-shadow: 0 0 0px 4px var(--color-bg-negative);
  }
}

.point {
  position: relative;
  width: 7px;
  height: 7px;
  transform: scale(0.5);

  &::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--color-notheme-text-primary);
  }

  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--color-notheme-text-primary);
  }
}

.pointLive {
  &::before {
    background-color: var(--color-bg-negative);
    animation: pulse-1 1s 1s infinite linear alternate;
  }

  &::after {
    background-color: var(--color-bg-negative);
    animation: pulse-2 1s 1s infinite linear alternate;
  }
}
</style>
