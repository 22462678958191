import { ENDPOINTS } from '@package/sdk/src/api/endpoints';
import type { FeatureToggleItem } from '@package/sdk/src/api/feature-toggle/types/feature';
import { defaultFeatureToggleValues, FeatureToggle } from '@package/sdk/src/api/feature-toggle/types/feature-toggle';

import type { EnvironmentService } from '../environment/environment-service';
import type { RequestService } from '../request-service';

export class FeatureService {
  private featureFlags: FeatureToggleItem[] = [];

  constructor(
    private readonly $http: RequestService,
    private readonly $environment: EnvironmentService,
  ) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public getFeatureFlag(name: FeatureToggle): FeatureToggleItem {
    const flag = this.featureFlags.find((feature) => feature.name === name);

    if (flag) {
      return flag;
    }

    return defaultFeatureToggleValues.find((feature) => feature.name) as FeatureToggleItem;
  }

  public async fetchFeatureFlags() {
    const { data } = await this.$http.request<FeatureToggleItem[]>(
      {
        url: ENDPOINTS.FLAGS_TOGGLES,
        method: 'GET',
        baseURL: this.$environment.getVariable('apiFlagsBaseURL'),
        query: {
          with_variant: true,
        },
      },
      {
        withToken: true,
        skipTokenValidation: true,
      },
    );

    this.featureFlags = data;
  }
}
