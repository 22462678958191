import useRootVideoElBounds from '@PLAYER/player/modules/video/use-root-video-el-bounds';
import { computed } from 'vue';

export default function useOrientation() {
  const { appWidth, appHeight } = useRootVideoElBounds();

  const isLandscape = computed(() => appWidth.value > appHeight.value);
  const isPortrait = computed(() => !isLandscape.value);

  return {
    isLandscape,
    isPortrait,
  };
}
