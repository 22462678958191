import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { useBattery } from '@vueuse/core';
import { computed } from 'vue';

export default function useDeviceBattery() {
  const { isSupported, level, dischargingTime, chargingTime, charging } = useBattery();

  const isLowBatteryMode = computed(
    () => level.value <= ConstantsConfigPlayer.getProperty('lowBatteryModeThresholdLevel'),
  );

  return {
    isBatteryApiSupported: isSupported,
    batteryLevel: level,
    isLowBatteryMode,
    batteryDischargingTime: dischargingTime,
  };
}
