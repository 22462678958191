import { ProcessingMapper } from '../base/processing-mapper';
import { WatchingItem } from './types/watching-item';
import { ApiWatchingItem } from './watching-item-types';

export class WatchingItemMapper {
  static mapMany(items: ApiWatchingItem[]): WatchingItem[] {
    return ProcessingMapper.process(items.map(WatchingItemMapper.map), { freezeData: false });
  }

  static map({
    updated_at,
    serial_title,
    serial_title_en,
    content_id,
    season_number,
    episode_number,
    ...rest
  }: ApiWatchingItem): WatchingItem {
    return ProcessingMapper.process(
      {
        updatedAt: updated_at,
        serialTitle: serial_title,
        serialTitleEn: serial_title_en,
        contentId: content_id,
        seasonNumber: season_number,
        episodeNumber: episode_number,
        ...rest,
      },
      { freezeData: false },
    );
  }
}
