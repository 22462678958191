import { ProcessingMapper } from '../base/processing-mapper';
import { ApiGenresBeltItem } from './content-types/genres-belt';
import { GenresBeltItem } from './types/genres-belt';

export class GenreBeltItemMapper {
  static mapMany(items: ApiGenresBeltItem[]): GenresBeltItem[] {
    return ProcessingMapper.process(items.map(GenreBeltItemMapper.map));
  }

  static map({ background_color, genres_slugs, ...rest }: ApiGenresBeltItem): GenresBeltItem {
    return ProcessingMapper.process({
      ...rest,
      genresSlugs: genres_slugs,
      backgroundColor: background_color,
    });
  }
}
