import useLogger from '@package/logger/src/use-logger';
import {
  Disposable,
  EventEmitter,
  IDisposable,
  isNumber,
  isObject,
  isString,
  UnexpectedComponentStateError,
} from '@package/sdk/src/core';
import { ensurePromiseExist, isServer } from '@PLAYER/player/base/dom';
import { AnyFunction } from '@PLAYER/player/base/function';
import { VideoPlayerHTML5Error } from '@PLAYER/player/errors/video-player-html5-error';
import { ExternalQualityLevel } from '@PLAYER/player/modules/event/internal-event';
import { ExternalEventMap } from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import patchVueApp from '@PLAYER/player/modules/global/patch-vue-app';
import { EnvironmentMode } from '@PLAYER/player/modules/global/use-environment';
import {
  PlayerGlobalProperty,
  PlayerInstanceConstructorOptions,
  PlayerInstanceLoadSourceOptions,
  PlayerInstancePauseCommandOptions,
  PlayerInstancePlayCommandOptions,
  PlayerInstanceSeekCommandOptions,
} from '@PLAYER/player/modules/instance/interfaces';
import {
  AppLanguageManager,
  AppPlayerCurrency,
  AppPlayerLanguage,
} from '@PLAYER/player/modules/localization/translate';
import type { MediaPlayerPlugin } from '@PLAYER/player/modules/plugin/media-player-plugin';
import type { UserSession } from '@PLAYER/player/modules/session/user';
import type { VideoConfig } from '@PLAYER/player/modules/video/use-video-config';
import { isClient } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { App, Component, createApp, reactive, Ref, ref } from 'vue';
import { renderToString } from 'vue/server-renderer';

import { version } from './../../../../package.json';

const logger = useLogger('player', 'media-player');
logger.level = -999;

function createPlayerTracker() {
  const _trackedPlayers = new Set();

  return {
    get trackedPlayers() {
      return _trackedPlayers;
    },
    trackPlayer(player: VijuPlayer) {
      _trackedPlayers.add(player);
    },
    untrackPlayer(player: VijuPlayer) {
      _trackedPlayers.delete(player);
    },
  };
}

const playersTracker = createPlayerTracker();

if (isClient) {
  console.info('%c INFO', 'color: #33f', 'viju video player version - ' + version);
}

export type CustomComponentMap = Record<string, Component>;

let currentPlayer: VijuPlayer | undefined;

const onFullscreenError = (error: Error) => {
  if (error instanceof VideoPlayerHTML5Error) {
    throw error;
  }
};

/**
 * @author Teodor_Dre <swen295@gmail.com>
 * @class
 * @abstract
 *
 * @description
 * Abstract class of player. Don't have own UI.
 * All players that have UI, must extend from this class
 *
 * @extends Disposable
 */
export abstract class VijuPlayer extends Disposable {
  public readonly id: string = nanoid(3);

  #app: App<Element>;
  readonly #emitter: EventEmitter<ExternalEventMap> = new EventEmitter();
  readonly #videoConfig = reactive<VideoConfig>({});
  readonly #options: PlayerInstanceConstructorOptions;

  public get app(): App<Element> {
    return this.#app;
  }

  public get el(): Element | null {
    return this.#app._container;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @property videoEl
   * @description
   * Direct ref to video element. Shouldn't be used to interact with player.
   * Use built-in API
   *
   * @deprecated
   * @public
   *
   * @returns HTMLVideoElement */
  public get videoEl(): HTMLVideoElement {
    return this.#app.config.globalProperties[PlayerGlobalProperty.VideoEl];
  }

  /**
   *
   * @param {PlayerInstanceConstructorOptions} options
   * @param {App<Element>} root
   * @param components
   * @protected
   */
  protected constructor(
    options: PlayerInstanceConstructorOptions,
    private readonly root: App<Element>,
    private readonly components?: CustomComponentMap,
  ) {
    super();

    this.#options = options;
    this.#createApp(root, options, components);
  }

  protected abstract registerComponents(app: App<Element>): void;

  protected registerComponent(name: string, component: Component): this {
    this.#app.component(name, component);

    return this;
  }

  #providePayload(): void {
    this.#app.provide('app.player.id', this.id);
    this.#app.provide('app.config', this.#options);
    this.#app.provide('app.emitter.external', this.#emitter);

    this.#app.provide('payload.videoConfig', this.#videoConfig);

    this.#app.provide('payload.session', VijuPlayer.#session);
    this.#app.provide('payload.environment', VijuPlayer.#environment);
    this.#app.provide('app.plugins', VijuPlayer.#plugins);
    this.#app.provide('trackedPlayers', playersTracker.trackedPlayers);
  }

  #getExternalProperty(key: PlayerGlobalProperty): AnyFunction {
    const properties = this.#app.config.globalProperties;
    const property = properties[key];

    if (!property) {
      throw new UnexpectedComponentStateError(key);
    }

    return property;
  }

  /**
   *
   *
   * @param {App<Element>} root
   * @param _
   * @param components
   * @private
   */
  #createApp(root: App<Element>, _: PlayerInstanceConstructorOptions, components?: CustomComponentMap) {
    this.#app = createApp(root);

    if (components) {
      const entries = Object.entries(components);
      for (const [name, component] of entries) {
        this.#app.component(name, component);
      }
    }

    this.#providePayload();
    this.registerComponents(this.#app);

    patchVueApp(this.#app, { $isNativeTitleTooltipShown: false });
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Indicates, does player mount to DOM or not. Video can be not loaded yet.
   *
   * @returns {boolean}
   */
  public get mounted(): boolean {
    try {
      return Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.PlayerMounted), undefined, []) as boolean;
    } catch (error) {
      return false;
    }
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Current time of player. Return 0 if player is not mounted yet.
   *
   * @returns {number}
   */
  public get currentTime(): number {
    return this.videoEl?.currentTime || 0;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Duration of current video inside player
   *
   * @return {number}
   */
  public get duration(): number {
    return this.videoEl?.duration || 0;
  }

  public get src(): string | undefined {
    try {
      const src = Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.GetSourceLink), undefined, []) as
        | string
        | undefined;

      logger.info('src', 'success');

      return src;
    } catch (error) {
      logger.error(error);
      return undefined;
    }
  }

  /**
   * @description
   *  Return boolean flag, that indicates does this player open if fullscreen mode or not.
   *
   * @returns {boolean}
   */
  public get isFullscreen(): boolean {
    try {
      const isFullscreen = Reflect.apply(
        this.#getExternalProperty(PlayerGlobalProperty.GetFullscreenState),
        undefined,
        [],
      ) as boolean;

      logger.info('isFullscreen', 'success');

      return isFullscreen;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }

  public get isPictureInPictureEnabled(): boolean {
    try {
      const isPictureInPictureEnabled = Reflect.apply(
        this.#getExternalProperty(PlayerGlobalProperty.GetPictureInPictureState),
        undefined,
        [],
      ) as boolean;

      logger.info('isPictureInPictureEnabled', 'success');

      return isPictureInPictureEnabled;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Indicates, does video muted or have 0 volume level.
   *
   * @returns {boolean}
   */
  public get muted(): boolean {
    return this.videoEl?.muted || this.videoEl?.volume === 0 || true;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   * Remove player from DOM. But, you can still mount this player to another element.
   * Event listeners are not disposed.
   *
   * @returns {this}
   */
  public unmount(): this {
    this.#app.config.globalProperties[PlayerGlobalProperty.PlayerMounted] = undefined;
    this.#app.unmount();
    this.#emitter.emit('unmounted');

    if (currentPlayer === this) {
      currentPlayer = undefined;
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   * Mount player to DOM. Selector must be valid to use with document.querySelector() API.
   *
   * @param {HTMLElement | string} elementOrSelector - string of ref to DOM element.
   * @returns {this}
   */
  public mount(elementOrSelector: HTMLElement | string): this {
    if (isServer) {
      return this;
    }

    const element = isString(elementOrSelector) ? window.document.querySelector(elementOrSelector) : elementOrSelector;

    if (!element) {
      throw new UnexpectedComponentStateError('Player.element');
    }

    if (this.#app) {
      this.#createApp(this.root, this.#options, this.components);
    }

    this.#app.mount(element);

    if (isClient) {
      playersTracker.trackPlayer(this);
    }

    if (
      this.#options.projector === 'vod' ||
      this.#options.projector === 'live' ||
      this.#options.projector === 'my-channel'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      currentPlayer = this;
    }

    return this;
  }

  public startMediaSession(): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.StartMediaSession), undefined, []);
    } catch (error) {
      logger.error('startMediaSession', error);
    }

    return this;
  }

  public endMediaSession(): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.EndMediaSession), undefined, []);
    } catch (error) {
      logger.error('endMediaSession', error);
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @throws {VideoPlayerHTML5Error}
   *
   * @description
   * Open this video in fullscreen.
   *
   * @returns {this}
   */
  public async requestFullscreen(): Promise<this> {
    try {
      const promise = ensurePromiseExist(
        Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.RequestFullscreen), undefined, []),
      );

      if (promise) {
        await promise;

        logger.info('requestFullscreen', 'success');
      }
    } catch (error) {
      onFullscreenError(error);
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Close this video from fullscreen
   *
   * @return {this}
   */
  public closeFullscreen(): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.ExitFullscreen), undefined, []);

      logger.info('exitFullscreen', 'success');
    } catch (error) {
      logger.error('exitFullscreen', error);
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   * Set quality to video. If you set quality - it will be stayed with video all time.
   * Use it carefully.
   *
   * By default - auto mode is settled.
   *
   * @param {ExternalQualityLevel} quality
   */
  public setQuality(quality: ExternalQualityLevel): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.SetQualityLevel), undefined, [quality]);

      logger.info('setQualityLevel', 'success');
    } catch (error) {
      logger.error('setQualityLevel', error);
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Player video
   *
   * @return {this}
   */
  public play(options?: PlayerInstancePlayCommandOptions): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.Play), undefined, [options]);

      if (options?.manual) {
        this.touch();
      }

      logger.info('play', 'success');
    } catch (error) {
      logger.error('play', error);
    }

    return this;
  }

  public touch(): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.Touch), undefined, []);

      logger.info('touch', 'success');
    } catch (error) {
      logger.error('touch', error);
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Set video volume. Must be an integer in range [0, 1];
   *
   * @return {this}
   */
  public setVolume(volume: number): this {
    try {
      this.videoEl.volume = volume;

      logger.info('setVolume', 'success');
    } catch (error) {
      logger.error('setVolume', error);
    }

    return this;
  }

  /**
   * @description
   *  Not ready yet
   *
   * @deprecated
   * @return {number}
   */
  public get volume(): number {
    return 0;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   * pause video
   *
   * @return {this}
   */
  public pause(options?: PlayerInstancePauseCommandOptions): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.Pause), undefined, [options]);

      if (options?.manual) {
        this.touch();
      }

      logger.info('pause', 'success');
    } catch (error) {
      logger.error('pause', error);
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Seek video to specified time.
   *
   * @param {PlayerInstanceSeekCommandOptions | number} options
   *
   * @return {this}
   */
  public seekTo(options: PlayerInstanceSeekCommandOptions | number = 0): this {
    let normalizedOptions: Partial<PlayerInstanceSeekCommandOptions> = {};

    if (isObject(options)) {
      normalizedOptions = { ...options };
    } else if (isNumber(options)) {
      normalizedOptions.offset = options;
    }

    if (normalizedOptions.manual) {
      this.touch();
    }

    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.SeekTo), undefined, [normalizedOptions]);

      logger.info('seekTo', 'success');
    } catch (error) {
      logger.error('seekTo', error);
    }

    return this;
  }

  /**
   * @author Teodor_Dre <swen295@gmail.com>
   *
   * @description
   *  Load source video and id of content.
   *
   * @return {this}
   */
  public load(options: PlayerInstanceLoadSourceOptions): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.LoadSource), undefined, [options]);

      logger.info('load', 'success');
    } catch (error) {
      logger.error('load', error);
    }

    return this;
  }

  /**
   * @deprecated
   *
   * @return {this}
   */
  public focus(): this {
    return this;
  }

  /**
   * @deprecated
   *
   * @return {this}
   */
  public blur(): this {
    return this;
  }

  public startLoad(startOffset = 0): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.StartLoad), undefined, [startOffset]);

      logger.info('startLoad', 'success');
    } catch (error) {
      logger.error('startLoad', error);
    }

    return this;
  }

  public stopLoad(): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.StopLoad), undefined, []);
    } catch (error) {
      logger.error('stopLoad', error);
    }

    return this;
  }

  public async openPictureInPicture(): Promise<this> {
    try {
      const promise = ensurePromiseExist(
        Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.OpenPictureInPicture), undefined, []),
      );

      if (promise) {
        await promise;
        logger.info('openPictureInPicture');
      }
    } catch (error) {
      logger.error('openPictureInPicture', error);
    }

    return this;
  }

  public async closePictureInPicture(): Promise<this> {
    try {
      const promise = ensurePromiseExist(
        Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.ClosePictureInPicture), undefined, []),
      );

      if (promise) {
        await promise;
        logger.info('closePictureInPicture');
      }
    } catch (error) {
      logger.error('closePictureInPicture', error);
    }

    return this;
  }

  /**
   * @deprecated
   * @return {this}
   */
  public requestKinomEditorMode(): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.RequestKinomEditorMode), undefined, []);
    } catch (error) {
      logger.error('requestEditorKinomMode', error);
    }

    return this;
  }

  /**
   *
   * @return {this}
   */
  public requestDebugMenu(): this {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.RequestDebugMenu), undefined, []);
    } catch (error) {
      logger.error('requestDebugMenu', error);
    }

    return this;
  }

  /**
   * @deprecated
   *
   * @return {this}
   */
  public showControls(): this {
    this.setConfigProperty('video.framelessMode', false);
    return this;
  }

  /**
   * @deprecated
   *
   * @return {this}
   */
  public hideControls(): this {
    this.setConfigProperty('video.framelessMode', true);
    return this;
  }

  public reload(): this {
    if (!this.app) {
      return this;
    }

    const el = this.app._container as HTMLElement;

    if (el) {
      this.unmount();
      this.mount(el);
    } else {
      logger.warn('calling reload, when el is not defined');
    }

    return this;
  }

  /**
   * @description
   * Создает инстанс плеера и превращает его в строку (HTML).
   * Обычно нужен для SSR
   *
   * @return {Promise<string>}
   */
  public renderToString() {
    if (isClient) {
      logger.info('renderToString was called on client. Are you sure about that?');
    }

    return renderToString(this.#app);
  }

  /**
   *
   * @param {T} event
   * @param {(arg: ExternalEventMap[T]) => void} callback
   * @return {IDisposable}
   */
  public on<T extends keyof ExternalEventMap>(event: T, callback: (arg: ExternalEventMap[T]) => void): IDisposable {
    return this.#emitter.on(event, callback);
  }

  /**
   *
   * @param {T} event
   * @param {(arg: ExternalEventMap[T]) => void} callback
   * @return {IDisposable}
   */
  public once<T extends keyof ExternalEventMap>(event: T, callback: (arg: ExternalEventMap[T]) => void): void {
    const disposable = this.#emitter.on(event, (arg) => {
      Reflect.apply(callback, undefined, [arg]);

      disposable.dispose();
    });
  }

  /**
   *
   * @param {VideoConfig} values
   * @return {this}
   */
  public setConfigProperties(values: VideoConfig): this {
    for (const [key, value] of Object.entries(values)) {
      const _key = key as keyof VideoConfig;
      this.setConfigProperty(_key, value);
    }

    return this;
  }

  /**
   *
   * @return {this}
   * @param key
   * @param value
   */
  public setConfigProperty<T extends keyof VideoConfig>(key: T, value: VideoConfig[T]): this {
    this.#videoConfig[key] = value;

    return this;
  }

  /**
   *
   * @return {this}
   */
  public requestSaveMediaOffline() {
    try {
      Reflect.apply(this.#getExternalProperty(PlayerGlobalProperty.RequestSaveMediaOffline), undefined, []);
    } catch (error) {
      logger.error('requestSaveMediaOffline', error);
    }

    return this;
  }

  /**
   * @return {this}
   */
  public dispose() {
    document.documentElement.classList.remove('no-scroll');

    this.#emitter.dispose();
    this.#app.config.globalProperties[PlayerGlobalProperty.PlayerMounted] = false;
    this.#app.unmount();

    if (isClient) {
      playersTracker.untrackPlayer(this);
    }
  }

  /**
   *
   * @type {Ref<EnvironmentMode>}
   * @private
   */
  static readonly #environment: Ref<EnvironmentMode> = ref('production');

  /**
   * @deprecated
   * use setPlayerLang
   *
   * @description
   * Обновление языка плеера
   */
  public static setLang(_: AppPlayerLanguage) {
    //
  }

  /**
   * @deprecated
   *
   * @description
   * Выставление языка валюты
   * @param {AppPlayerCurrency} currency
   */
  public static setCurrency(currency: AppPlayerCurrency) {
    AppLanguageManager.setCurrency(currency);
  }

  static readonly #plugins: MediaPlayerPlugin[] = [];
  public static addPlugin(plugin: MediaPlayerPlugin) {
    if (VijuPlayer.#plugins.includes(plugin)) {
      return;
    }

    VijuPlayer.#plugins.push(plugin);
  }

  /**
   *
   * @param {"development" | "production"} env
   */
  public static setEnvironment(env: 'development' | 'production'): void {
    VijuPlayer.#environment.value = env;
  }

  /**
   *
   * @type {Ref<UserSession | undefined>}
   * @private
   */
  static readonly #session: Ref<UserSession | undefined> = ref();

  /**
   *
   * @param {UserSession} session
   */
  public static setSession(session: any) {
    VijuPlayer.#session.value = session;
  }
}

if (isClient) {
  window.$vijuPlayer = {};
}

export function useCurrentPlayer(): VijuPlayer | undefined {
  return currentPlayer;
}
