export enum SubscriptionPlatform {
  Apple = 'apple',
  Google = 'google',
  Payture = 'payture',
  Cloudpayments = 'cloudpayments',
  Partner = 'api',
  Sber = 'sber',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Default = 'cloudpayments',
}
