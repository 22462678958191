import { Episode, Season } from '@package/sdk/src/api';
import { indexOutOfRange, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useContentAvailability from '@PLAYER/player/modules/content/use-availability';

export type NextAvailableEpisode = {
  episode: Episode;
  season: Season;
};

interface GetNextAvailableEpisodeOptions {
  seasons: Season[];
  currentSeasonNumber: number;
  currentEpisodeNumber: number;
}

export function getNextAvailableEpisode(options: GetNextAvailableEpisodeOptions): NextAvailableEpisode | undefined {
  const { isAvailable } = useContentAvailability();
  const { currentSeasonNumber, currentEpisodeNumber, seasons } = options;

  const startSeasonIndex = seasons.findIndex((season: Season) => currentSeasonNumber === season.number);

  const startEpisodeIndex = seasons[startSeasonIndex].episodes.findIndex(
    (episode: Partial<Episode>) => currentEpisodeNumber === episode.number,
  );

  if (indexOutOfRange(startSeasonIndex)) {
    throw new UnexpectedComponentStateError('startSeasonIndex');
  }

  if (indexOutOfRange(startEpisodeIndex)) {
    throw new UnexpectedComponentStateError('startEpisodeIndex');
  }

  let episodeIndex = startEpisodeIndex + 1;

  function getNextAvailable(startIndex: number, endIndex: number): NextAvailableEpisode | undefined {
    for (let i = startIndex; i < endIndex; i++) {
      for (let j = episodeIndex; j < seasons[i].episodes.length; j++) {
        if (j === startEpisodeIndex && i === startSeasonIndex) {
          return;
        }

        const currentSeason = seasons[i];
        const currentEpisode = currentSeason.episodes[j];

        const matchedEpisode = isAvailable(currentEpisode);

        if (matchedEpisode) {
          const episode = seasons[i].episodes[j] as Episode;
          const season = seasons[i] as Season;

          return {
            episode,
            season,
          };
        }
      }

      episodeIndex = 0;
    }

    if (startIndex !== 0) {
      return getNextAvailable(0, startSeasonIndex);
    }
  }

  return getNextAvailable(startSeasonIndex, seasons.length);
}
