type PerformanceFeatureItemDetails = { fromVersion: number };

export type PerformanceFeatureItem = PerformanceFeatureItemDetails | null;

export type PerformanceFeatureType =
  | 'speechRecognitionApi'
  | 'backdropFilter'
  | 'yandexMetrica'
  | 'transitionComponent'
  | 'backgroundPlayerOnMainPage';

export class PerformanceFeatureMap {
  public get features(): Record<PerformanceFeatureType, PerformanceFeatureItem> {
    return {
      speechRecognitionApi: this.speechRecognitionApi(),
      backdropFilter: this.backdropFilter(),
      yandexMetrica: this.yandexMetrica(),
      transitionComponent: this.transitionComponent(),
      backgroundPlayerOnMainPage: this.backgroundPlayerOnMainPage(),
    };
  }

  public speechRecognitionApi(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }

  public backgroundPlayerOnMainPage(): PerformanceFeatureItem {
    return {
      fromVersion: 130,
    };
  }

  public transitionComponent(): PerformanceFeatureItem {
    return null;
  }

  public backdropFilter(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }

  public yandexMetrica(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }
}

export class TizenPerformanceFeaturesMap extends PerformanceFeatureMap {
  public backdropFilter() {
    return null;
  }
}

export class WebosFeaturesMap extends PerformanceFeatureMap {
  public backdropFilter(): PerformanceFeatureItem {
    return null;
  }

  public speechRecognitionApi(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }
}

export class VidaaFeatureMap extends PerformanceFeatureMap {
  public backdropFilter(): PerformanceFeatureItem {
    return null;
  }
}
