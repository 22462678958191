<template>
  <section :class="$style.errorPage">
    <div :class="$style.errorPageWrapper">
      <div :class="$style.textBlockWrapper">
        <div :class="$style.textBlock">
          <div :class="$style.iconWrapper">
            <icon-viju-tv />
            <span :class="$style.iconGradient"></span>
          </div>
          <p :class="$style.status">Ошибка</p>
          <p :class="$style.text">{{ errorText }}</p>
          <pre v-if="!isRelease" :class="$style.card">{{ error.stack }}</pre>
          <app-sdk-button
            v-if="isRelease"
            text="Назад"
            variation="smart-button-primary"
            :class="$style.backButton"
            @click="onBackButtonClick()"
          />
        </div>
      </div>
      <div :class="$style.imageWrapper">
        <img :class="$style.image" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import AppSdkButton from '@package/ui/src/components/AppButton.vue';

import IconVijuTv from '../icons/IconVijuTv.vue';

export default {
  components: {
    IconVijuTv,
    AppSdkButton,
  },
  props: {
    error: {
      type: Error,
      required: true,
    },
    isRelease: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    errorText() {
      /**
       * @type {Error}
       */
      const error = this.error;

      return error.message;
    },
  },
  methods: {
    onBackButtonClick() {
      this.$emit('on-back-button-click');
    },
  },
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;

.errorPage {
  height: 75%;
  overflow-y: auto;
}

.errorPageWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 650px;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
}

.textBlockWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  flex-shrink: 0;
}

.iconWrapper {
  position: relative;
  margin-bottom: 65px;
}

.iconGradient {
  position: absolute;
  top: 0;
  left: -85px;
  z-index: -1;
  width: 155px;
  height: 155px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background-color: var(--color-states-bg-accent-hover); // поправить цвета на странице
  filter: blur(85px);
}

.textBlock {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: var(--g-spacing-10);
  height: 100%;
}

.logo {
  position: absolute;
  top: 18px;
  left: 50px;
}

.text {
  margin-bottom: 30px;
  color: var(--color-text-primary);

  @include smartTvFonts.SmartTvBody-2();
}

.card {
  min-width: 0;
  max-width: 1000px;
  border-radius: 0.25rem;
  border: 1px solid var(--color-stroke-div-primary);
  background-color: var(--color-bg-secondary);
  background-clip: border-box;
  color: var(--color-text-secondary);
}

.backButton {
  width: fit-content;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: var(--color-bg-secondary);
    color: var(--color-text-primary);
  }
}

.status {
  margin-bottom: var(--g-spacing-10);

  @include smartTvFonts.SmartTvTitle-1();
}

.imageWrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.image {
  max-width: initial;
  height: 100%;
}
</style>
