import { ApplicationError } from '@package/sdk/src/core';

export enum VideoPlayerScope {
  Playback = 'playback',
  Fullscreen = 'fullscreen',
  Orientation = 'orientation',
}

export class VideoPlayerHTML5Error extends ApplicationError {
  public readonly name = 'VideoPlayerHTML5Error';

  constructor(
    public readonly scope: VideoPlayerScope,
    public readonly message: string,
  ) {
    super(`Scope: ${scope} - ${message}`);
  }

  public toJSON(): Record<string, any> {
    const { scope, message } = this;

    return {
      scope,
      message,
    };
  }
}
