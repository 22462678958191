import useVideoConfig from '@PLAYER/player/modules/video/use-video-config';
import { computed } from 'vue';

export type TimestampType = 'media_intro' | 'media_next' | 'media_kinom';

export interface MediaTimestamp {
  type: TimestampType;
  start?: number;
  end?: number;
  timeToRewind?: number;
  disposeOnReach?: boolean;
}

export default function useMediaTimestamps() {
  const videoConfig = useVideoConfig();

  const timestamps = computed(() => videoConfig['content.timestamps'] || []);
  const isTimestampsPresent = computed(() => Boolean(timestamps.value?.length));

  const removeTimestamp = (timestamp: MediaTimestamp) => {
    if (!videoConfig['content.timestamps']) {
      return;
    }

    videoConfig['content.timestamps'] = videoConfig['content.timestamps']?.filter(
      (stamp) => timestamp.type !== stamp.type,
    );
  };

  return {
    removeTimestamp,
    isTimestampsPresent,
    timestamps,
  };
}
