export enum VuexStoreNamespace {
  Auth = 'auth',
  Content = 'content',
  Catalog = 'catalog',
  MainPage = 'mainPage',
  Session = 'session',
  TvChannels = 'tvChannels',
  RemoteControl = 'remoteControl',
}

export const getStoreProperty = <Key extends VuexStoreNamespace, T extends string>(
  namespace: Key,
  property: T,
): `${Key}/${T}` => {
  return `${namespace}/${property}`;
};
