<template>
  <article :class="$style.text">
    <h3 :class="$style.title">{{ $t('pages.main.myChannel.title') }}</h3>
    <h4 :class="$style.subtitle">{{ $t('pages.main.myChannel.subtitle') }}</h4>
    <section :class="$style.buttons">
      <NavigatableItem
        :focus-key="FocusKeys.MY_CHANNEL_BUTTON(0)"
        :tag="AppButton"
        :class="$style.button"
        :active-class="$style.active"
        :text="$t('pages.main.myChannel.gotoMyChannel')"
        @click="onGotoMyChannel"
      />
      <NavigatableItem
        :focus-key="FocusKeys.MY_CHANNEL_BUTTON(1)"
        :tag="AppButton"
        :class="$style.button"
        :active-class="$style.active"
        :text="$t('pages.main.myChannel.cancel')"
        @click="onFinish"
      />
    </section>
  </article>
</template>

<script setup lang="ts">
import { onboardingService, RouterPage, routerService } from '@package/smarttv-base/src';
import { FocusKeys } from '@SMART/index';

import AppButton from '@/components/app-button/AppButton.vue';

const emit = defineEmits<{
  (e: 'finish'): void;
}>();

const onFinish = () => {
  onboardingService.finishMyChannel();
  emit('finish');
};

const onGotoMyChannel = () => {
  onboardingService.finishMyChannel();
  routerService.push({ name: RouterPage.MyChannelPage });
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.text {
  position: fixed;
  top: adjustPx(316px);
  left: adjustPx(960px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: adjustPx(864px);
  height: adjustPx(668px);
  background: var(--color-bg-primary);

  .title {
    margin-bottom: adjustPx(32px);
    @include f-title-3-2;
  }

  .subtitle {
    margin-bottom: adjustPx(64px);
    @include f-body-2-2;
  }

  .buttons {
    display: flex;

    .button {
      height: adjustPx(96px);
      border-radius: adjustPx(24px);
      border: none;
      background: var(--color-bg-button);
      margin-right: adjustPx(18px);
      @include f-label-2;
    }

    .active {
      background: var(--color-notheme-bg-accent);
      color: var(--color-notheme-text-accent);
    }
  }
}
</style>
