export default function addScriptTagWithHtml(html: string, id: string) {
  const script = document.createElement('script');

  script.id = id;
  script.innerHTML = html;

  document.head.appendChild(script);

  return script;
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it('Should contains script in head element', () => {
    const script = addScriptTagWithHtml('{ const test = 1; }', 'test');

    expect(document.head.querySelector('#test')).equals(script);
  });
}
