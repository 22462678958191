<template>
  <object type="application/avplayer" :class="$style.tizenObject" />
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.tizenObject {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
