<template>
  <timeline-frame-preview-image
    ref="el"
    :src="previewSrc"
    :on-click="onRequestEpisode"
    :class="{
      [$style.wrapper]: true,
      [$style.wrapperAnimated]: isButtonAnimated,
      [$style.smartTvActive]: isSmartTV && focused,
    }"
  >
    <template #text>
      <p :class="$style.description">
        <span :class="$style.text">{{ $t('vod.nextMedia') }}</span>
        <span :class="$style.time">{{ episodeTitle }}</span>
      </p>
    </template>
  </timeline-frame-preview-image>
</template>

<script setup lang="ts">
import { AnyFunction } from '@PLAYER/player/base/function';
import TimelineFramePreviewImage from '@PLAYER/player/components/timeline/TimelineFramePreviewImage.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';

const props = defineProps<{
  previewSrc: string;
  onRequestEpisode: AnyFunction;
  episodeTitle: string;
  isButtonAnimated: boolean;
}>();

const { isSmartTV } = usePlatform();
const { useNavigatable } = useSmartNavigatable();

const { el, focused } = useNavigatable({ onEnterPress: props.onRequestEpisode });
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts.scss' as webFonts;
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.wrapper {
  overflow: hidden;
}

.wrapperAnimated {
  --next-episode-animation-duration: 6s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-next-episode-button-pseudo);
    width: 0;
    background-color: var(--color-notheme-bg-tertiary-80);
    animation: var(--next-episode-animation-duration) linear button-filling;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--g-spacing-4) var(--g-spacing-8) var(--g-spacing-6);
}

.text {
  color: var(--color-notheme-text-primary);

  @include webFonts.WebLabel-3();
}

.time {
  color: var(--color-notheme-text-secondary);
  margin-top: var(--g-spacing-2);
  text-transform: uppercase;

  @include webFonts.WebBody-3();
}

.smartTvActive {
  border: adjust.adjustPx(8px) solid var(--color-notheme-bg-accent);
}

@keyframes button-filling {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
