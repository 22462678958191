<template>
  <ScrollViewport :class="$style.scrollViewport" :style="transform">
    <slot />
  </ScrollViewport>
</template>

<script setup lang="ts">
import type { Component } from 'vue';
import { computed, h, useSlots } from 'vue';

interface Props {
  tag?: string;
  x?: number;
  y?: number;
  orientation?: 'vertical' | 'horizontal';
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  x: 0,
  y: 0,
});

const slots = useSlots();

const transform = computed((): string => {
  if (props.orientation) {
    return '';
  }

  let xVal = parseFloat(props.x?.toString());
  let yVal = parseFloat(props.y?.toString());

  xVal = !isNaN(xVal) ? xVal * -1 : 0;
  yVal = !isNaN(yVal) ? yVal * -1 : 0;

  return `transform: translate(${xVal}px, ${yVal}px)`;
});

const style = computed(() => {
  if (props.orientation) {
    return {
      'overflow-x': props.orientation === 'horizontal' ? 'auto' : 'hidden',
      'overflow-y': props.orientation === 'vertical' ? 'auto' : 'hidden',
    };
  } else {
    return transform.value;
  }
});

const ScrollViewport: Component = h(props.tag, { style: style.value }, slots);
</script>

<style module lang="scss">
.scrollViewport {
  transition: transform 0.32s ease 0s;
  will-change: transform;

  &::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
}
</style>
