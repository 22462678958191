import VideoPlayerEvent from '@PLAYER/player/base/event';
import { TimeMilliseconds, TimeSeconds } from '@PLAYER/player/base/number';
import { MediaTimestamp, TimestampType } from '@PLAYER/player/modules/content/use-media-timestamps';
import { PerformanceType } from '@PLAYER/player/modules/hooks/use-perfomance-detector';
import { QualityLevelState } from '@PLAYER/player/modules/interfaces/hls';

export type ExternalQualityLevel = 'auto' | 'lowest' | 'highest';
export type QualityLevelUpdatedEvent = QualityLevelState;

export interface DebugInfoEvent {
  bandwidth: number;
  bufferLength: number;
  videoCodec?: string;
  audioCodec?: string;
  latency?: number;
  realQualityLevelHeight: number;
}

export interface PlayerTimeUpdated {
  viewingTime: TimeMilliseconds;

  timeshiftOffset?: TimeSeconds;
  startContentTime?: TimeSeconds;
  finishContentTime?: TimeSeconds;
}

export interface DevicePerformanceEvent {
  performance: PerformanceType;
  fps: number;
}

export interface DoubleTapEvent {
  tapPosition: 'left' | 'right';
  isActive: boolean;
}

export interface TimestampEvent {
  timestamp: MediaTimestamp;
  type: TimestampType;
  diff?: number;
}

export interface ControlsStateEvent {
  visible: boolean;
}

export class VideoPlayerInternalEvent<T> extends VideoPlayerEvent<T> {
  constructor(data: T) {
    super(data);
  }
}
