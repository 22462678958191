import { ProcessingMapper } from '../base/processing-mapper';
import { Moment } from '../content/types/moment';
import { ApiMoment } from './moment-types/moment';

export class MomentMapper {
  static mapMany(moments: ApiMoment[]): Moment[] {
    return ProcessingMapper.process(moments.map(MomentMapper.map), { freezeData: false });
  }

  static map({
    content_id,
    content_type,
    content_title,
    start_offset,
    end_offset,
    in_user_collection,
    content_in_user_collection,
    moment_playlist_ids,
    default_moment_playlist_id,
    season_number,
    episode_number,
    content_slug,
    content_year,
    content_duration,
    can_create_ugc,
    like_state,
    genres,
    content_poster,
    content_availability,
    content_license_date_start,
    content_license_date_end,
    ...rest
  }: ApiMoment): Moment {
    return ProcessingMapper.process(
      {
        ...rest,
        episodeNumber: episode_number,
        seasonNumber: season_number,
        contentId: content_id,
        contentType: content_type,
        contentTitle: content_title,
        startOffset: start_offset,
        endOffset: end_offset,
        inUserCollection: in_user_collection,
        contentInUserCollection: content_in_user_collection,
        momentPlaylistIds: moment_playlist_ids,
        contentSlug: content_slug,
        contentYear: content_year,
        contentDuration: content_duration,
        canCreateUGC: can_create_ugc,
        genres: genres || [],
        likeState: like_state,
        contentPoster: content_poster,
        contentAvailability: content_availability,
        contentLicenseDateEnd: content_license_date_end,
        contentLicenseDateStart: content_license_date_start,
        defaultMomentPlaylistId: default_moment_playlist_id,
      },
      { freezeData: false },
    );
  }
}
