<template></template>

<script lang="ts" setup>
import { ensurePromiseExist } from '@PLAYER/player/base/dom';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useSafeVideoElement from '@PLAYER/player/modules/video/use-safe-video-element';

const props = defineProps<{ isAllowAutoplay: boolean }>();
const videoEl = useSafeVideoElement();

const emit = defineEmits<{
  (event: 'autoplay-success'): void;
  (event: 'autoplay-failure'): void;
}>();

const handleAutoplayError = (error: unknown) => {
  const isNotAllowedErrorAutoplay = error instanceof Error && error.name === 'NotAllowedError';

  if (isNotAllowedErrorAutoplay) {
    console.error(`DOMException#autoplay prevented: ${error.message}`);
    emit('autoplay-failure');
  } else {
    emit('autoplay-success');
  }
};

useEffect(() => {
  // Если по умолчанию автопроигрывание выключено
  if (!props.isAllowAutoplay) {
    return;
  }

  try {
    const playPromise = ensurePromiseExist(videoEl.play());

    if (playPromise) {
      playPromise.then(() => emit('autoplay-success')).catch(handleAutoplayError);
    }
  } catch (error) {
    handleAutoplayError(error);
  }
});
</script>
