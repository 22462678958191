<template></template>

<script lang="ts" setup>
import { indexOutOfRange, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useMediaSeasons from '@PLAYER/player/modules/content/use-media-seasons';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import { storeToRefs } from 'pinia';
import { watchEffect } from 'vue';

const { isSeasonsPresent, seasons } = useMediaSeasons();
const playlistStore = usePlaylistStore();
const { currentPlaylistItemId } = storeToRefs(playlistStore);
const { isKinom, isVOD, isLive } = useProjector();
const { content } = useContentInformation();

const setKinomPlaylists = () => {
  playlistStore.setPlaylists(seasons.value);
  playlistStore.setCurrentPlaylist(seasons.value[0]);

  const itemId = currentPlaylistItemId.value || content.value.id;
  const kinomIndex = seasons.value[0].contentMomentsList.findIndex((ep) => ep.id === itemId);

  if (indexOutOfRange(kinomIndex)) {
    playlistStore.setCurrentPlaylistItemIndex(0);
  } else {
    playlistStore.setCurrentPlaylistItemIndex(kinomIndex);
  }
};

const setVODPlaylist = () => {
  let matchedSeason = seasons.value.find((season) =>
    season.episodes.find((ep) => ep.id === currentPlaylistItemId.value),
  );

  let episodeIndex = 0;

  if (!matchedSeason) {
    matchedSeason = seasons.value[0];
  } else {
    episodeIndex = matchedSeason.episodes.findIndex((ep) => ep.id === currentPlaylistItemId.value);

    if (indexOutOfRange(episodeIndex)) {
      throw new UnexpectedComponentStateError('matchedSeasonIndex');
    }
  }

  playlistStore.setPlaylists(seasons.value);
  playlistStore.setCurrentPlaylistItemIndex(episodeIndex);
  playlistStore.setCurrentPlaylist(matchedSeason);
};

watchEffect(
  () => {
    if (!isSeasonsPresent.value) {
      return;
    }

    if (isKinom.value) {
      return setKinomPlaylists();
    }

    if (isVOD.value || isLive.value) {
      return setVODPlaylist();
    }
  },
  { flush: 'post' },
);
</script>
