import { ProcessingMapper } from '../base/processing-mapper';
import { CompilationColors } from './compilation';
import { ApiCompilationColors } from './compilation-types';

export class ColorMapper {
  static map({ main, light }: ApiCompilationColors): CompilationColors {
    return ProcessingMapper.process({
      main,
      light,
    });
  }
}
