import { ApplicationError } from '../errors/application-error';

export enum MediaErrorStatusCode {
  MEDIA_ERR_ABORTED = 1, // not critical
  MEDIA_ERR_NETWORK = 2,
  MEDIA_ERR_DECODE = 3,
  MEDIA_ERR_SRC_NOT_SUPPORTED = 4,
}

export class NativeMediaError extends ApplicationError {
  public readonly name = 'NativeMediaError';

  constructor(private readonly error: MediaError) {
    super(`NativeMediaError: code - ${error.code}`);
  }

  public get code(): MediaErrorStatusCode {
    return this.error.code;
  }

  public toJSON(): Record<string, any> {
    const { error } = this;

    return {
      code: error.code,
      message: error.message,
    };
  }
}
