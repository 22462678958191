import { isUndefinedOrNull } from '@package/sdk/src/core';
import getQueryParamByName from '@package/sdk/src/core/dom/get-query-param-by-name';

import type { DsmlValue } from '../dsml';

enum UTMParam {
  Campaign = 'utm_campaign',
  Content = 'utm_content',
  Medium = 'utm_medium',
  Source = 'utm_source',
  Term = 'utm_term',
}

export default function getParsedUTMValues(): DsmlValue[] {
  const values: DsmlValue[] = [];

  Object.values(UTMParam).forEach((property) => {
    const value = getQueryParamByName(property);

    if (isUndefinedOrNull(value)) {
      return;
    }

    values.push({ property, value });
  });

  return values;
}
