<template>
  <div :class="$style.wrapper">{{ playButtonSubtitle }}</div>
</template>

<script setup lang="ts">
import * as playerHelpers from '@package/media-player/src/player/helpers';
import {
  ContentActionsWithoutStoreArgument,
  ContentGetters,
  ContentState,
  SessionGetters,
  SessionState,
  storeToRefs,
  useContentStore,
  useSessionStore,
} from '@SMART/index';
import { computed } from 'vue';

const { isActiveSubscription, isPartnerSubscription, isPartnerUser } = storeToRefs<
  SessionState,
  SessionGetters,
  unknown
>(useSessionStore());

const { content } = storeToRefs<ContentState, ContentGetters, ContentActionsWithoutStoreArgument>(useContentStore());

const playButtonSubtitle = computed(() => {
  return playerHelpers.getPlayButtonSubtitle({
    isActiveSubscription: isActiveSubscription.value,
    isPartnerSubscription: isPartnerSubscription.value || isPartnerUser.value,
    content: content.value,
  });
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.wrapper {
  @include smartTvFonts.SmartTvBody-2();
}
</style>
