export type CookieKey = 'dsml.token';

function getDomainName(): string {
  const { hostname } = window.location;

  return hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
}

export function getCookie(name: CookieKey) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) === 0) {
      return JSON.parse(c.substring(nameEQ.length, c.length));
    }
  }

  return undefined;
}

const domainName = getDomainName();

export function setCookie(name: CookieKey, value: any) {
  const myDate = new Date();
  myDate.setMilliseconds(60 * 60 * 24);
  const additionalCookiePolicy = 'SameSite=None;Secure';
  const expiresAt = value.expires || myDate;

  document.cookie =
    // eslint-disable-next-line no-useless-concat
    `${name}=${JSON.stringify(value)};domain=${domainName};path=/;` + `expires=${expiresAt};${additionalCookiePolicy}`;
}
