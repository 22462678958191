import { MediaContentType } from '../../api/content/types/content-type';

export enum AnalyticContentType {
  MOVIE = 'movie',
  SERIES = 'series',
}

export type AnalyticDirectionValue = 'left' | 'right';

export function getAnalyticContentTypeValue(contentType: MediaContentType): AnalyticContentType {
  if (contentType === MediaContentType.MOVIE) {
    return AnalyticContentType.MOVIE;
  }

  return AnalyticContentType.SERIES;
}
