<template></template>

<script setup lang="ts">
import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';

let adultWarningTimeoutId: number | undefined;

const layoutStore = useLayoutStore();
const eventBus = useSafeEventBus();

const onPlayerInit = () => {
  if (adultWarningTimeoutId) {
    window.clearTimeout(adultWarningTimeoutId);
  }

  layoutStore.setIsAdultContentWarningVisible(true);

  adultWarningTimeoutId = window.setTimeout(() => {
    layoutStore.setIsAdultContentWarningVisible(false);
  }, ConstantsConfigPlayer.getProperty('adultContentWarningTimeoutMs'));
};

eventBus.on('onPlayerInit', onPlayerInit);

useEffect(() => {
  return () => {
    if (adultWarningTimeoutId) {
      window.clearTimeout(adultWarningTimeoutId);
    }

    layoutStore.setIsAdultContentWarningVisible(false);
    eventBus.removeEventListener('onPlayerInit', onPlayerInit);
  };
});
</script>
