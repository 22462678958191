import type { Episode, Media, Serial } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { isDefined, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { getNextAvailableEpisode } from '@PLAYER/player/modules/content/get-next-available-episode';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useSession from '@PLAYER/player/modules/session/use-session';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

function getFirstFreeEpisodeAndSeason(content: Serial) {
  if (content.accessKind !== 'all_users') {
    return undefined;
  }

  let lastAvailableEpisode: Episode | undefined;

  const { seasons } = content;

  const lastAvailableSeason = seasons.find((season) => {
    lastAvailableEpisode = season.episodes.find((episode) => episode.accessKind === 'all_users');

    return isDefined(lastAvailableEpisode);
  });

  if (!lastAvailableSeason || !lastAvailableEpisode) {
    throw new UnexpectedComponentStateError('lastAvailableSeason | lastAvailableEpisode');
  }

  return {
    lastAvailableEpisode,
    lastAvailableSeason,
  };
}

export default function useFreeContentInformation() {
  const { content, isSerialContent } = useContentInformation();
  const { isKinom, isLive } = useProjector();
  const { isUserWatchedFreeThresholdTime } = storeToRefs(useLayoutStore());
  const { isActiveSubscription } = useSession();
  const { isSmartTV } = usePlatform();

  const freeAvailableEpisodeAndSeason = computed(() => {
    if (isSerialContent.value) {
      return getFirstFreeEpisodeAndSeason(content.value as Serial);
    }

    return undefined;
  });

  const isFirstEpisodeOrMovieFree = computed(() => {
    if (isKinom.value || isLive.value || isActiveSubscription.value) {
      return false;
    }

    const media = content.value as Media;

    if (media.contentType === MediaContentType.Movie) {
      return media.accessKind === 'all_users';
    }

    if (isSerialContent.value) {
      if (!freeAvailableEpisodeAndSeason.value) {
        return false;
      }

      const { lastAvailableEpisode } = freeAvailableEpisodeAndSeason.value;

      return lastAvailableEpisode.accessKind === 'all_users' || lastAvailableEpisode.accessKind === 'register';
    }

    return false;
  });

  const nextAfterFreeEpisode = computed(() => {
    // Если это не сериал, или нет досутпного сезона/зпизода
    if (!isSerialContent.value || !freeAvailableEpisodeAndSeason.value) {
      return undefined;
    }

    if (!isFirstEpisodeOrMovieFree.value) {
      return undefined;
    }

    const { lastAvailableEpisode, lastAvailableSeason } = freeAvailableEpisodeAndSeason.value;

    const serial = content.value as Serial;

    const seasonEpisode = getNextAvailableEpisode({
      seasons: serial.seasons,
      currentEpisodeNumber: lastAvailableEpisode.number,
      currentSeasonNumber: lastAvailableSeason.number,
    });

    if (!seasonEpisode) {
      return undefined;
    }

    return seasonEpisode.episode;
  });

  const seasonForNextAfterFreeEpisode = computed(() => {
    // Если это не сериал, или нет досутпного сезона/зпизода
    if (!isSerialContent.value || !freeAvailableEpisodeAndSeason.value) {
      return undefined;
    }

    if (!isFirstEpisodeOrMovieFree.value) {
      return undefined;
    }

    const { lastAvailableEpisode, lastAvailableSeason } = freeAvailableEpisodeAndSeason.value;

    const serial = content.value as Serial;

    const seasonEpisode = getNextAvailableEpisode({
      seasons: serial.seasons,
      currentEpisodeNumber: lastAvailableEpisode.number,
      currentSeasonNumber: lastAvailableSeason.number,
    });

    if (!seasonEpisode) {
      return undefined;
    }

    return seasonEpisode.season;
  });

  const isNextFreeContentTooltipShown = computed(
    () => isFirstEpisodeOrMovieFree.value && nextAfterFreeEpisode.value && isUserWatchedFreeThresholdTime.value,
  );

  return {
    isNextFreeContentTooltipShown,
    nextAfterFreeEpisode,
    isFirstEpisodeOrMovieFree,
    seasonForNextAfterFreeEpisode,
  };
}
