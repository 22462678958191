export interface LocalStorageValueMap {
  'smart.player.onboarding': boolean;

  'common.player.settings.disposeChromecastTranslationOnUnload': boolean;
  'common.player.settings.rememberLastQualityLevel': boolean;
  'common.player.settings.lowBatteryMode': boolean;

  'common.player.settings.lastQualityLevel': 'auto' | number;

  'common.player.settings.rewindOnboardingTimesShown': number;
}

export enum LocalStorageKey {
  SmartTvOnboardingControl = 'smart.player.onboarding',
  DisposeChromecastOnUnload = 'common.player.settings.disposeChromecastTranslationOnUnload',
  RememberLastQualityLevel = 'common.player.settings.rememberLastQualityLevel',
  LastQualityLevel = 'common.player.settings.lastQualityLevel',
  RewindOnboardingTimesShown = 'common.player.settings.rewindOnboardingTimesShown',
  LowBatteryMode = 'common.player.settings.lowBatteryMode',
}
