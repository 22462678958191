<template>
  <media-description-layer :title="title" subtitle="" :content="content" />
</template>

<script lang="ts" setup>
import type { Channel } from '@package/sdk/src/api';
import MediaDescriptionLayer from '@PLAYER/player/components/media-information/MediaDescriptionLayer.vue';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import { computed } from 'vue';

const { content } = useContentInformation();

const isTvChannelTitleShown = false;

const title = computed(() => {
  if (!isTvChannelTitleShown) {
    return '';
  }

  return (content.value as Channel).currentProgram?.title;
});
</script>
