import { getCurrentInstance } from 'vue';

export default function useDefaultProvide() {
  const app = getCurrentInstance();

  const getDefault = (injectKey: string) => {
    return app?.appContext.provides[injectKey];
  };

  return {
    getDefault,
  };
}
