import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { inject } from 'vue';

const injectKey = 'app.player.id';

export default function useInstanceId() {
  const { getDefault } = useDefaultProvide();

  return inject<string>(injectKey, getDefault(injectKey));
}
