<template>
  <media-description-layer
    :title="title"
    :logo="logo"
    :content="content"
    :is-playlist-mode="isPlaylistModeFullscreen"
  />
  <slot></slot>
</template>

<script lang="ts" setup>
import type { Channel, Moment } from '@package/sdk/src/api';
import MediaDescriptionLayer from '@PLAYER/player/components/media-information/MediaDescriptionLayer.vue';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import usePlaylistMode from '@PLAYER/player/modules/hooks/use-playlist-mode';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { content } = useContentInformation();
const { currentPlaylist } = storeToRefs(usePlaylistStore());
const { isPlaylistModeFullscreen } = usePlaylistMode();

const title = computed(() => {
  if (isPlaylistModeFullscreen.value) {
    return currentPlaylist.value?.title;
  }

  return (content.value as Moment)?.contentTitle;
});

const logo = computed(() => (content.value as Channel)?.logo);
</script>
