<template>
  <div>
    <smart-tv-video-control-button
      :on-click="onTooltipClick"
      :is-label-shown="true"
      aria-haspopup="true"
      :text="$t('settings.playbackRate')"
    >
      <icon-settings size="medium" state="normal" />
      <span>{{ $t('settings.playbackRate') }}</span>
    </smart-tv-video-control-button>
    <smart-tv-control-tooltip-element
      v-if="isTooltipShown"
      :options="playbackSpeedRateOptions"
      :on-select-option="onSelectOption"
      :selected-option="playbackSpeedRateSettings?.selectedOption"
    />
  </div>
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import IconSettings from '@PLAYER/player/components/icons/IconSettings.vue';
import useTooltipState from '@PLAYER/player/modules/timeline/use-tooltip-state';
import usePlayerSettings, { OptionItem, SettingItemType } from '@PLAYER/player/modules/video/use-player-settings';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import SmartTvControlTooltipElement from '@PLAYER/player/versions/smart/components/ui/SmartTvControlTooltipElement.vue';
import { computed } from 'vue';

const { settings, onSelectOption: doSelectOption } = usePlayerSettings();
const { isTooltipShown, openTooltip, closeTooltip } = useTooltipState();

const playbackSpeedRateSettings = computed(() =>
  settings.value.find((setting) => setting.type === SettingItemType.PlaybackRate),
);
const playbackSpeedRateOptions = computed(() => playbackSpeedRateSettings.value?.options || []);

const onTooltipClick = () => {
  if (isTooltipShown.value) {
    return closeTooltip();
  }

  openTooltip();

  window.$setOnPressBackCallback(closeTooltip);
};

const onSelectOption = (option: OptionItem) => {
  if (!playbackSpeedRateSettings.value) {
    throw new UnexpectedComponentStateError('playbackSpeedRateSettings');
  }

  closeTooltip();

  return doSelectOption(option, playbackSpeedRateSettings.value);
};
</script>
