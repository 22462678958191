<template>
  <teleport v-if="labelContainerEl" :to="labelContainerEl">
    <app-label
      :ref="(comp) => (labelEl = comp?.$el)"
      :text="text"
      :left="position.left"
      :class="textClass"
      :top="position.top"
    />
  </teleport>
</template>

<script lang="ts" setup>
import AppLabel from '@PLAYER/player/components/ui/AppLabel.vue';
import useSafeLabelElement from '@PLAYER/player/modules/label/use-safe-label-element';
import useSafeRootVideoElement from '@PLAYER/player/modules/video/use-safe-root-video-element';
import { useScroll, watchOnce } from '@vueuse/core';
import { onMounted, ref } from 'vue';

type LabelPosition = {
  left: number;
  top: number;
};

const props = defineProps<{
  focusEl: HTMLElement;
  textClass?: string;
  calculateLabelPosition?: (labelEl: HTMLElement, rootVideoEl: HTMLElement) => LabelPosition;
  text: string;
}>();

const labelContainerEl = useSafeLabelElement();
const rootVideoEl = useSafeRootVideoElement();

const labelEl = ref<HTMLElement>();

const { isScrolling } = useScroll(window);
const position = ref<LabelPosition>({
  top: 0,
  left: 0,
});

watchOnce(isScrolling, () => {
  if (!labelEl.value) {
    return;
  }

  labelEl.value.style.display = 'none';
});

onMounted(() => {
  if (!props.calculateLabelPosition) {
    return console.warn('Expect function calculateLabelPosition to be defined');
  }

  position.value = Reflect.apply(props.calculateLabelPosition, undefined, [labelEl.value, rootVideoEl.value]);

  const nextSibling = labelEl.value?.nextElementSibling as HTMLElement;
  const prevSibling = labelEl.value?.previousElementSibling as HTMLElement;

  if (prevSibling) {
    prevSibling.style.display = 'none';
  }

  if (nextSibling) {
    nextSibling.style.display = 'none';
  }
});
</script>
